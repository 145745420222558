<!-- Displays a food entry in a reservable state -->
<template lang="html">
  <div class="food-list-entry">
    <div data-available="true" :data-entry-id="entry.id" class="bg--white">
        <simple-accordion @closed="collapsed = true" @opened="collapsed = false">
          <div class="food-cell__header" slot="header">
              <span class="font-md">{{entry.name}}</span>
              <img class="category-image image--xxs pull-right" :src="categoryImagePath">
              <span class="pull-right">{{availableCount}}<span v-show="!collapsed"> available</span></span>
          </div>
          <div class="food-cell__content text-center">

            <div class="list-entry__details">
                  <div class="details__cell">
                      <div>
                          <img class="image--xs unmarg" alt="Image" src="img/svg/silverware_shorter.svg"></img>
                          <span class="">Unit</span>
                          <p class="font-dosis-bold">{{ entry.units }} each</p>
                      </div>
                  </div>
                  <div class="details__cell">
                      <div>
                          <img class="image--xs unmarg" alt="Image" src="img/svg/auto.svg"></img>
                          <span class=""></span><br/>
                          <div class="text-nowrap font-dosis-bold">{{ provider.name }}</div>
                          <p class="">{{ provider.address.street }}</p>
                      </div>
                  </div>
                  <div class="details__cell">
                      <div>
                          <img class="image--xs unmarg" alt="Image" src="img/svg/calendar.svg"></img>
                          <span class="">Pickup By</span>
                          <p class="font-dosis-bold">{{ entryExpiration }}</p>
                      </div>
                  </div>
            </div>
            <div class="h-centered-content">
              <div class="list-entry__actions">
                <action-button class="action-button--primary" @button-click="reserve" :is-loading.sync="reservationProcessing" :is-confirmed.sync="reservationComplete">Reserve</action-button>
              </div>
            </div>
        </div>
        </simple-accordion>

  <hr class="unmarg"/>
</div>
  </div>
</template>

<script>
import Fireapp from '../modules/fireapp.js';
import FireUtils from '../modules/fireutils.js';
import moment from 'moment';
import SimpleAccordion from './simple-accordion.vue';

export default {
  name: 'food-list-entry',
  components: {
    SimpleAccordion
  },
  props: [ 'entryId', 'provider'],
  firebase(){
    return{
      entry: {
        source: Fireapp.database().ref(`foodEntries/${this.entryId}`),
        asObject: true
      }
    }
  },
  data() { return {
    collapsed : true,

    reservationProcessing: false,
    reservationComplete: false
  } },
  computed: {
    entryExpiration(){
      return moment(this.entry.expirationDate).calendar();
    },
    availableCount(){
      // Get the availability through a store getter
      return this.$store.getters.availibilityCount(this.entry);
    },
    categoryImagePath(){
      if (!this.entry || !this.entry.category) {
        return "";
      }
      let category = FireUtils.values.categories[this.entry.category];
      return category.imageURL;
    }
  },
  methods: {
    reserve(){
      // perform the async reserve action on the store
      if (!FireUtils.values.userIsAnonymous) {
        this.$modal.show('error-alert', {
          title: 'Permission Denied',
          message: 'Providers are not eligible to reserve food',
          type: 'error'
        });
        return;
      }
      this.reservationProcessing = true;
      this.$store.dispatch('reserve', this.entry)
      .then(()=>{
        this.reservationComplete = true;
        this.$notification.show('item-added');
      })
      .catch((err) => {
        this.reservationProcessing = false;
        if (err.name && err.name === "Quota Reached"){
          let quotaMessage = `Our policy limits each person to three reservations a day to keep it fair for everyone. You can cancel one of your reservations from today and reserve something different, or come back tomorrow to reserve more.`;
          this.$modal.show('image-alert', {
            title: 'Your Bag is Full!',
            message: quotaMessage,
            imagePath: 'img/svg/pickup.svg',
            imageClasses: [],
            modalClasses: ['image-modal image-modal--quota-reached']
          });
        }else {
          this.$modal.show('error-alert', {
            title: err.name || 'Error Reserving',
            message: err.message || "",
            type: err.type || 'error'
          });
        }
        });

    }
  }
}
</script>

<style lang="css">

.food-list-entry {
  font-family: 'Dosis', sans-serif;
  font-size: 1.08em;
}

.category-image {
  margin-left: 10px;
  margin-bottom: 0px;
}
.food-cell__header,
.food-cell__content {
  padding: 10px;
}

.list-entry__details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
}
.list-entry__details .details__cell {
  width: 30%;
}
.list-entry__actions {
  margin: auto;
}
</style>
