<template lang="html">
  <footer :class="{'collapsed-left' : collapsedLeft}">
      <div class="flex-container">
          <div class="flex-container__row footer-navigation">
              <div class="flex-container__item">
                  <h6 class="type--uppercase">Find</h6>
                  <ul class="list--hover">
                      <li><router-link :to="$siteRoutes.find.path">Find Food</router-link></li>
                      <li><router-link :to="$siteRoutes.about.path">About Us</router-link></li>
                  </ul>
              </div>
              <div class="flex-container__item">
                  <h6 class="type--uppercase">Partners</h6>
                  <ul class="list--hover">
                      <li><router-link :to="$siteRoutes.provide.path">Partner Console</router-link></li>
                      <li><router-link :to="$siteRoutes.workwithus.path">Work With Us</router-link></li>
                  </ul>
              </div>
              <div class="flex-container__item">
                  <h6 class="type--uppercase">More</h6>
                  <ul class="list--hover">
                    <li><router-link :to="$siteRoutes.info.path">FAQ</router-link></li>
                    <li><router-link :to="$siteRoutes.mobileapp.path">Mobile App</router-link></li>
                    <li><router-link :to="$siteRoutes.contactus.path">Contact us</router-link></li>
                  </ul>
              </div>
          </div>
          <hr>

          <div class="flex-container__row">
              <div class="flex-container__item">
                  <span class="type--fine-print">Copyright © 2017 FreeFoodForFamilies All rights reserved.</span>
                  <router-link class="type--fine-print" :to="$siteRoutes.privacy.path">Privacy</router-link>
                  <router-link class="type--fine-print" :to="$siteRoutes.terms.path">Terms</router-link>
                  <div>
                    <p class="type--fine-print">Please note that this is a beta version of FreeFoodForFamilies. All information and data shown is for demonstration and testing purposes only and we have no formal affiliation with any of the businesses referenced.</p>
                  </div>
              </div>
          </div>
      </div>
  </footer>
</template>

<script>

export default {
    name: "footer-view",
    components: {

     },
    props: [ 'collapsedLeft' ],
    firebase: {},
    data(){
      return {}
    },
    computed: {},
    methods: {}
}
</script>

<style lang="css">
footer {
  padding: 30px 20px 10px 20px;
  z-index: 998;
  background: #fff;
}

footer.collapsed-left {
  max-width: 30em;
}

.footer-navigation{
  max-width: 50em;
  text-align: center;
}

@media all and (max-width: 768px) {
  .footer-navigation{
    max-width: inherit;
    text-align: left;
  }
}

h6, h6:not(:last-child){
  margin-bottom: 0.16667em;
}
</style>
