<template lang="html">
  <transition name="slide-in">
  <div v-show="visible" :class="classes">
    <div class="v-notification__content">
      <span><slot></slot></span>
      <span><div class="v-notification__close-button" @click="toggle(false)">
        <i class="custom icomoon-cross">
        </i>
      </div></span>
    </div>
  </div>
  </transition>
</template>

<script>
import Vue from 'vue'
import Notif from './notification'

  export default {
      name: "notification",
      components: {  },
      props: {
        name: {
          required: true,
          type: String
        },
        classes: {
          type: [String, Array],
          default: 'v-notification',
        },
        displayDuration: {
          type: Number,
          default: 4000
        }
      },
      firebase: {},
      data(){ return {
        visible: false
       } },
       beforeMount () {
         Notif.event.$on('toggle', (name, state, params) => {
           if (name === this.name) {
             if (typeof state === 'undefined') {
               state = !this.visible
             }

             this.toggle(state, params)
           }
         });
       },
      computed: {},
      methods: {
        genEventObject (params) {
          //todo: clean this up (change to ...)
          var data = {
            name: this.name,
            timestamp: Date.now()
          }

          return Vue.util.extend(data, params || {});
        },
        toggle(state, params){
          console.log('show notification');

          const beforeEventName = this.visible ? 'before-close' : 'before-open';
          const afterEventName = this.visible ? 'closed' : 'opened';

          let stopEventExecution = false;

          const stop = () => { stopEventExecution = true }
          const beforeEvent = this.genEventObject({ stop, state, params });

          this.$emit(beforeEventName, beforeEvent);

          if (!stopEventExecution) {
            const afterEvent = this.genEventObject({ state, params });

            this.visible = state;
            this.$emit(afterEventName, afterEvent);
          }

          if (this.visible){
            setTimeout(() => {
              this.visible = false;
              console.log('hide notification');
            }, this.displayDuration)
          }
        }
      },
  }
</script>

<style lang="css" scoped>
.v-notification {

  padding: 10px;
  margin: 10px;
  border-radius: 3px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.25);

  position: fixed;
  z-index: 1000;
  right: 0;
  top: 100px;

  background: #d7e55c;
  font-size: 1.1em;
  text-align: center;
}

.v-notification__content {
  display: flex;
  align-items: center;
}
.v-notification__close-button {
  cursor: pointer;
  padding-left: 10px;
}

.slide-in-enter-active, .slide-in-leave-active {
  transition: all 0.7s;
}

.slide-in-enter, .slide-in-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

</style>
