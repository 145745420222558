import Notif from './notification.vue'

const Plugin = {
  install (Vue, options = {}){
    if (this.installed) {
      return
    }

    this.installed = true
    this.event = new Vue()

    Vue.prototype.$notification = {
      show(name, params) {
        Plugin.event.$emit('toggle', name, true, params)
      },
      hide(name, params) {
        Plugin.event.$emit('toggle', name, false, params)
      },
      toggle(name, params) {
        Plugin.event.$emit('toggle', name, undefined, params)
      }
    }

    Vue.component('notification', Notif)
  }
}

export default Plugin
