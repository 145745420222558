<!-- Displays a food entry in a reservable state -->
<template lang="html">
  <!-- Use v-show rather than if because this value could change frequently. https://vuejs.org/v2/guide/conditional.html#v-show  -->
  <div class="editable-food-list-entry food-list-entry">
    <div data-available="true" :data-entry-id="entry.id">
        <simple-accordion @closed="collapsed = true" @opened="collapsed = false">
          <div class="food-cell__header" slot="header">
            <span class="font-md">{{entry.name}}</span>
            <img class="category-image image--xxs pull-right" :src="categoryImagePath">
            <span class="pull-right">{{entry.totalListed}}<span v-show="!collapsed"> listed</span></span>
          </div>
          <div class="food-cell__content text-center">
            <div class="list-entry__details">
                  <div class="details__cell">
                      <div>
                          <img class="image--xs unmarg" alt="Image" src="img/svg/silverware_shorter.svg">
                          <span class="">Item Size</span>
                          <p>{{ entry.units }} each</p>
                      </div>
                  </div>
                  <div class="details__cell">
                      <div>
                        <img class="image--xs unmarg" alt="Image" src="img/svg/calendar.svg">
                        <span :class="{'red-text' : expired}">{{ expireLabelText }}</span>
                        <p>{{ entryExpiration }}</p>
                      </div>
                  </div>
                  <div class="details__cell">
                      <div>
                        <img class="image--xs unmarg" alt="Image" src="img/svg/simpleBarcode.svg">
                        <span>reserved</span>
                        <p>{{ numberReserved }}</p>
                      </div>
                  </div>
            </div>
            <div class="h-centered-content">
              <div class="list-entry__actions">

                <confirm-button class="action-button--primary" @button-click="deleteEntry">Delete All</confirm-button>
              </div>
            </div>

        </div>
        </simple-accordion>

  <hr class="unmarg"/>
    </div>
  </div>
</template>

<script>
import Fireapp from '../modules/fireapp.js';
import FireUtils from '../modules/fireutils.js';

import moment from 'moment';
import SimpleAccordion from './simple-accordion.vue';

export default {
  name: 'editable-food-list-entry',
  components: {
    SimpleAccordion
  },
  props: [ 'entryId', 'provider' ],
  firebase(){
    return{
      entry: {
        source: Fireapp.database().ref(`foodEntries/${this.entryId}`),
        asObject: true
      }
    }
  },
  data() { return {
    collapsed : true,

    deleteProcessing: false,
    deleteComplete: false
  } },
  computed: {
    expired () {
      return (this.entry.expirationDate < Date.now());
    },
    expireLabelText() {
      if(this.expired) {

        return "expired";
      } else {
        return "expires";
      }
    },
    entryExpiration(){
      return moment(this.entry.expirationDate).calendar();
    },
    availableCount(){
      // Get the availability through a store getter
      return this.$store.getters.availibilityCount(this.entry);
    },
    numberReserved(){
      return this.$store.getters.numberReserved(this.entry);
    },
    categoryImagePath(){
      if (!this.entry || !this.entry.category) {
        return "";
      }
      let category = FireUtils.values.categories[this.entry.category];
      return category.imageURL;
    }
  },
  methods: {
    confirmDelete(){
      let totalListed = this.entry.totalListed;
      let itemCount = `all ${totalListed} items`;
      if (totalListed === 1) {
        itemCount = `${totalListed} item`;
      }

      this.$modal.show('confirmation-modal', {
        title: 'Delete All?',
        message: `Are you sure you want to delete ${itemCount}?`,
        confirmButton: {
          title : 'Delete All',
          classes: ['btn--destructive']
        },
        callback: (confirmed) => { if (confirmed) { this.deleteEntry() }}
      })
    },
    deleteEntry(){
      this.deleteProcessing = true;
      // perform the async reserve action on the store
      this.$store.dispatch('deleteEntry', this.entry)
      .then(()=>{
        this.deleteComplete = true;
      })
      .catch((err) => {
        this.deleteProcessing = false;
        this.$modal.show('error-alert', {
          title: 'Error Deleting',
          message: err.message,
          type: 'error'
        });
      });
    }
  }
}
</script>

<style lang="css" scoped>

.red-text {
  color: red;
}
</style>
