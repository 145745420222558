<!-- Displays a list of food belonging to a specific provider -->
<template lang="html">
  <div v-show="hasVisibleEntries" class="provider-food-list">

      <div class="pad--top-40">
          <h3 class="unmarg">{{ provider.name }}</h3>
      </div>

       <!-- Create a list item for each entry owned by the provider. Bindind the entry id as the key allows vue to track the unique elements better. -->
        <component ref="entryItems" v-for="(v, entry) in filteredIds" :key="entry" :is="entryType" :entryId="entry" :provider="provider"></component>
  </div>

</template>

<script>
import Fireapp from '../modules/fireapp.js';
import FireUtils from '../modules/fireutils.js';
import FoodListEntry from './food-list-entry.vue';
import EditableFoodListEntry from './editable-food-list-entry.vue';

export default {
  name: 'provider-food-list',
  components: {
    'list-entry' : FoodListEntry,
    'editable-entry' : EditableFoodListEntry
  },
  props: [
    'provider',
    'editable',
    'entryFilter'
  ],
  data() { return {
  } },
  computed: {
    providerId(){
      return provider['.key'];
    },
    entryType(){
      return this.editable ? 'editable-entry' : 'list-entry'
    },
    shouldShow(){
      if(!this.provider || !this.provider.foodEntryIds) { return false; }

    },
    filteredIds() {
      let filtered = {};
      if (this.provider && this.provider.foodEntryIds){
        Object.keys(this.provider.foodEntryIds).forEach((id) => {
          let entry = FireUtils.values.foodEntries[id];
          if (entry && this.entryFilter(entry)){
            filtered[id] = true;
          }
        });
      }
      return filtered;
    },
    hasVisibleEntries(){
      let someEntriesVisible = this.filteredIds && Object.keys(this.filteredIds).length > 0;
      return someEntriesVisible;
    }
  },
  watch : {
    // Use a watch function so that every time the entry filter updates, the visible value will as well
    // entryFilter: function(val){
    //   this.visible = this.hasVisibleEntries;
    // }
  },
  methods: {}
}
</script>

<style lang="css" scoped>
</style>
