<template lang="html">
  <div class="admin-view">
   <div class="details">
       <div class="card">
         <div v-if="selected">
           <pending-provider-details :pending-record-user-id="selected.record['.key']" :admin-mode="true">
             <span slot="additional-actions">
               <a v-if="selected.type === 'awaitingReview'" class="btn btn--primary" @click="moveToReview(selected.record)">
                   <span class="btn__text">Review</span>
               </a>
               <span v-if="selected.type === 'inReview'">
                 <action-button class="action-button--primary" @button-click="moveToApproved(selected.record)" :is-loading.sync="approvalProcessing" :is-confirmed.sync="approvalComplete">Approve</action-button>

                 <a class="btn btn--primary" @click="moveToDenied(selected.record)">
                     <span class="btn__text">Deny</span>
                 </a>
               </span>
             </span>
           </pending-provider-details>
         </div>
         <div v-else class="no-items">
           Select a pending record below.
         </div>
       </div>
   </div>
   <div class="flex-columns">
     <div class="card">
       <h3 class="column-title">Awaiting Review</h3>
       <div>
         <pending-provider-cell v-for="record in awaitingReview" :pending-record="record" @selected="setSelected(record, 'awaitingReview')">
         </pending-provider-cell>
         <div v-if="awaitingReview.length < 1" class="no-items">
           No Records
         </div>
       </div>
     </div>
     <div class="card">
       <h3 class="column-title">In Review</h3>
       <div>
         <pending-provider-cell v-for="record in inReview" :pending-record="record" @selected="setSelected(record,'inReview')">
         </pending-provider-cell>
         <div v-if="inReview.length < 1" class="no-items">
           No Records
         </div>
       </div>
     </div>
     <div class="card">
       <h3 class="column-title">Denied</h3>
       <div>
         <pending-provider-cell v-for="record in denied" :pending-record="record" @selected="setSelected(record, 'denied')">
         </pending-provider-cell>
         <div v-if="denied.length < 1" class="no-items">
           No Records
         </div>
       </div>
     </div>
   </div>
  </div>
</template>

<script>
import Fireapp from '../../modules/fireapp.js';
import PendingProviderDetails from './pending-provider-details.vue';
import PendingProviderCell from './pending-provider-cell.vue';

  export default {
      name: "admin-view",
      components: {
        PendingProviderDetails,
        PendingProviderCell
      },
      props: [  ],
      firebase: {
        awaitingReview : Fireapp.database().ref('pendingProviders/awaitingReview'),
        inReview : Fireapp.database().ref('pendingProviders/inReview'),
        denied : Fireapp.database().ref('pendingProviders/denied')
      },
      data(){ return {
        selected : null,

        approvalProcessing: false,
        approvalComplete: false
       } },
      computed: {},
      methods: {
        setSelected(record, type){
          console.log(`set record: ${record}`);
          if (record){
          this.selected = { record, type }
        }
      },
      moveToReview(record){
        this.$store.dispatch('reviewProvider', record).then(error => {
          if (!error){
            this.selected.type = 'inReview';
          }
        });
      },
      moveToDenied(record){
        record.reviewerInfo = {};
        this.$store.dispatch('denyProvider', record).then(error => {
          if (!error){
            this.selected.type = 'denied';
          }
        });
      },
      moveToApproved(record){
        this.approvalProcessing = true;

        record.reviewerInfo = {};

        let provider = record.provider;
        let addressComp = provider.address;
        let address = `${addressComp.street}, ${addressComp.city} ${addressComp.country} ${addressComp.postalCode}`;

        this.$googleMaps.geocode({ address }).asPromise()
        .then((response) => {
          let results = response.json.results[0];
          let geoLoc = results.geometry.location;
          console.log('geoLoc:');
          console.log(geoLoc);
          let location = geoLoc && {latitude: geoLoc.lat, longitude: geoLoc.lng} || {latitude: 0, longitude: 0};
          provider.location = location;
          return provider
        })
        .then((provider) => {
          this.$store.dispatch('approveProvider', record).then(error => {
            if (!error){
              this.selected = null;
            }
            this.approvalComplete = true;
          });
        })
        .catch((error) => {
          console.log(error);
            this.$modal.show('error-alert', {
              title: 'Error Determining Location',
              message: 'Could not determine location coordinates for this provider.',
              type: 'error'
            })
            this.approvalProcessing = false;
        })



      }
      },
  }
</script>

<style lang="css" scoped>
.admin-view {
  background: linear-gradient(0deg, #fff, #e5f7f3);
}
.card {
  margin: 10px;
  padding: 10px;
  background: white;
  border: 1px solid #ececec;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}

.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.details > div {
  min-height: 400px;
  padding: 30px;
}

.no-items {
  text-align: center;
  width: 100%;
  height: 100%;
  color: #666;
}

.flex-columns {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

@media all and (max-width: 768px){
  .flex-columns {
    flex-direction: column;
    align-items: stretch;
  }
}

.flex-columns > div {
  flex: 1 1 0px;
}

.column-title {
  padding: 10px 5px 15px 5px;
  border-bottom: 1px solid #75dbcf;
  margin-bottom: 10px;
}

</style>
