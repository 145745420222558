// This file contains simple universal prototype extensions and exports some generic functions. It should not contain any app specific logic.

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}


export default {

  // This applies any properties of template that are not defined on the object, but it
  // does not override existing properties or check type conformance
  normalize(obj, template) {
    Object.keys(template).forEach((k) => {
      obj[k] = !(obj[k]) ? template[k] : obj[k]
    });
  },

  extend(obj, other) {
    for (var property in other) {
        obj[property] = other[property];
    }
    return obj;
  },

  // Calculate the current bootstrap breakpoint based on the window width
  viewportBreakpoint() {
    let w = window.innerWidth;
    if (w < 768) { return 'xs';}
    else if (w >= 768 && w < 992) { return 'sm'; }
    else if (w >= 992 && w < 1200) { return 'md'; }
    else { return 'lg'; }
  }

}

export const $ = window.$ = window.jQuery;
