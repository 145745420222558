'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (vueElement, googleMapObject, events) {
  _lodash2.default.forEach(events, function (eventName) {
    var exposedName = eventName;
    googleMapObject.addListener(eventName, function (ev) {
      vueElement.$emit(exposedName, ev);
    });
  });
}; /* vim: set softtabstop=2 shiftwidth=2 expandtab : */