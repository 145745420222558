<template lang="html">
  <div class="contact-received">

    <div class="v-centered-content text-center large-message">
      <div class="h-centered-content">
        <i class="custom icon icomoon-circle-check super-size-icon color--green"></i>
        <h1 class="h1--large color--orange">Thank You!</h1>
        <p class="font-lg">We have recieved your questions or feedback and will respond soon.</p>
      </div>
    </div>
    <div class="bg--primary-2 decorative-stripe">

    </div>
  </div>
</template>

<script>
  export default {
      name: "contact-received",
      components: {  },
      props: [  ],
      firebase: {},
      data(){ return {  } },
      computed: {},
      methods: {},
  }
</script>

<style lang="css" scoped>
.super-size-icon {
  font-size: 150px;
  margin-bottom: 30px;
}
.large-message {
  padding: 5em 0;
}
</style>
