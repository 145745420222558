<template lang="html">
  <div class="mobileapp-view">
    <div class="v-centered-content">
      <div class="h-centered-content text-center">
          <h1 class="">Get the App</h1>
          <h3 class="">Free food a tap away<br><span class=" font-intro_script">Pretty Sweet</span></h3>
          <img class="app-store-link" src="img/appleStore.png" alt="apple store">
      </div>
    </div>

  </div>
</template>

<script>
  import CoverHeader from './cover-header.vue';

  export default {
      name: "mobileapp-view",
      components: {
        CoverHeader
       },
      props: [  ],
      firebase: {},
      data(){
        return {

        }
      },
      computed: {},
      methods: {}
  }
</script>

<style lang="css" scoped>
.mobileapp-view {
  background: url(img/stock/mobile-featured.jpg);
  background-position: center center;
  background-size: cover;
  height: 60vh;
}
.app-store-link {
  max-width: 200px;
  cursor: pointer;
}
h1 {
  margin-bottom: 0px;
}

.mobileapp-view div * {
  color: #fff;
}
</style>
