<template lang="html">
  <div class="provide-view">
    <modal-view v-if="entryModalVisible" @close="entryModalVisible = false" modalType="createEntry"></modal-view>


      <!-- <cover-header bg-image-source="./img/stock/green-apples.jpg" overlay-amount="0">
            <div class="header-content">
              <div class="centered-vertically">
                <div class="centered-content">
                  <h1 class="font-intro_head color--primary">Partner Console</h1>
                  <h3>{{ providers && providers[0] && providers[0].name || 'not working' }}</h3>
                </div>
              </div>
              <div class="add-entry-button btn btn--primary btn--icon" @click="entryModalVisible = true">
                  <span class="btn__text"><i class="custom icomoon-plus" ></i>List Food</span>
              </div>
            </div>
      </cover-header>

      <section class="container space--sm">
          <div class="row">
            <div id="food-list__container" class="container col-xs-12 col-md-8">
                  <div class="row">
                      <div class="col-xs-12">
                        <div id="food-list" class="masonry">
                          <food-list :providers="providers" :categories="categories" :entry-filter="entryFilter" :provider-mode="true"></food-list>
                        </div>
                      </div>
                  </div>
              </div>
            <div id="orders-container" class="col-xs-12 col-md-4 pull-right boxed boxed--xs boxed--border">
              <provider-orders></provider-orders>
            </div>
          </div>
      </section> -->

      <div class="dashboard-container">
        <div class="dashboard-navigation">
          <div v-for="section in dashboardSections" @click="openSection = section" :class="{'active' : (openSection.enumVal === section.enumVal)}"><i class="custom" :class="section.icon"></i> {{ section.title }}</div>
        </div>
        <section>
          <div class="centered-vertically ">
            <div class="centered-content">
              <h1 class="font-intro_head color--primary">{{ providers && providers[0] && providers[0].name || 'not working' }}</h1>
              <h4>Partner Console</h4>
            </div>
          </div>
          <div class="section-header">
            <h1>{{openSection.title}}</h1>
            <div v-if="displayingFoodList" class="button--circular marg-bottom-10" @click="entryModalVisible = true">
              <span><i class="custom icomoon-plus"></i></span>
            </div>
          </div>

          <food-list v-if="displayingFoodList" :providers="providers" :categories="categories" :entry-filter="entryFilter" :provider-mode="true"></food-list>

          <provider-orders v-if="displayingOrders"></provider-orders>

          <pending-provider-details v-if="displayingAccount" :pending-record-user-id="currentUserId" :display-progress-bar="false">
          </pending-provider-details>

        </section>
      </div>

  </div>
</template>

<script>
import Fireapp from '../modules/fireapp.js';
import Utils from '../modules/utilities.js';

import CoverHeader from './cover-header.vue';
import FoodList from './food-list.vue';
import ProviderOrders from './provider-orders.vue';
import ModalView from './modal.vue';
import PendingProviderDetails from './admin/pending-provider-details.vue';

const dashboardSections = {
  foodList : {enumVal : 0, title: 'Listed Food', icon: 'icomoon-clipboard'},
  orders : {enumVal : 1, title: 'Orders', icon: 'icomoon-paper-stack'},
  account : {enumVal : 2, title: 'Account', icon: 'icomoon-briefcase'}
}

  export default {
      name: "provide-view",
      components: {
        CoverHeader,
        FoodList,
        ProviderOrders,
        ModalView,
        PendingProviderDetails
      },
      props: [ 'navigationOverlay' ],
      firebase: {
        allProviders: Fireapp.database().ref(`providers`),
        categories: Fireapp.database().ref('categories')
      },
      data(){ return {
        entryFilter : () => { return (e) => {
          return e.providerId == this.provider.id;
        } },
        entryModalVisible : false,

        dashboardSections,
        openSection :  dashboardSections.foodList
       } },
      computed: {
        // data dependant on the store, has to be computed
        currentUserId(){ return this.$store.state.currentUserId; },
        providers(){

          let filtered = this.allProviders.filter((p) => p.accountId == this.$store.state.currentUserId);

          return filtered;
        },
        providerName(){

        },
        displayingFoodList(){ return this.openSection.enumVal === this.dashboardSections.foodList.enumVal },
        displayingOrders(){ return this.openSection.enumVal === this.dashboardSections.orders.enumVal },
        displayingAccount(){ return this.openSection.enumVal === this.dashboardSections.account.enumVal }
      },
      methods: {},
      beforeRouteEnter (to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        // calling next, allows the routing to proceed to provider console
        // check for login occurs within router itself
        next(vm => {
          // access to component instance via `vm`
          vm.$emit('update:navigationOverlay', 'overlayLight');
        });

      }
  }
</script>

<style lang="css" scoped>

.centered-vertically {
  display: flex;
  align-items: center;
  /*height: 100%;*/
}

.centered-content {
  padding: 20px 0;
}

h1, h2, h3,h4 {
  margin-bottom: 0;
}

.section-header.h1 {
  color: #595B70;
}

.button--circular {
  color: #fff;
  background: #ff8e5a;
  display: block;

  width: 3em;
  height: 3em;
  border-radius: 1.5em;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: 0.3s;
}
.button--circular:hover {
  transform: scale(1.2);
  opacity: 0.8;
}
.button--circular span {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.3em;
}

.marg-bottom-10 {
  margin-bottom: 10px;
}

.dashboard-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dashboard-navigation {
  padding: 5px 5px;
  background: #595b70;
  display: flex;
  flex: 0 0 auto;
}
.dashboard-navigation > div {
  padding: 10px;
  margin: 8px;
  cursor: pointer;
  color: #fff;
  font-size: 1.2em;
}
.dashboard-navigation > div:hover,
.dashboard-navigation > div.active {
  background: #292a35;
  border-radius: 3px;
}
.dashboard-navigation > div:active{
  background: #3d3e4d;
}
.dashboard-navigation > div i {
  padding-right: 0.5em;
}

.dashboard-container section {
  padding: 10px 30px;
  max-width: 65em;
}

.dashboard-container section {
  background-color: #fff;
  border-radius: 3px;
  flex: 1 1 auto;
}

.section-header {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.5em;
}
.section-header h1{
  flex: 1 1 auto;
}

.section-header a {
  padding: 10px;
  cursor: pointer;
}

i.icono-hamburger, i.icono-pin, i.icono-tag {
  color: #555;
}

@media all and (min-width: 768px){
  .dashboard-container section {
    padding: 10px 60px;
  }
  .dashboard-container {
    flex-direction: row;
  }
  .dashboard-navigation {
    display: block;
    padding: 15px 5px;
  }
}
</style>
