<template lang="html">
  <div class="pending-provider-cell" @click="$emit('selected')">
    <div><strong>{{pendingRecord.provider.name}}</strong></div>
    <div>{{formattedAddress}}</div>
  </div>
</template>

<script>
  export default {
      name: "pending-provider-cell",
      components: {  },
      props: [ 'pendingRecord' ],
      firebase: {},
      data(){ return {  } },
      computed: {
        formattedAddress(){
          let address = this.pendingRecord.provider.address;
          if (address){
            let street = address.street ? address.street : '';
            let city = address.city ? address.city : '';
            let state = address.state ? address.state : '';

            return `${street}, ${city} ${state}`;
          }
          return '';
        }
      },
      methods: {},
  }
</script>

<style lang="css" scoped>
.pending-provider-cell {
  padding: 10px 5px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  line-height: 1.4em;
}

.pending-provider-cell:hover {
  background: #e6ffff;
}

.pending-provider-cell:active {
  background: #ccffff;
}
</style>
