import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import FireUtils from '../modules/fireutils.js';
import addressNormalizer from 'google-places-address-normalizer';

import entries from './store-modules/entries.js';
import orders from './store-modules/orders.js';
import providers from './store-modules/providers.js';

const store = new Vuex.Store({

  // Primary store information
  state: {
    currentUserId : "",
    currentLocation : {
      lat: 38.8923,
      lng: (-77.0218)
    },
    geoLocationInfo : {
      address_components : [
        {
          long_name: 'Washington',
          short_name: 'Washington',
          types: ["locality", "political"]
        },
        {
          long_name: 'District of Columbia',
          short_name: 'DC',
          types: ["administrative_area_level_1", "political"]
        }
      ]
    },
    userIsProvider : false,
    userIsAdmin : false
  },
  mutations: {
    setCurrentUserId(state, id){
      state.currentUserId = id;
    },
    setCurrentLocation(state, location){
      state.currentLocation = location;
    },
    setGeoLocationInfo(state, info){
      state.geoLocationInfo = info;
    },
    setUserIsAdmin(state, isAdmin) {
      state.userIsAdmin = isAdmin;
    }
  },
  actions : {
    updateUserIsAdmin({commit}){
      return new Promise((resolve, reject) => {
       FireUtils.values.determineAdminStatus().then((status) => {
         commit('setUserIsAdmin', (status === 'Authorized'));
         resolve();
       }).catch(() => { reject(); });
     });
    }
  },
  getters : {
    currentUser: state => {
      // Get the db object representing info about the user.
          let userId = state.currentUserId;
          let user = FireUtils.values.users[userId];
          user = user ? user : {};
          FireUtils.normalize.user(user, userId);
          return user;
    },
    getCurrentLocation: state => {
      return state.currentLocation;
    },
    userIsProvider: state => {
      if (FireUtils.values && FireUtils.values.accounts[state.currentUserId]) {
        return true;
      } else {
        return false;
      }
    },
    currentProvider : state => {
      if (FireUtils.values) {
        let providerId = FireUtils.values.accounts[state.currentUserId];
        if (providerId) {
          let provider = FireUtils.values.providers[providerId];
          if (provider) {
            return FireUtils.normalize.provider(provider);
          }
        }
      }

      return null;

    },
    currentLocationName: state => {
      let address_components = state.geoLocationInfo.address_components;
      if (address_components){
        let normalized = addressNormalizer(address_components);
        let city = normalized.city;
        let state = normalized.state_abbreviation;
        return `${city}, ${state}`;
      }
      return '';
    }


  },

  // Modules are a way to extract code that would otherwise just exist in the main store sections.
  modules : {
    entries,
    orders,
    providers
  }
});

// Observe current user and update the store accordingly
FireUtils.values.$on('FireUtils-userChanged', (id) => {
  store.commit('setCurrentUserId', id);
  store.dispatch('updateUserIsAdmin');
});

export default store;
