<template lang="html">
  <div class="pending-progress-bar">
    <div class="progress-track">
      <div class="step" :class="{completed : isApplyning}">
        Apply
      </div>
      <div class="step" :class="{completed : isAwaitingReview}">
        Awaiting Review
      </div>
       <div class="step" :class="{completed : isInReview}">
         In Review
       </div>
       <div class="step" :class="{completed : isAccepted}">
        Accepted
        </div>
      </div>
  </div>
</template>

<script>
import FireUtils from '../../modules/fireutils.js';

  export default {
      name: "pending-progress-bar",
      components: {  },
      props: [ 'pendingProgress' ],
      firebase: {},
      data(){ return {  } },
      computed: {
        isApplyning(){
          return this.pendingProgress.enumValue >= FireUtils.statusTypes.pendingProviders.applying.enumValue;
        },
        isAwaitingReview(){
          return this.pendingProgress.enumValue >= FireUtils.statusTypes.pendingProviders.awaitingReview.enumValue;
        },
        isInReview(){
          return this.pendingProgress.enumValue >= FireUtils.statusTypes.pendingProviders.inReview.enumValue;
        },
        isAccepted(){
          return this.pendingProgress.enumValue >= FireUtils.statusTypes.pendingProviders.approved.enumValue;
        }
      },
      methods: {},
  }
</script>

<style lang="css" scoped>


.progress-track {
  padding: 50px;
  display: flex;
  align-items: stretch;
}

.progress-track .step {
  flex: 1 1 auto;
  position: relative;
  padding: 0px 20px 15px 20px;
  border-bottom: 2px solid #ddd;
  text-align: center;
}

.progress-track .step.completed {
  border-bottom: 3px solid #eeaa88;
}

.progress-track .step:first-of-type {
  padding-left: 0px;
  text-align: left;
}

.progress-track .step:last-of-type {
  padding-right: 0px;
  text-align: right;
}

.progress-track .step::after {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 12px;
  border: 2px solid #ddd;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 100%;
  margin-top: -8px;
  background: #bbb;
}

.progress-track .step:first-of-type::after {
  left: 0px;
  margin-left: 0px;
}

.progress-track .step:last-of-type::after {
  left: 100%;
  margin-left: -16px;
}

.progress-track .step.completed::after {
  background: #eeaa88;
  border-color:#ffccbb;
}
</style>
