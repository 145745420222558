<template lang="html">
  <section class="ui main text container legal-section">
      <div class="row">
          <div class="col-xs-12 col-md-8 col-md-offset-2">

              <h1>Terms of Use</h1>
              <hr/>
              <div class="font-open_sans">

                  <h3>Ownership of Site; Agreement to Terms of Use</h3>
                  <p>These Terms and Conditions of Use (the “Terms of Use”) apply to the web site located at www.freefoodforfamilies.com, its subsidiaries and affiliates (collectively, the “Site”). <br><br>BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU
                      DO NOT AGREE, DO NOT USE THE SITE.<br><br> FreeFoodForFamilies reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It is your responsibility to check these Terms of Use
                      periodically for changes. Your continued use of the Site following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these Terms of Use, FreeFoodForFamilies grants you a personal,
                      non-exclusive, non-transferable, limited privilege to enter and use the Site. </p>

                  <h3>Content</h3>
                  <p>All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code (collectively, “Content”), including but not limited to the design, structure, selection, coordination, expression,
                      “look and feel” and arrangement of such Content, contained on the Site is owned, controlled or licensed by or to FreeFoodForFamilies and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual
                      property rights and unfair competition laws. Except as expressly provided in these Terms of Use, no part of the Site and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated,
                      transmitted or distributed in any way (including “mirroring”) to any other computer, server, Web site or other medium for publication or distribution or for any commercial enterprise, without FreeFoodForFamilies' express prior written
                      consent.
                  </p>

                  <h3>Your Use of the Site</h3>
                  <p>You may not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Site or
                      any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Site or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available
                      through the Site. FreeFoodForFamilies reserves the right to bar any such activity.<br> You may not attempt to gain unauthorized access to any portion or feature of the Site, or any other systems or networks connected to the Site or
                      to any FreeFoodForFamilies server, or to any of the services offered on or through the Site, by hacking, password “mining” or any other illegitimate means. You may not probe, scan or test the vulnerability of the Site or any network
                      connected to the Site, nor breach the security or authentication measures on the Site or any network connected to the Site. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to the
                      Site, or any other partner of FreeFoodForFamilies including any FreeFoodForFamilies account not owned by you, to its source, or exploit the Site or any service or information made available or offered by or through the Site, in any
                      way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Site. You agree that you will not take any action that
                      imposes an unreasonable or disproportionately large load on the infrastructure of the Site or FreeFoodForFamilies' systems or networks, or any systems or networks connected to the Site or to FreeFoodForFamilies.<br> You agree not to
                      use any device, software or routine to interfere or attempt to interfere with the proper working of the Site or any transaction being conducted on the Site, or with any other person’s use of the Site. <br> You may not forge headers
                      or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to FreeFoodForFamilies on or through the Site or any service offered on or through the Site. You may not pretend that you are,
                      or that you represent, someone else, or impersonate any other individual or entity. You may not use the Site or any Content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any
                      illegal activity or other activity which infringes the rights of FreeFoodForFamilies or others.</p>

                  <h3>Accounts, Passwords and Security</h3>
                  <p>Certain features or services offered on or through the Site may require you to open an account (including setting up a FreeFoodForFamilies ID and password). You are entirely responsible for maintaining the confidentiality of the information
                      you hold for your account, including your password, and for any and all activity that occurs under your account as a result of your failing to keep this information secure and confidential. You agree to notify FreeFoodForFamilies immediately
                      of any unauthorized use of your account or password, or any other breach of security. You may be held liable for losses incurred by FreeFoodForFamilies or any other user of or visitor to the Site due to someone else using your FreeFoodForFamilies
                      ID, password or account as a result of your failing to keep your account information secure and confidential.<br> You may not use anyone else’s FreeFoodForFamilies ID, password or account at any time without the express permission
                      and consent of the holder of that FreeFoodForFamilies ID, password or account. FreeFoodForFamilies cannot and will not be liable for any loss or damage arising from your failure to comply with these obligations.</p>

                  <h3>Privacy</h3>
                  <p>FreeFoodForFamilies' Privacy Policy applies to use of this Site. Additionally, by using the Site, you acknowledge and agree that Internet transmissions are never completely private or secure. You understand that any message or information
                      you send to the Site may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.</p>

                  <h3>Links to Other Sites and to the FreeFoodForFamilies Site</h3>
                  <p>This Site may contain links to other independent third-party Web sites (“Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under FreeFoodForFamilies' control, and FreeFoodForFamilies
                      is not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with
                      these Linked Sites.</p>

                  <h3>Disclaimers</h3>
                  <p>FREEFOODFORFAMILIES DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE OR FEATURE OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE
                      SITE AND ITS CONTENT ARE DELIVERED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. ALL INFORMATION PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT NOTICE. FREEFOODFORFAMILIES CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THE
                      SITE WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES. FREEFOODFORFAMILIES DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                      PURPOSE. FREEFOODFORFAMILIES DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE AND/OR ANY FREEFOODFORFAMILIES SERVICES. YOU ASSUME TOTAL RESPONSIBILITY
                      FOR YOUR USE OF THE SITE AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST FREEFOODFORFAMILIES FOR DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE
                      BARGAIN BETWEEN THE PARTIES.<br> The above disclaimer applies to any damages, liability or injuries caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer
                      virus, communication line failure, theft or destruction of or unauthorized access to, alteration of, or use, whether for breach of contract, tort, negligence or any other cause of action. FreeFoodForFamilies reserves the right to do
                      any of the following, at any time, without notice: (1) to modify, suspend or terminate operation of or access to the Site, or any portion of the Site, for any reason; (2) to modify or change the Site, or any portion of the Site, and
                      any applicable policies or terms; and (3) to interrupt the operation of the Site, or any portion of the Site, as necessary to perform routine or non-routine maintenance, error correction, or other changes.</p>

                  <h3>Limitation of Liability</h3>
                  <p>Except where prohibited by law, in no event will FreeFoodForFamilies be liable to you for any indirect, consequential, exemplary, incidental or punitive damages, including lost profits, even if FreeFoodForFamilies has been advised of the
                      possibility of such damages. If, notwithstanding the other provisions of these Terms of Use, FreeFoodForFamilies is found to be liable to you for any damage or loss which arises out of or is in any way connected with your use of the
                      Site or any Content, FreeFoodForFamilies' liability shall in no event exceed US$100.00. Some jurisdictions do not allow limitations of liability, so the foregoing limitation may not apply to you.</p>

                  <h3>Indemnity</h3>
                  <p>You agree to indemnify and hold FreeFoodForFamilies, its officers, directors, agents, subsidiaries and affiliates, harmless from any demands, loss, liability, claims or expenses (including attorneys’ fees), made against FreeFoodForFamilies
                      by any third party due to or arising out of or in connection with your use of the Site.</p>

                  <h3>Violation of These Terms of Use</h3>
                  <p>FreeFoodForFamilies may disclose any information we have about you (including your identity) if we determine that such disclosure is necessary in connection with any investigation or complaint regarding your use of the Site, or to identify,
                      contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) FreeFoodForFamilies' rights or property, or the rights or property of visitors to or users of
                      the Site. FreeFoodForFamilies reserves the right at all times to disclose any information that FreeFoodForFamilies deems necessary to comply with any applicable law, regulation, legal process or governmental request. FreeFoodForFamilies
                      also may disclose your information when FreeFoodForFamilies determines that applicable law requires or permits such disclosure, including exchanging information with other companies and organizations for fraud protection purposes.
                      <br>You acknowledge and agree that FreeFoodForFamilies may preserve any transmittal or communication by you with FreeFoodForFamilies through the Site or any service offered on or through the Site, and may also disclose such data if required
                      to do so by law or FreeFoodForFamilies determines that such preservation or disclosure is reasonably necessary to (1) comply with legal process, (2) enforce these Terms of Use, (3) respond to claims that any such data violates the
                      rights of others, or (4) protect the rights, property or personal safety of FreeFoodForFamilies, users of or visitors to the Site, and the public. <br>You agree that FreeFoodForFamilies may, in its sole discretion and without prior notice,
                      terminate your access to the Site and/or block your future access to the Site if we determine that you have violated these Terms of Use or other agreements or guidelines which may be associated with your use of the Site. You also agree
                      that any violation by you of these Terms of Use will constitute an unlawful and unfair business practice, and will cause irreparable harm to FreeFoodForFamilies for which monetary damages would be inadequate, and you consent to FreeFoodForFamilies
                      obtaining any injunctive or equitable relief that FreeFoodForFamilies deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies FreeFoodForFamilies may have at law or in equity. You
                      agree that FreeFoodForFamilies may, in its sole discretion and without prior notice, terminate your access to the Site, for cause, which includes (but is not limited to) (1) requests by law enforcement or other government agencies,
                      (2) a request by you (self-initiated account deletions), (3) discontinuance or material modification of the Site or any service offered on or through the Site, or (4) unexpected technical issues or problems. If FreeFoodForFamilies
                      does take any legal action against you as a result of your violation of these Terms of Use, FreeFoodForFamilies will be entitled to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such action, in
                      addition to any other relief granted to FreeFoodForFamilies. <br>You agree that FreeFoodForFamilies will not be liable to you or to any third party for termination of your access to the Site as a result of any violation of these Terms
                      of Use.</p>

                  <h3>Governing Law; Dispute Resolution</h3>
                  <p>You agree that all matters relating to your access to or use of the Site, including all disputes, will be governed by the laws of the United States and by the laws of the State of California without regard to its conflicts of laws provisions.
                      You agree to the personal jurisdiction by and venue in the state and federal courts in Santa Clara County, California, and waive any objection to such jurisdiction or venue. The preceding provision regarding venue does not apply if
                      you are a consumer based in the European Union. If you are a consumer based in the European Union, you may make a claim in the courts of the country where you reside. Any claim under these Terms of Use must be brought within one (1)
                      year after the cause of action arises, or such claim or cause of action is barred. Claims made under the separate terms and conditions of purchase for goods and services are not subject to this limitation. No recovery may be sought
                      or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and attorneys’ fees. In the event of any controversy or dispute between FreeFoodForFamilies and you arising out of
                      or in connection with your use of the Site, the parties shall attempt, promptly and in good faith, to resolve any such dispute. If we are unable to resolve any such dispute within a reasonable time (not to exceed thirty (30) days),
                      then either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available to them under applicable law.</p>

                  <h3>Void Where Prohibited</h3>
                  <p>FreeFoodForFamilies administers and operates the www.freefoodforfamilies.com Site from its location in the USA. Although the Site is accessible worldwide, not all features, products or services discussed, referenced, provided or offered
                      through or on the Site are available to all persons or in all geographic locations, or appropriate or available for use outside the United States. FreeFoodForFamilies reserves the right to limit, in its sole discretion, the provision
                      and quantity of any feature, product or service to any person or geographic area. Any offer for any feature, product or service made on the Site is void where prohibited. If you choose to access the Site from outside the United States,
                      you do so on your own initiative and you are solely responsible for complying with applicable local laws.</p>

                  <h3>Miscellaneous</h3>
                  <p>You may not use or export or re-export any Content or any copy or adaptation of such Content, or any product or service offered on the Site, in violation of any applicable laws or regulations, including without limitation United States
                      export laws and regulations. If any of the provisions of these Terms of Use are held by a court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions shall be limited or eliminated to the minimum
                      extent necessary and replaced with a valid provision that best embodies the intent of these Terms of Use, so that these Terms of Use shall remain in full force and effect. These Terms of Use constitute the entire agreement between
                      you and FreeFoodForFamilies with regard to your use of the Site, and any and all other written or oral agreements or understandings previously existing between you and FreeFoodForFamilies with respect to such use are hereby superseded
                      and cancelled. FreeFoodForFamilies will not accept any counter-offers to these Terms of Use, and all such offers are hereby categorically rejected. FreeFoodForFamilies' failure to insist on or enforce strict performance of these Terms
                      of Use shall not be construed as a waiver by FreeFoodForFamilies of any provision or any right it has to enforce these Terms of Use, nor shall any course of conduct between FreeFoodForFamilies and you or any other party be deemed to
                      modify any provision of these Terms of Use. These Terms of Use shall not be interpreted or construed to confer any rights or remedies on any third parties.</p>

                  <h3>Feedback and Information</h3>
                  <p>Any feedback you provide at this site shall be deemed to be non-confidential. FreeFoodForFamilies shall be free to use such information on an unrestricted basis.</p>

                  <p>If you have any questions, <router-link :to="$siteRoutes.contactus.path">contact us</router-link>.<br> Updated August 1, 2017</p>
              </div>

          </div>
      </div>
  </section>

</template>

<script>
export default {
    name: "terms-view",
    components: {

     },
    props: [  ],
    firebase: {},
    data(){
      return {

      }
    },
    computed: {},
    methods: {},
    beforeRouteEnter (to, from, next) {
      // called before the route that renders this component is confirmed.
      // does NOT have access to `this` component instance,
      // because it has not been created yet when this guard is called!

      // calling next, allows the routing to proceed
      next(vm => {
        // access to component instance via `vm`
        vm.$emit('update:navigationOverlay', 'overlayLight');
      });
    }
}
</script>

<style lang="css" scoped>
</style>
