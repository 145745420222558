<template lang="html">
  <div class="no-results">
    <div class="text-center">
        <i class="icon icon--xxl icon-Magnifi-Glass2 color--primary-2"></i>
        <h3>{{ title }}</h3>
        <p class="font-lg">{{ description }}</p>
    </div>
  </div>
</template>

<script>
  export default {
      name: "no-results",
      components: {  },
      props:  {
        title: {
          type: String,
          required: false,
          default: 'No Items Found'
        },
        description: {
          type: String,
          required: false,
          default: 'Please try a different search.'
        }
      },
      firebase: {},
      data(){ return {  } },
      computed: {},
      methods: {},
  }
</script>

<style lang="css" scoped>
.no-results {
  margin: 30px 0;
}
</style>
