<template lang="html">
<div class="apply-provider">

  <h1 class="color--primary-2 text-center">Apply as a Provider</h1>
   <pending-provider-details :pending-record-user-id="currentUser.uid" :display-progress-bar="true">
     <span slot="additional-actions">
       <a class="btn btn--primary" @click="cancelApplication()">
           <span class="btn__text">Cancel Application</span>
       </a>
     </span>
   </pending-provider-details>

      </div>
</template>

<script>
import Fireapp from '../modules/fireapp.js';
import FireUtils from '../modules/fireutils.js';
import routes from '../vue-router/routes.js';

import PendingProviderDetails from './admin/pending-provider-details.vue';

import { required, minLength, between } from 'vuelidate/lib/validators';

let statusTypes = FireUtils.statusTypes.pendingProviders;

export default {
  name: "apply-provider",
  components: { PendingProviderDetails },
  props: [],
  firebase: {
  },
  data() {
    return {
      statusTypes,
    }
  },
  validations: {
    name: { required }
  },
  computed: {
    currentUser(){
      let currentUser = FireUtils.values.currentUser;
      return currentUser;
    }
  },
  methods: {
    cancelApplication(){
      let provider = {
        accountId: this.currentUser.uid
      }
      this.$store.dispatch('cancelProviderApplication', {provider})
      .then(() => {});
    }
  },
}

</script>

<style lang="css" scoped>

h1, h2, h3, h4 {
  margin-bottom: 0;
}

.apply-provider {
  padding: 10px;
  max-width: 50em;
  margin: auto;
}
</style>
