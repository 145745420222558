<template lang="html">
  <div>
    <a id="two-problems" class="in-page-link"></a>
    <section class="imagebg text-center in-page-link unmarg--top">
      <div class="background-image-holder">
        <img alt="background" src="img/bg/slate-1080.svg">
      </div>
      <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="h1--large">Why?</h1>
            </div>
        </div>
        <!--end of row-->
      </div>
    </section>
    <section class="imagebg image--light">
      <div class="background-image-holder">
          <img alt="background" src="img/bg/aqua-diag-900.svg">
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-10 col-xs-offset-1 visible-xs">
            <img src="./img/stock/sad-boy-empty-plate.jpg" class="img-rounded" alt="">
          </div>
          <div class="col-xs-11 col-sm-4 col-xs-offset-1 container">
            <h1 class="animated pad-top--20 unpad-bottom">Food Insecurity</h1>
            <p class="lead font-lg primary"> 48 million people across the country face hunger, including 15 million children and 7 million seniors.</p>

            <p class="lead">Trying to make ends meet, they are sometimes forced to choose whether to buy food or to pay utility bills or medical expenses. They are students, veterans, families, and our neighbors.</p>
            <p>Executive Summary 2015 <br><a href="http://www.FeedingAmerica.org">FeedingAmerica.org</a></p>
          </div>
          <div class="col-sm-4 col-sm-offset-1 hidden-xs">
            <img src="./img/stock/sad-boy-empty-plate.jpg" class="img-rounded" alt="">
          </div>
        </div>
      </div>
    </section>
    <section class="imagebg image--light">
      <div class="background-image-holder">
          <img alt="background" src="img/bg/cutie-diag-900.svg">
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-10 col-xs-offset-1 col-sm-4">
            <img src="./img/stock/bread-shelves.jpg" class="img-rounded" alt="">
          </div>
          <div class="col-xs-11 col-xs-offset-1 col-sm-4 container">
            <h1 class="animated pad-top--20 unpad-bottom">Food Surplus</h1>
            <p class="lead font-lg primary"> 40 percent of food in America ends up in the landfill, or as much as 70 billion pounds of food a year.</p>

            <p class="lead">Retailers, restaurants, and suppliers often throw away what they don’t sell or use of their inventory. This can  be thousands of pounds of edible food uneaten each week.</p>
              <router-link class="router-link flex__justify-end"  :to="routes.workwithus.path">
                <span>Help solve the problem.</span><i class="icon icon-Arrow-Right color--orange"></i>
              </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import routes from '../vue-router/routes.js';
export default {
  name: "site-motivation",
  components: {

   },
  props: [  ],
  firebase: {},
  data(){
    return {
      routes
    }
  },
  methods: {},
}
</script>

<style lang="css">
</style>
