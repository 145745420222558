<template lang="html">
  <div>

    <section class="explanation-process imagebg text-center in-page-link unmarg--top">
        <div class="background-image-holder">
            <img alt="background" src="img/bg/slate-1080.svg">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h1 class="h1--large">Work With Us</h1>
                    <p class="font-md">
                      Use your extra food to make a difference. Sign up to become a Provider in your community.
                    </p>
                </div>
            </div>
            <!--end of row-->
        </div>
    </section>
    <section class="imagebg image--light explanation-step">
      <div class="background-image-holder">
          <img alt="background" src="img/bg/truce-diag-900.svg">
      </div>
      <div class="container-fluid">
        <div class="row explanation-step__details">
          <div class="col-xs-4 col-xs-offset-4 col-sm-offset-0">
              <img src="img/svg/application_submitted.svg" class="animated explanation-step__img" alt="">
          </div>
          <div class="col-xs-12 col-sm-7 container">
              <h1 class="animated text-center-xs">Applying is Easy</h1>
              <div class="row">
                <div class="col-sm-6 col-md-4">
                    <div class="feature feature-6">
                        <i class="icon icon--md icon-Pen-2 color--primary-2"></i>
                        <h4 class="font-md">Sign up</h4>
                        <p class="font-md">
                            Sign up below and fill out a short application.
                        </p>
                    </div>
                    <!--end feature-->
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="feature feature-6">
                    <i class="icon icon--md icon-Search-People color--primary-2"></i>
                    <h4 class="font-md">Wait For Review</h4>
                    <p class="font-md">
                        We will review the application and help you get setup.
                    </p>
                  </div>
                    <!--end feature-->
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="feature feature-6">
                    <i class="icon icon--md icon-Shop-2  color--primary-2"></i>
                    <h4 class="font-md">Provide Food</h4>
                    <p class="font-md">
                        You can start offering free food through our app and website.
                    </p>
                  </div>
                    <!--end feature-->
                </div>
              </div>

              <div>
                <router-link class="router-link flex__justify-end" :to="routes.signin.path">
                  <span>Sign Up</span><i class="icon icon-Arrow-Right"></i>
                </router-link>
              </div>
            </div>
        </div>
        <!--end of row-->
      </div>
    </section>
  </div>


</template>

<script>
import routes from '../vue-router/routes.js';
export default {
  name: "work-with-us",
  components: {

   },
  props: [  ],
  firebase: {},
  data(){
    return {
      routes
    }
  },
  methods: {},
}
</script>

<style lang="css">
</style>
