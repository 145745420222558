<!-- Displays provider-food-lists for an array of providers -->
<template lang="html">
  <div class="food-list">

    <search-filter :filters="categories" :selected-filter="searchInfo.filter" @filtered="filterChanged"></search-filter>
    <provider-food v-for="provider in providers" :provider="provider" :editable="entriesEditable" :entry-filter="adjustedFilter" :id="`provider-section-${provider.id}`"></provider-food>
    <transition name="fade-down">
      <no-results v-if="allFilteredEntries && allFilteredEntries.length < 1"></no-results>
    </transition>
  </div>
</template>

<script>
import FireUtils from '../modules/fireutils.js';

import ProviderFoodList from './provider-food-list.vue';
import SearchFilter from './search-filter.vue';
import NoResults from './no-results.vue';
export default {
  name: 'food-list',
  components: {
    'provider-food' : ProviderFoodList,
    SearchFilter,
    NoResults
  },
  props: [
    'providers', // An array of providers (passed along from find.js)
    'categories', // The categories to display in the search filter
    'entryFilter',
    'providerMode'
   ],
  firebase: {},
  data() { return {
    // Info linked to the search-filter component
    searchInfo : { filter : "", searchText: ""}
  } },
  computed: {

    // Function to be passed to child components for filtering their entries. Since we don't actually know what entries we will be displaying, and the provider-food-list does not know the properties of the entries it displays, this function will need to be bound all the way down to the food-list-entry. This is a computed property, so the filter function being used by child components will update whenever the searchInfo or providers update
    adjustedFilter(){
      let si = this.searchInfo;
      let providers = this.providers;
      let parentFilter = this.entryFilter;
      return function(entry){

        // For both the category filter and the search text, the RegExp will register as a match if the expression is empty. So we don't have to check whether either value is an empty string

        // If there is no entry loaded, don't let it display
        if(!entry || !entry.category || !entry.name || !providers) { return false; }

        // If this entry does not pass the parent component's filter (find.vue or provide.vue), do not try to include it here
        if (!parentFilter(entry)) {
          return false;
        }
        // regular expression for checking against the category filter with a case-insensitive search
        let freg = new RegExp(si.filter, 'gi');
        let catMatch = entry.category.match(freg);

        // regular expression for checking against the search text
        let sreg = new RegExp(si.searchText, 'gi');
        // Check the entry name against the search text
        let nameMatch = entry.name.match(sreg);

        // Find the entry's provider
        let provider = providers.filter((p) => p['.key'] == entry.providerId)[0];
        // Initialize a default value for the provider match in case the provider cannot be found
        let provMatch = false;
        // Check the provider's name against the search text
        if (provider) { provMatch = provider.name.match(sreg); }

        // Include the entry if the entry's category matches the selected filter and the name or provider's name matches the search filter.
        return catMatch && (nameMatch || provMatch);
      }
    },
    allFilteredEntries(){
      let entries = Object.values(FireUtils.values.foodEntries);
      let filtered = entries.filter(this.adjustedFilter);
      console.log(`${entries.length} total entries, ${filtered.length} filtered`);
      return filtered;
    },
    entriesEditable(){
      return this.providerMode && !FireUtils.values.userIsAnonymous;
    }
   },
  methods: {
    filterChanged(f){
      this.searchInfo = f;
    }
  }
}
</script>

<style lang="css" scoped>

/* duplicate transition css in provider-orders.vue */
.fade-down-enter-active {
  transition:  0.5s;
}
.fade-down-enter, .fade-down-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

</style>
