<template lang="html">
    <div class="v-video video-cover border--round">
      <div class="background-image-holder">
          <img alt="image" :src="coverPath">
      </div>
      <div class="video-play-icon"></div>
      <video controls>
        <source :src="`${videoPath}.mp4`" type="video/mp4">
        <source :src="`${videoPath}.ogg`" type="video/ogg">
        Your browser does not support the video tag.
      </video>
    </div>
</template>

<script>
  export default {
      name: "v-video",
      components: {  },
      props: {
        videoPath: {
          type: String,
          required: true
        },
        coverPath: {
          type: String,
          required: true
        }
      }
  }
</script>

<style lang="css" scoped>
</style>
