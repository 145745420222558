<template lang="html">
  <div class="find-view">

          <div class="main-container">

                <cover-header class="cover-header" bg-image-source="./img/stock/peach-apples_narrow70.jpg" overlayAmount="0">
                <!-- <div class="container pos-vertical-center">
                    <div class="row">
                        <div class="col-sm-10 col-md-9">
                            <h1 class="font-intro_rust">Washington DC</h1>
                            <h2 class="font-intro_script">Browse. Reserve. Pickup.</h2>
                            <location-search></location-search>
                        </div>
                    </div>
                </div> -->
                <div class="header-parallax" data-rellax-speed="4">
                  <div class="">
                    <h1 class="font-intro_head">{{ $store.getters.currentLocationName }}</h1>
                    <h2 class="font-intro_script">Browse. Reserve. Pickup.</h2>
                    <location-search></location-search>
                  </div>
                </div>
              </cover-header>

            <section class="side-by-side">
              <div ref="mapColumn"  class="map-wrapper">
                <providers-map
                :providers="providers"
                :center="location"
                @center-changed="mapCenterChanged"
                @selectProvider="scrollToProvider"></providers-map>
              </div>
              <food-list :providers="nearbyProviders" :categories="categories" :entry-filter="entryFilter"></food-list>
            </section>
        </div>
        <notification name="item-added">This item has been added to your bag.</notification>
  </div>
</template>

<script>
import Fireapp from '../modules/fireapp.js';
import Utils from '../modules/utilities.js'
import GeoPoint from "geopoint";

import CoverHeader from './cover-header.vue';
import FoodList from './food-list.vue';
import ProvidersMap from './providers-map.vue';
import LocationSearch from './location-search.vue';
import UserBag from './user-bag.vue'

import Rellax from 'rellax';

  export default {
      name: "find-view",
      components: {
        CoverHeader,
        FoodList,
        ProvidersMap,
        LocationSearch,
        UserBag
      },
      props: [ 'showBag', 'navigationOverlay', 'collapseFooter' ],
      firebase: {
        providers: Fireapp.database().ref(`providers`),
        categories: Fireapp.database().ref('categories')
      },
      data(){
        return {
          mapSticking : false
        }
      },
      computed: {
        // data dependant on the store, has to be computed
        currentUserId(){ return this.$store.state.currentUserId; },
        location(){ return this.$store.state.currentLocation; },
        nearbyProviders(){
          return this.providers.filter((p) => {
            let pLocation = p.location;
            if (!pLocation) {
                return false;
            }
            // Current search location GeoPoint
            let cPoint = new GeoPoint(this.location.lat, this.location.lng);
            // Provider location GeoPoint
            let pPoint = new GeoPoint(pLocation.latitude, pLocation.longitude);
            // Distance in Kilometers from Current location to Provider
            let distance = pPoint.distanceTo(cPoint, true);
            return distance < 15.0;
          });
        },
        entryFilter() {
          return (entry) => {
            if (!entry) { return false; }
            let availibilityCount = this.$store.getters.availibilityCount(entry);
            let expired = entry.expirationDate < Date.now();
            if (availibilityCount <= 0 || expired) { return false; }
            return true;
          };
      } // Include entry if it is available and isn't expired
      },
      methods: {
        positionMap(breakpoint) {
          let $ = this.$jQuery;
          let map = $(this.$refs.mapColumn);

            if (breakpoint !== 'xs') {
                if (!this.mapSticking) {
                  map.sticky({
                    topSpacing:0,
                    widthFromWrapper: false,
                    responsiveWidth: true,
                    zIndex:600
                  });
                  this.mapSticking = true;
                }
            } else {
              // map.unstick();
              this.mapSticking = false;
            }
        },
        scrollToProvider(provider) {
          if (provider && provider.foodEntryIds) {
            let element = `#provider-section-${provider.id}`;
            this.$scrollTo(element);
          }
        },
        mapCenterChanged(newCenter){
          let location = {
            lat : newCenter.lat(),
            lng : newCenter.lng()
          }
          this.$store.commit('setCurrentLocation', location);
        }
      },
      mounted() {
        window.addEventListener("resize", () => {
          // Listen for changes to the window size and adjust the map positioning when needed
          console.log('find.vue -- window resized');
          this.positionMap(Utils.viewportBreakpoint())
          this.$emit('update:collapseFooter', window.innerWidth > 922);
        });
        this.$emit('update:collapseFooter', window.innerWidth > 922);
        this.positionMap(Utils.viewportBreakpoint());

        let parallax = Rellax('.rellax');
      },
      beforeRouteEnter (to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!

        // calling next, allows the routing to proceed
        next(vm => {
          // access to component instance via `vm`
          vm.$emit('update:showBag', true);
          vm.$emit('update:navigationOverlay', 'overlayDark');
        });
      },
      beforeRouteLeave (to, from, next) {
        // called when the route that renders this component is about to
        // be navigated away from.
        // has access to `this` component instance.
        this.$emit('update:showBag', false);
        this.$emit('update:collapseFooter', false);

        // calling next, allows the routing to proceed
        next();
      }
  }
</script>

<style lang="css">
.find-view .cover-header .cover-image {
    background-position-x: -200px;
}
@media all and (min-width: 1320px) {
  .find-view .cover-header .cover-image {
      background-position-x: 0px;
  }
}
</style>

<style lang="css" scoped>
.cover-header {
  position: relative;
  height: 200px !important;
}
.header-parallax {
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  padding: 10px;
  padding-right: 30px;
  padding-left: 50px;

  background:  linear-gradient(110deg, transparent 15.5%, white 15.5%), linear-gradient(110deg, transparent 15%, #FF8E5A 15%);
  text-align: right;
}

.header-parallax > div {
  width: 100%;
}

.header-parallax h1.font-intro_head {
  color: #a1b20c;
  margin-bottom: 0;
}

.header-parallax h2.font-intro_script {
  color: #9DBEB9;
}

.side-by-side > * {
  flex: 1 1 auto;
}


.food-list {
  padding: 0 20px;
  max-width: 35em;
}

.map-wrapper {
  height: 170px;
  order: 1;
  flex: 1 1 auto;
}

@media all and (min-width: 530px) {
  .food-list {
    margin: 0 auto;
  }

  .map-wrapper {
    height: 230px;
  }
}

@media all and (max-width: 922px) {

  .header-parallax h1.font-intro_head {
    font-size: 1.6em;
  }

  .header-parallax h2.font-intro_script {
    font-size: 1.3em;
  }
}

@media all and (min-width: 922px) {

  .cover-header {
    position: relative;
    height: 300px !important;
  }
  .header-parallax {
    padding: 30px;
    padding-right: 150px;
    padding-left: 150px;
    width: 700px;
  }

  .map-wrapper {
    height: 100vh;
    order: 1;
    flex: 1 1 auto !important;
  }

  .side-by-side {
    display: flex;
    align-items: flex-start;
    align-content: stretch;
  }
}

@media all and (min-width: 1320px) {
  .header-parallax {
    position: absolute;
    top: 60px;

    /* Duplicate of 922px rule for clarity*/
    padding: 30px;
    padding-right: 150px;
    padding-left: 150px;
    width: 700px;

    background:  linear-gradient(110deg, transparent 15.5%, white 15.5%), linear-gradient(110deg, transparent 15%, #FF8E5A 15%);
  }
  .header-parallax > div {
    padding: 20px 0;
  }
}

</style>
