<template lang="html">
  <div class="user-testimonials">
    <section>

        <div class="container">
            <div class="row text-center">
                <h2>It Really Works</h2>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3">
                    <div class="testimonial testimonial-2">
                        <div class="testimonial__body boxed boxed--border bg--secondary">
                            <p>
                                Some people are wondering if this is for real. Here's my dinner saying it is!
                            </p>
                        </div>
                        <div class="testimonial__image">
                            <img alt="Image" class="img-circle" src="img/person.png">
                            <h5>Reggie</h5>
                            <span>User</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="testimonial testimonial-2">
                        <div class="testimonial__body boxed boxed--border bg--secondary">
                            <p>
                                Now I can get things we need from the store up the street instead of going after my shift across town to the food bank.
                            </p>
                        </div>
                        <div class="testimonial__image">
                            <img alt="Image" class="img-circle" src="img/person.png">
                            <h5>Jane S.</h5>
                            <span>User</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="testimonial testimonial-2">
                        <div class="testimonial__body boxed boxed--border bg--secondary">
                            <p>
                                I love being able to actually contribute something to people and try to make a little difference. They look at you and say thanks and that's all the motivation i need.
                            </p>
                        </div>
                        <div class="testimonial__image">
                            <img alt="Image" class="img-circle" src="img/person.png">
                            <h5>Sam</h5>
                            <span>Partner</span>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-md-3">
                    <div class="testimonial testimonial-2">
                        <div class="testimonial__body boxed boxed--border bg--secondary">
                            <p>
                                This was the help my family needed. The little things go a long way.
                            </p>
                        </div>
                        <div class="testimonial__image">
                            <img alt="Image" class="img-circle" src="img/person.png">
                            <h5>Devon</h5>
                            <span>User</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
  export default {
      name: "user-testimonials",
      components: {  },
      props: [  ],
      firebase: {},
      data(){ return {  } },
      computed: {},
      methods: {},
  }
</script>

<style lang="css" scoped>
</style>
