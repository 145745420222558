<template lang="html">
  <div class="search-filter">
    <i class="custom icomoon-search">
    </i>
      <input type="text" name="search" placeholder="Search For Food" class="underlined" v-model="searchText">

      <div class="options-dropdown"
           @click="categoryDropdownActive = !categoryDropdownActive">

        <span class="options-dropdown__btn">{{ selectedFilterDisplayName }}</span>

        <transition name="dropdown">
        <ul v-show="categoryDropdownActive">
          <li :class="{'active' : (selectedFilter == '')}" data-masonry-filter="*" @click="selectFilter('')">All</li>

          <li v-for="filter in filters"
              @click="selectFilter(filter.id)"
              :class="{'active' : (selectedFilter == filter.id)}">

            <img :src="filter.imageURL" class="category-image image--xxs unmarg">
            <span>{{  filter.id.capitalize() }}</span>
          </li>
        </ul>
        </transition>

      </div>

  </div>
</template>

<script>
import utilities from '../modules/utilities.js';

  export default {
      name: "search-filter",
      components: {  },
      props: [ 'filters', 'selectedFilter' ],
      data(){ return {
        searchText : "",
        categoryDropdownActive : false
      } },
      computed: {
        filter(){
          return { filter: this.selectedFilter, searchText: this.searchText}
        },
        selectedFilterDisplayName(){
          let f = this.selectedFilter;
          if (f == ''){
            f = 'Filter';
          }

          return f.capitalize();
        }
      },
      watch: {
        searchText(){
          this.$emit('filtered', this.filter);
        }
      },
      methods: {
        selectFilter(f){
          let current = this.filter;
          current.filter = f;
          // Emit a clone so that the interface will re-render because the object changed.
          this.$emit('filtered', {filter: current.filter, searchText: current.searchText});
        }
      },
  }
</script>

<style lang="css" scoped>

@media all and (max-width: 767px) {
  .search-filter {
    padding: 16px;
  }
}

.search-filter {
  display: flex;
  padding-top: 24px;
}

.search-filter > i {
  position: relative;
  left: 0;
  line-height: 2em;
  font-size: 1.2em;
  color: #ff8e5a;
}

.search-filter > input {
  flex-grow: 1;
  margin-left: -30px;
  padding-left: 50px;
  padding-right: 150px;
  background: transparent;
  color: #292a35;
  font-family: Dosis, Helvetica, sans-serif;
}

.search-filter > input::placeholder {
    color: #595b70 !important;
    font-family: Dosis, Helvetica, sans-serif;
}

.options-dropdown {
  position: relative;
  cursor: pointer;
  width: 150px;
  margin-left: -150px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.options-dropdown > span {
  color: #ff8e5a;
  transition: 0.3s;

  min-width: 50px;
  padding: 5px 10px;
  border-radius: 5px;

  font-size: 1.2em;
  font-weight: 600;
  font-family: Dosis, Helvetica, sans-serif;
  text-align: center;
}

.options-dropdown > ul {
  z-index: 500;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 10px;

  padding: 7px 0;
  border-radius: 5px;
  background: #fafafa;
  border-top: 2px solid #FF8E5A;

  box-shadow: 0px 4px 10px rgba(0,25,35,0.15);

}

.options-dropdown > ul li {
  padding: 7px 15px;
}
.options-dropdown > ul li > span{
  padding: 10px;
}

.options-dropdown > ul li:hover,
.options-dropdown > ul li:active {
  background: #D9FFFA
}


.options-dropdown ul::before {
  position: absolute;
  border-bottom: 10px solid #FF8E5A;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin: 0;
  width: 0px;
  height: 10px;
  top: -10px;
  right: 10px;
  margin-left: -10px;
  content: '';
}

.dropdown-enter-active {
  transition: 0.5s;
}

.dropdown-leave-active {
  transition: 0.3s;
}
.dropdown-enter, .dropdown-leave-to{
  opacity: 0;
  transform: translateY(-10px);
}
</style>
