// 1. Define route components.
// These can be imported from other files
import HomeView from '../components/home.vue';
import ProvideView from '../components/provide.vue';
import FindView from '../components/find.vue';
import AboutView from '../components/about.vue';
import HelpView from '../components/help.vue';
import MobileAppView from '../components/mobileapp.vue';
import InfoView from '../components/info.vue';
import PrivacyView from '../components/privacy.vue';
import LocationsView from '../components/locations.vue';
import TermsView from '../components/terms.vue';
import FourOhFour from '../components/page-not-found.vue';
import SignIn from '../components/sign-in.vue';
import ApplyProvider from '../components/apply-provider.vue';
import Admin from '../components/admin/admin.vue';
import HowItWorks from '../components/how-it-works.vue';
import SiteMotivation from '../components/site-motivation.vue';
import WorkWithUs from '../components/work-with-us.vue';
import ContactUs from '../components/contact-us.vue';
import ContactReceived from '../components/contact-received.vue';

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// Vue.extend(), or just a component options object.
// We'll talk about nested routes later.
const routesMap = {
  home : { path: '/', component: HomeView, name: 'home' },
  provide : { path: '/provide', component: ProvideView, name:'provide', meta: {  requires : { authentication: true, provider: true} } },
  find : { path: '/find', component: FindView, name:'find'  },
  about : { path: '/about', component: AboutView, name: 'about' },
  help : { path: '/help', component: HelpView, name: 'help' },
  mobileapp : { path: '/mobileapp', component: MobileAppView, name: 'mobileapp' },
  info : { path: '/info', component: InfoView, name: 'info' },
  privacy : { path: '/privacy', component: PrivacyView, name: 'privacy' },
  locations : { path: '/locations', component: LocationsView, name: 'locations' },
  terms : { path: '/terms', component: TermsView, name: 'terms' },
  signin : { path: '/sign-in', component: SignIn, name: 'sign-in' },
  apply : { path: '/apply', component: ApplyProvider, name: 'apply', meta: {  requires : { authentication: true} } },
  fourofour : {path: '*', component: FourOhFour, name: '404'},

  admin : { path: '/admin', component: Admin, name: 'admin', meta: {  requires : { authentication: true, admin: true} } },
  howitworks : { path: '/how-it-works', component: HowItWorks, name: 'how-it-works' },
  sitemotivation : { path: '/site-motivation', component: SiteMotivation, name: 'site-motivation' },
  workwithus : { path: '/work-with-us', component: WorkWithUs, name: 'work-with-us'},
  contactus : { path: '/contact-us', component: ContactUs, name: 'contact-us'},
  contactreceived : { path: '/contact-received', component: ContactReceived, name: 'contact-received'}
};



export const routes = Object.values(routesMap);

export default routesMap;
