<template lang="html">
  <div class="home-view">
        <div class="main-container">

          <section class="home-view__section who">
            <div class="section__content">
              <div class="hero-message">
                <h1>Find My Meal</h1>
                <h2>Connecting people with extra to those who need more.</h2>
                <router-link class="btn btn--primary" :to="routes.find.path">
                  <span class="btn__text">Get Started</span>
                </router-link>
              </div>

              <div class="summary-video">
                <v-video video-path="img/freefood" coverPath="img/freefood-still.png"></v-video>
              </div>
            </div>
          </section>

          <section class="home-view__section how bg--green-light">
            <div class="section__content">
              <h2 class="color--orange">Simple to Use</h2>


            <div class="how__steps">

              <labeled-image
                imagePath="img/svg/browse.svg" imageLabel="Browse"
                :classes="['how__steps__step']" :imageClasses="[]" :labelClasses="['text-center', 'font-intro_script', 'font-lg', 'color--gray', 'pad-bottom--20']"
              ></labeled-image>

              <labeled-image
                imagePath="img/svg/reserve.svg" imageLabel="Reserve"
                :classes="['how__steps__step']" :imageClasses="[]" :labelClasses="['text-center', 'font-intro_script', 'font-lg', 'color--gray', 'pad-bottom--20']"
              ></labeled-image>

              <labeled-image
                imagePath="img/svg/pickup.svg" imageLabel="Pickup"
                :classes="['how__steps__step']" :imageClasses="[]" :labelClasses="['text-center', 'font-intro_script', 'font-lg', 'color--gray', 'pad-bottom--20']"
              ></labeled-image>

            </div>

            <router-link class="router-link flex__justify-end" :to="routes.howitworks.path">
              <span>Read More</span><i class="icon icon-Arrow-Right"></i>
            </router-link>
            </div>
          </section>

          <section class="home-view__section why bg--gray">
            <div class="section__content">
              <h2 class="color--white">Why For Free?</h2>
            <div class="h-centered-content">

            <div class="why-stats why-stat-group">
              <div class="why-stat__value color--aqua hidden--mobile font-dosis">
                40
              </div>
              <div class="why-stat-group flex__column">
                <div class="why-stat__description color--aqua font-dosis">
                  <span class="font-lg color--aqua color--aqua font-intro_head"><span class="visible--mobile">40</span> million</span><br> people across the country face hunger, including 15 million children and 7 million seniors.
                </div>
                <div class="why-stat__description color--aqua font-dosis">
                  <span class="font-lg color--aqua font-intro_head"><span class="visible--mobile">40</span> percent</span><br> of food in America ends up in the landfill, as much as 70 billion pounds of food a year.
                </div>
              </div>

            </div>
          </div>

            <router-link class="router-link flex__justify-end" :to="routes.sitemotivation.path">
              <span>Read More</span><i class="icon icon-Arrow-Right"></i>
            </router-link>
          </div>
          </section>


          <featured-providers></featured-providers>
          <user-testimonials></user-testimonials>

        </div>
  </div>
</template>

<script>
  import routes from '../vue-router/routes.js';

  import UserTestimonials from './user-testimonials.vue'
  import FeaturedProviders from './featured-providers.vue'
  import VVideo from './generic/v-video.vue'
  import LabeledImage from './generic/labeled-image.vue'

  export default {
      name: "home-view",
      components: {
        UserTestimonials,
        FeaturedProviders,
        VVideo,
        LabeledImage
       },
      props: [  ],
      firebase: {},
      data(){
        return {
          routes
        }
      },
      methods: {},
  }
</script>

<style lang="css">

.how__steps{
  display: flex;
  align-items: flex-start;
  align-content: stretch;
  justify-content: space-around;
  width: 100%;
}

.how__steps__step {
  max-width: 300px;
  margin: 10px;
  flex: 1 1 300px;

  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}

.home-view section > .section__content{
  padding: 20px;
  max-width: 70em;
  margin: auto;
}

.home-view section h2 {
  text-align: center;
  font-size: 2em;
}
.home-view .hero-message h1 {
  font-size: 3em;
  margin-bottom: -.2em;
  color: #75dbdf;
}
.home-view section .hero-message h2 {
  color: #b7b7b7;
  text-align: left;
}

.home-view section.how {
  background: #dce770;
}

.home-view section.who > .section__content{
  display: flex;
  flex-direction: column;
}

.home-view section.who .summary-video {
  width: 100%;
}
.home-view section {
    padding: 1.5em 0px;
}

.home-view__section.why.bg--gray {
  background: url(img/svg/usa-map.svg) #595b70 no-repeat;
  background-position: center center;
}

.why-stats {
  align-items: center;
  margin-bottom: 5em;
}

.why-stat-group {
  display: flex;
}
.why-stat__value {
  font-size: 200px;
  line-height: 200px;
  margin-right: 20px;
}
.flex__column {
  flex-direction: column;
}
.why-stat__description {
  font-size: 1.5em;
  margin-top: 1.3em;
}
.why-stat__description:first-of-type{
  margin-top: 0px;
}
.why-stat__description .font-lg {
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;
}

.visible--mobile {
  display: unset;
}

.hidden--mobile {
  display: none;
}

@media all and (min-width: 768px) {

  .home-view section {
      padding: 3em 0px;
  }
  .home-view section.who {
      padding: 7em 0px;
  }

  .home-view section > .section__content{
    padding: 20px 50px;
  }
  .home-view section h2 {
    text-align: left;
    font-size: 3.5em
  }
  .home-view .hero-message h1 {
    font-size: 4em;
  }
  .home-view .hero-message h2 {
    font-size: 2em;
  }
  .home-view section.who > .section__content{
    flex-direction: row;
  }

  .home-view section.who .summary-video {
    width: 500px;
    min-height: 300px;
  }
  .home-view .how__steps__step {
    margin: 20px;
  }
  .why-stat__value {
    font-size: 300px;
    line-height: 300px;
  }
  .why-stat__description {
    max-width: 300px;
  }
  .hidden--mobile {
    display: unset;
  }
  .visible--mobile {
    display: none;
  }
}

</style>
