<template lang="html">
  <div class="provider-orders">

    <div class="row">
        <div class="col-xs-12">
            <div><h3 class=" pull-left hint--right hint--rounded hint--medium" aria-label="When people reserve food you post, it shows here">Pending Orders</h3>
            </div>
            <div>
              <h3 class="badge pull-right pending-orders-number" >{{ orders.length }}</h3>
            </div>
            <form>
              <input id="checkout-search" v-model="searchText" type="text" name="checkout-search" placeholder="Find Order" autocomplete="off" spellcheck="false">
            </form>
        </div>
    </div>
    <hr/>

    <div class="row">
      <p v-show="orders && orders.length < 1" class="no-orders-label font-lg space--lg col-xs-12 color--primary-1"><span class="hint--right hint--medium hint--rounded" aria-label="When users reserve your items, their orders will be listed here until they pick up their items">No Pending Orders</span><p>
        <div id="orders-list" class="col-xs-12">
          <transition-group tag="div" name="list">
            <user-order v-for="order in allFilteredOrders" :key="order.id" :orderId="order['id']" :displayedForProvider="true"></user-order>
          </transition-group>
        </div>
    </div>

    <transition name="fade-down">
      <no-results v-if="allFilteredOrders && allFilteredOrders.length < 1"></no-results>
    </transition>
  </div>
</template>

<script>
import Fireapp from '../modules/fireapp.js';
import FireUtils from '../modules/fireutils.js'
import UserOrder from './user-order.vue';
import NoResults from './no-results.vue';

  export default {
      name: "provider-orders",
      components: {
        UserOrder,
        NoResults
     },
     data () {
       return {
         searchText : ""
       }
      },
      firebase() {
        return {
          allOrders : Fireapp.database().ref(`orders`)
        }
      },
      computed: {
        orders(){
          let currentProvider = this.$store.getters.currentProvider;
          if (!currentProvider) {
            return [];
          }
          return this.allOrders.filter((order) => {
            let totalReservedInOrder = this.$store.getters.reservedItemTotal(order);
            return totalReservedInOrder > 0 && order.provider === currentProvider.id;
          });
        },
        orderFilter(){
          let searchText = this.searchText;
          return function(order){

            // For the search text, the RegExp will register as a match if the expression is empty. So we don't have to check whether either value is an empty string

            // If there is no order loaded, don't let it display
            if(!order || !order.id) { return false; }

            // regular expression for checking id against the search text
            let sreg = new RegExp(searchText, 'gi');
            // Check the entry name against the search text
            let idMatch = order.id.match(sreg);

            // regular expression for checking reservedItem names against the search text

            var entryNames = [];
            var nameMatch;
            for (let entryId in order.reservedItems) {
              let entryName = FireUtils.values.foodEntries[entryId].name;
              entryNames.push(entryName);
              if (entryName.match(sreg)) {
                // at least one entry name matches the searchText, so this order is a match
                nameMatch = true;
              }
            }

            return idMatch || nameMatch;

          }
        },
        allFilteredOrders(){
          let filtered = this.orders.filter(this.orderFilter);
          console.log(`${this.orders.length} total orders, ${filtered.length} filtered`);
          console.log(filtered);
          return filtered;
        }
      }
  }
</script>

<style lang="css" scoped>

/* duplicate transition css in food-list.vue */
.fade-down-enter-active {
  transition:  0.5s;
}
.fade-down-enter, .fade-down-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

</style>
