<template lang="html">
  <div class="about-view">
    <section class="imagebg text-center unmarg--top">
        <div class="background-image-holder">
            <img alt="background" src="img/bg/slate-1080.svg">
        </div>
        <h1 class="h1--large">About</h1>
    </section>
    <div class="section__content">
      <p class="font-lg">We are connecting families facing hunger with businesses that want to donate surplus food to their community.</p>

      <p>Free Food for Families is a non-profit organization conceived by a Harvard instructor to address food insecurity and food waste. The multi-platform app will premiere in Washington D.C. <router-link :to="$siteRoutes.contactus.path">Contact us</router-link> if you are interested in working together to bring Free Food to your city.</p>

      <div class="info-links">
        <router-link class="router-link flex__justify-end" :to="$siteRoutes.howitworks.path">
          <span>How It Works</span><i class="icon icon-Arrow-Right"></i>
        </router-link>
        <router-link class="router-link flex__justify-end" :to="$siteRoutes.sitemotivation.path">
          <span>Why</span><i class="icon icon-Arrow-Right"></i>
        </router-link>
      </div>

    </div>

  </div>
</template>

<script>

export default {
    name: "about",
    components: {},
    props: [  ],
    firebase: {},
    data(){
      return {}
    },
    computed: {},
    methods: {}
}
</script>

<style lang="css">
.about-view .section__content{
  padding: 20px;
  max-width: 50em;
  margin: auto;
}

.section__content p{
  font-size: 1.25em;
}
.section__content p.font-lg {
  margin-top: 1em;
  font-size: 2em;
  line-height: 1.5em;
}
.info-links {
  display: flex;
}
.info-links a {
  flex: 1 1 auto;;
  justify-content: center;
}
</style>
