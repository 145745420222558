/**
 * This module contains Firebase specific configuration and database manipulation as well as some database computation and utility functions.
 * @module modules/fireapp.js
 */

import Firebase from 'firebase';

// Typical configuration required by firebase
//

// CHANGE_FOR_PRODUCTION!!!!!
const config = {
  // Development config
  // apiKey: 'AIzaSyA8X-MEAqalfm5KZAY_I-EOaEzj1-S12ro',
  // authDomain: 'dev-freefood.firebaseapp.com',
  // databaseURL: 'https://dev-freefood.firebaseio.com',
  // storageBucket: 'dev-freefood.appspot.com',
  // messagingSenderId: '934979456998'

  // Production config
  apiKey: 'AIzaSyCjvOWJoH8g2LtNsDhaIMTgrO3ld_r1jKg',
  authDomain: 'freefood-d6a78.firebaseapp.com',
  databaseURL: 'https://freefood-d6a78.firebaseio.com',
  projectId: 'freefood-d6a78',
  storageBucket: 'freefood-d6a78.appspot.com',
  messagingSenderId: '803771924893'
};

/**
 * Firebase configuration to be imported into any other js module that needs to access Firebase. This allows the same app instance to be shared across modules.
 *
 * import Fireapp from '../modules/fireapp.js';
 *
 */
const fa = Firebase.initializeApp(config);

// Concept found in this issue answer https://github.com/vuejs/vuefire/issues/21#issuecomment-294048508

export default fa;
