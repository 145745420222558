<template lang="html">
  <div class="labeled-image" :class="classes">
    <img :src="imagePath" :alt="`${imageLabel} image`" :class="imageClasses">
    <div :class="labelClasses">{{imageLabel}}</div>
  </div>
</template>

<script>
  export default {
      name: "labeled-image",
      components: {  },
      props: {
        imagePath : {
          type: String,
          required: true
        },
        imageLabel : {
          type: String,
          default: ''
        },
        imageClasses: {
          type: [String, Array],
          default: []
        },
        labelClasses:{
          type: [String, Array],
          default: []
        },
        classes:{
          type: [String, Array],
          default: []
        }
      },
      firebase: {},
      data(){ return {  } },
      computed: {},
      methods: {},
  }
</script>

<style lang="css" scoped>
</style>
