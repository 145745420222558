<template lang="html">
    <modal
      name="error-alert"
      :reset="true"
      :classes="modalClasses"
      transition="scale"
      :adaptive="true"
      @before-open="beforeOpen">
      <div class="modal--close-button" @click="$modal.hide('error-alert')">
        <i class="custom icomoon-cross">
        </i>
      </div>
      <div class="v-centered-content">
        <div>
          <div class="modal--header">
            <h4 class="unmarg">{{ modalInfo.title }}</h4>
          </div>
          <div class="modal--content">
            <p>{{ modalInfo.message }}</p>
          </div>
        </div>
      </div>
    </modal>
</template>

<script>
import Utils from '../../modules/utilities'

  const defaultModalInfo = {
    title: 'Unknown Error',
    message: '',
    type: 'error'
  };

  export default {
      name: "alert-modal",
      components: {  },
      props: [  ],
      firebase: {},
      data(){ return {
        modalInfo: defaultModalInfo,
        defaultModalInfo
       } },
      computed: {
        modalClasses(){
          return ['v--modal', `${this.modalInfo.type}-modal`, 'alert-modal'];
        }
      },
      methods: {
        beforeOpen (event) {
          let mi = event.params;
          Utils.normalize(mi, this.defaultModalInfo)
          this.modalInfo = mi;
        }
      },
  }
</script>

<style lang="css" scoped>
.v--modal {
  position: relative;
}
</style>

<style lang="css">
.error-modal {
  border-top: 3px solid red;
}

.alert-modal {
  max-width: 400px;
  width: 90%;
  background: white;
  padding: 10px 20px;
}

.warning-modal {
  border-top: 3px solid #ffd000;
}

.success-modal {
  border-top: 3px solid #bdce24;
}


.modal--close-button {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  text-align: right;
  padding-right: 5px;
}

.modal--header, .modal--content {
}

.modal--header h4 {
  line-height: 1em;
}


  .scale-enter-active,
  .scale-leave-active {
    transition: all 0.3s;
  }
  .scale-enter,
  .scale-leave-active {
    opacity: 0;
    transform: scale(0.3) translateY(24px);
  }

</style>
