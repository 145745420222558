// Orders Vuex Store Module.
import Firebase from 'firebase';
import FireUtils from '../../modules/fireutils.js';
import Utils from '../../modules/utilities.js'

const state = {

}

const mutations = {

}

const actions = {
  applyAsProvider: (context, {provider, submissionInfo, reviewerInfo}) => {

    // Normalize passed data
    provider = FireUtils.normalize.provider(provider);
    submissionInfo = FireUtils.normalize.submissionInfo(submissionInfo);
    // Include reviewerInfo in case this application was previously denied and is being re-submitted
    reviewerInfo = reviewerInfo || {};

    // Reference the highest level db that we will be accessing
    let database = Firebase.database().ref('pendingProviders');

    // Push a new provider key to the providers node. Since we don't pass an object,
    // no value will be set in the providers node
    let newProviderKey = Firebase.database().ref('providers').push().key;
    provider.id = newProviderKey;

    let updates = {}

    let currentUser =  FireUtils.values.currentUser;
    let accountId = currentUser.uid;

    if (!provider.accountId || provider.accountId === accountId){
      // Update the account id and email if the currentUser owns the provider or is creating it.
      // Otherwise, this is an Admin adjusting the info
      provider.accountId = accountId;
      submissionInfo.timestamp = Date.now();
      submissionInfo.email = submissionInfo.email || currentUser.email;
    }

    // Create the pendingRecord
    let awaitingReview = {
      provider, submissionInfo, reviewerInfo
    }

    return new Promise((resolve, reject) => {
      // Store the new pending item in the awaitingReview node
      updates[`awaitingReview/${accountId}`] = awaitingReview;
      // if this application was previously denied, remove it from the denied node
      updates[`denied/${accountId}`] = null;

      // Perform the updates
      database.update(updates, (error) => {
        if (error) { reject(error); }
        else { resolve(); }
      });
    });
  },
  reviewProvider: (context, {provider, submissionInfo}) => {
    provider = FireUtils.normalize.provider(provider);

    let database = Firebase.database().ref('pendingProviders');

    let inReview = {
      provider, submissionInfo
    }

    let updates = {};
    updates[`awaitingReview/${provider.accountId}`] = null;
    updates[`inReview/${provider.accountId}`] = inReview;

    return new Promise((resolve, reject) => {
      database.update(updates, error => {
        if (error) { reject(error); }
        else { resolve(); }
      });
    });
  },
  denyProvider: (context, {provider, submissionInfo, reviewerInfo}) => {
    provider = FireUtils.normalize.provider(provider);

    let database = Firebase.database().ref('pendingProviders');

    reviewerInfo.timestamp = Date.now();

    let denied = {
      provider, submissionInfo, reviewerInfo
    }

    let updates = {};
    updates[`inReview/${provider.accountId}`] = null;
    updates[`denied/${provider.accountId}`] = denied;

    return new Promise((resolve, reject) => {
      database.update(updates, error => {
        if (error) { reject(error); }
        else { resolve(); }
      });
    });
  },
  approveProvider : (context, {provider, submissionInfo, reviewerInfo}) => {
    FireUtils.normalize.provider(provider);

    // This action touches various top level nodes, so just reference the root of the db
    let database = Firebase.database().ref();

    reviewerInfo.timestamp = Date.now();

    let approvedInfo = { providerId: provider.id, submissionInfo, reviewerInfo };

    let updates = {};
    updates[`pendingProviders/inReview/${provider.accountId}`] = null;
    updates[`pendingProviders/approved/${provider.accountId}`] = approvedInfo;
    updates[`providers/${provider.id}`] = provider;
    updates[`accounts/${provider.accountId}`] = provider.id;
    return new Promise((resolve, reject) => {
      database.update(updates, error => {
        if (error) { reject(error); }
        else { resolve(); }
      });
    });
  },
  cancelProviderApplication : (context, {provider, submissionInfo, reviewerInfo}) => {

    let database = Firebase.database().ref('pendingProviders');
    if (!provider || !provider.accountId){
      return new Promise((resolve, reject) => { reject('Could not find the provider Application') });
    }

    // Delete the pendingProvider from any node where it could exist.
    let updates = {};
    updates[`awaitingReview/${provider.accountId}`] = null;
    updates[`inReview/${provider.accountId}`] = null;
    updates[`denied/${provider.accountId}`] = null;
    updates[`approved/${provider.accountId}`] = null;

    return new Promise((resolve, reject) => {
      database.update(updates, error => {
        if (error) { reject(error); }
        else { resolve(); }
      });
    });
  }
}

const getters = {

}

export default {
  state,
  mutations,
  actions,
  getters
}
