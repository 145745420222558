<template lang="html">
  <transition name="modal">
    <div class="wrapper">

      <div class="modal__content">
        <component :is="modalType" @close="$emit('close')"></component>
      </div>
      <div class="modal-overlay" @click="$emit('close')"></div>

    </div>
  </transition>
</template>

<script>
import UserBag from './user-bag.vue';
import CreateEntry from './create-entry.vue';

export default {
  name: "modal-view",
  components: {
    UserBag,
    CreateEntry
  },
  props: [
    'modalType' // either userBag or createEntry, which then resolve to valid html tags
  ],
  data () {
    return {
    }
  }

}
</script>

<style lang="css">

/* Mobile */
@media screen and (max-width: 50em){
  .modal__content {
    padding: 0.5em;
  }
}

/* All screens larger than mobile */
@media screen and (min-width: 50em){
  .modal__content {
    padding: 2em;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: center;

  position: fixed;
  z-index: 988;
  width: 100vw;
}

.modal-overlay {
  position: fixed;
  z-index: 9995;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
}

.modal__content {
  position: relative;
  box-sizing: border-box;
  z-index: 9999;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin: auto;
  height: 100vh;
  width: 100%;
  max-width: 40em;
  /*padding-top: 75px;*/
  padding-bottom: 3em;
}

/* These really do work from an animation standpoint, to make the modal fade in. Due to issues with z-indexing the net effect is too chopy to use, however */

/*.modal-enter, .modal-leave-to {
  opacity: 0;
}

.modal-enter-active, .modal-leave-active {

  transition: opacity .5s;
}

.modal-leave, .modal-enter-to {
  opacity: 1;
}*/


</style>
