<template lang="html">

<div>
    <div class="navigation-bar" :class="{'navigation-top' : !isMobile, 'navigation-side' : isMobile}">
    <div v-show="isMobile" class="menu-button" :class="{'menu-button--open' : mobileIsOpen }" @click="mobileIsOpen = !mobileIsOpen"><span>Menu Button</span></div>

    <router-link :to="routes.home.path">
      <img class="logo logo-dark" alt="logo" src="img/logo/logo-color.svg">
    </router-link>

    <nav :class="{'active' : mobileIsOpen }" @click="isMobile && false || mobileIsOpen">
      <router-link :to="routes.find.path" data-hover="Browse"><span>Browse</span></router-link>
      <router-link :to="routes.provide.path" data-hover="Provide"><span>Provide</span></router-link>
      <router-link :to="routes.about.path" data-hover="About"><span>About</span></router-link>

      <a v-if="!userIsAuthenticated && showbag && isMobile" data-hover="My Bag" @click="bagModalVisible = !bagModalVisible"><span>My Bag<i class="icon custom icomoon-bag"></i></span></a>
      <a v-if="userIsAuthenticated && isMobile" @click="signOut" data-hover="Sign Out"><span>Sign Out</span></a>
    </nav>

    <nav v-if="!isMobile">
      <a v-if="!userIsAuthenticated && showbag" data-hover="My Bag" @click="bagModalVisible = !bagModalVisible"><span>My Bag<i class="icon custom icomoon-bag"></i></span></a>
      <a v-if="userIsAuthenticated" @click="signOut" data-hover="Sign Out"><span>Sign Out</span></a>
    </nav>
  </div>

  <modal-view class="userBag-modal"  v-if="bagModalVisible" @close="bagModalVisible = false" modalType="userBag"></modal-view>

  <div class="spacer">
    <!-- This element exists to knock bump down the body content the dynamic height of the nav bar -->
  </div>

</div>

</template>

<script>
import FireUtils from '../modules/fireutils.js';
import ModalView from './modal.vue';
import routes from '../vue-router/routes.js';

export default {
  name: 'main-nav',
  components: {
    ModalView
  },
  props: ['showbag', 'overlay'],
  firebase: {},
  data() {
    return {
      bagModalVisible: false,
      routes,

      isMobile: window.innerWidth < 800,
      mobileIsOpen: false
    };
  },
  computed: {
    userIsAuthenticated() {
      return !FireUtils.values.userIsAnonymous;
    },
    provider() {
      return this.$store.getters.currentProvider;
    }
  },
  methods: {
    signOut() {
      console.log('sign out method');
      FireUtils.values.signOut();
      // route user to find page after sign out is complete. this cannot be done by making the sign out button a router-link, because the routing would happen before the FireUtils.values.signOut, instead of after.
      if (
        this.$router &&
        this.$router.currentRoute &&
        this.$router.currentRoute.meta &&
        this.$router.currentRoute.meta.requires &&
        this.$router.currentRoute.meta.requires.authentication
      ) {
        this.$router.push('find');
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768;
    });
  }
};
</script>

<style lang="css" scoped>


  .logo {
    width: 160px;
  }

  .navigation-bar nav {
  	overflow: hidden;
    /*font-family: 'Helvetica';*/
  }

  .navigation-bar nav a:hover,
  .navigation-bar nav a:focus {
  	outline: none;
  }

  .navigation-bar nav a {
    outline: none;
  	text-decoration: none;
  	text-transform: uppercase;
  	letter-spacing: 1px;
  	font-weight: 200;
  	font-size: 1.35em;
    cursor: pointer;
  }

  .navigation-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    border-bottom: 5px solid #75dbcf;
  }
  .navigation-bar, .spacer {
    width: 100%;
    background: white;
    height: 75px;
  }

/* --------------- top fixed navigation bar --------------- */

  .navigation-top {
  	overflow: hidden;
  }
  .navigation-top nav:first-of-type {
    flex-grow: 1;
  }
  .navigation-top nav:last-child {
    flex-direction: row-reverse;
  }

  .navigation-top nav {
    background: white;
    margin: 0;
    display: flex;
    /*padding: 0 30px;*/
  }

  .navigation-top nav a {
  	position: relative;
  	color: #aaa;
    text-align: center;
  }


  .navigation-top nav  {
  	position: relative;
  	z-index: 1;
  }

  .navigation-top nav a span {
  	display: block;
  	padding: 20px 20px;

    -webkit-transition: -webkit-transform 0.3s;
  	-moz-transition: -moz-transform 0.3s;
  	transition: transform 0.3s;
  }

  .navigation-top nav a::before{
  	position: absolute;
  	top: 0;
  	left: 0;
  	z-index: -1;
  	padding: 20px 20px;
  	width: 100%;
  	height: 100%;
  	color:  #75dbcf;
  	/*content: attr(data-hover);*/
    content: '';
  	-webkit-transition: 0.3s;
  	-moz-transition: 0.3s;
  	transition: 0.3s;
  	-webkit-transform: translate(-8%, 100%);
  }


  .navigation-top nav a::before{
    width: 120%;
    margin-left: -5%;
  /*  Draw the Rhombus shape  */
    background-image: linear-gradient(110deg, transparent 20%,  #D9FFFA 20%,  #D9FFFA 80%, transparent 80%);
    /*opacity: 0.4;*/
  }

/* moves existing title out so it can be replaced with ::before content. In this case our ::before content is '' so we don't need to move anything */
  /*.navigation-top nav a:hover span,
  .navigation-top nav a:focus span,
  .navigation-top nav a.active span{
  	-webkit-transform: translate(25%, -100%);
  	-moz-transform: translate(25%, -100%);
  	transform: translate(25%, -100%);

  }*/

  .navigation-top nav a:hover::before,
  .navigation-top nav a:focus::before,
  .navigation-top nav a.active::before {
  	-webkit-transform: translate(0%, 0%);
  	-moz-transform: translate(0%, 0%);
  	transform: translate(0%, 0%);
  }





  /* --------------- side modal navigation bar --------------- */

/*.navigation-side {
}*/

.navigation-side {
  justify-content: center;
}

.navigation-side nav {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  transform : translateX(-100%);
  background : #D9FFFA;
  padding-top: 80px;
  width: 200px;
  height: 100vh;
  transition: 0.3s;
}

.navigation-side nav.active {
  transform : translateX(0);
}

.navigation-side nav a {
  position: relative;
  display: block;
  color: rgba(42, 43, 54, 0.6);
  text-align: left;
  padding : 20px;
  transition: 0.3s ease-out;
}

.navigation-side nav a:hover,
.navigation-side nav a:focus {
  color: #40bbaa;
}

.navigation-side nav a::before,
.navigation-side nav a::before {
  width: 200px;
  height: 100%;
  background-image: linear-gradient(110deg, transparent 0%,  white 0%,  white 85%, transparent 85%);
  z-index: -1;
  position: absolute;
	top: 0;
	left:0;
  content: '';
  transform: translateX(-100%);
  opacity: 0;
  transition: 0.3s ease-out;
}


.navigation-side nav a:hover::before,
.navigation-side nav a:focus::before {
	-webkit-transform: translate(0%, 0%);
	-moz-transform: translate(0%, 0%);
	transform: translate(0%, 0%);
  opacity: 1.0;
}


  /* ---------------------- Menu button ---------------------- */

  .menu-button {
  	position: absolute;
  	z-index: 1000;
  	top: 20px;
  	left: 20px;
  	width: 24px;
  	height: 20px;
  	padding: 0;
  	cursor: pointer;
  	border: none;
  	outline: none;
  	background: transparent;
  }

  .no-js .menu-button {
  	display: none;
  }

  .menu-button::before,
  .menu-button::after,
  .menu-button span {
  	background: #75dbcf;
  }

  .menu-button::before,
  .menu-button::after {
  	content: '';
  	position: absolute;
  	top: 50%;
  	left: 0;
  	width: 100%;
  	height: 2px;
  	pointer-events: none;
  	-webkit-transition: -webkit-transform 0.25s;
  	transition: transform 0.25s;
  	-webkit-transform-origin: 50% 50%;
  	transform-origin: 50% 50%;
  }

  .menu-button span {
  	position: absolute;
  	left: 0;
  	overflow: hidden;
  	width: 100%;
  	height: 2px;
  	text-indent: 200%;
  	-webkit-transition: opacity 0.25s;
  	transition: opacity 0.25s;
  }

  .menu-button::after {
  	-webkit-transform: translate3d(0, 10px, 0) scale3d(0.8, 1, 1);
  	transform: translate3d(0, 10px, 0) scale3d(0.8, 1, 1);
  }

  .menu-button--open span {
  	opacity: 0;
  }

  .menu-button--open::before {
  	-webkit-transform: rotate3d(0, 0, 1, 45deg);
  	transform: rotate3d(0, 0, 1, 45deg);
  }

  .menu-button--open::after {
  	-webkit-transform: rotate3d(0, 0, 1, -45deg);
  	transform: rotate3d(0, 0, 1, -45deg);
  }


  .icomoon-bag {
    font-size: 1em !important;
    margin-left: 4px;
  }
</style>
