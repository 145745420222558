<template lang="html">
  <div class="user-bag">
    <img src="img/svg/Circle_x.svg" class="close-bag-button hide-on-print" @click="$emit('close')"></img>
      <div class="bag-header space-lg">
        <h2 class="font-xlg hide-on-print">My Bag</h2>
        <br class="hide-on-print"/>

        <div v-if="displayOrders" class="font-lg smaller-on-print">
          <p>Below are your orders, one for each Provider you reserved food from. Each order contains pickup instructions and a unique barcode for your food. Take a copy of your barcode(s) with you when you go to pickup your items.</p></div>
        <div v-else class="font-lg smaller-on-print">
          <p>Once you reserve items, your orders will display below. </p></div>
        <br/>
        <transition name="scale">
        <span v-show="quotaReached" class="tag hide-on-print">Quota Reached</span>
        </transition>
        <action-button
        class="action-button--primary print-button hide-on-print"
        @button-click="print"
        :is-loading.sync="preparingPrint"
        :is-confirmed.sync="preparingPrintComplete">
        <i class="custom icomoon-printer"></i> Print
      </action-button>

      </div>

        <transition-group tag="div" :duration="1000" :css="true" name="list" move-class="list-move" class="animated-list orders-container" mode="out-in">
          <user-order
            v-for="orderId in orderIds"
            :key="orderId['.value']"
            :orderId="orderId['.value']">
          </user-order>
        </transition-group>

  </div>

</template>

<script>
import Fireapp from '../modules/fireapp.js';
import FireUtils from '../modules/fireutils.js';
import UserOrder from './user-order.vue';
import moment from 'moment';

  export default {
      name: "user-bag",
      components: {
        UserOrder
       },
      props: [  ],
      firebase(){},
      data(){
        return {
        // initialize an empty orderIds array. This will be populated once the array is bound to firebase
        orderIds : [],
        preparingPrint: false,
        preparingPrintComplete: false
      }
      },
      computed: {
        displayOrders(){
          return (this.orderIds && this.orderIds.length > 0) ? true : false
        },
        quotaReached(){
          let user = FireUtils.values.users[FireUtils.values.currentUserId];
          let currentQuotaCount = user && user.quotaInfo && user.quotaInfo.numberOfItems || 0;
          let currentQuotaDay = user && user.quotaInfo && user.quotaInfo.date
          let reserveLimit = FireUtils.values.devSettings.reserveLimit;
          let quotaRepresentsToday = currentQuotaDay && moment().isSame(user.quotaInfo.date, 'day');

          return (quotaRepresentsToday && currentQuotaCount >= reserveLimit)

        },
        allBarcodesLoaded(){ return this.orderIds && this.barcodesLoaded >= this.orderIds.length}
      },
      methods: {
        bindOrderIds(){
          let id = this.$store.state.currentUserId;
          if (id && id.length > 0) {
            console.log(`bind ${id}`);
            // TODO: Adjust naming of "orderIds" to be clearer. This object in the user node is structured <provider-id> : <order-id>, so having to say oderId['.value'] to get the order id doesn't make much sense.
            this.$bindAsArray('orderIds', Fireapp.database().ref(`users/${id}/orders`));
            this.$forceUpdate();
          }
        },
        cancelOrderId(id){
          this.$store.dispatch('cancel', FireUtils.values.orders[id]);
        },
        print(){
          this.preparingPrint = true;
          setTimeout(() => {
            this.preparingPrintComplete = true;
            window.print();
          }, 2000);
        }
      },
      beforeMount(){ this.bindOrderIds();
      //  },
      // mounted() {
        // Watch the state's currentUserId and rebind the orders when the user changes
        this.$store.watch(
          state => { return state.currentUserId },
          () => { this.bindOrderIds() }
        )
      }
  }
</script>

<style lang="css">
@media print {
  .app > div > div:not(.userBag-modal), footer {
    display: none;
  }
  body * {
    visibility: hidden;
  }

  .user-bag, .user-bag * {
    visibility: visible;
  }
  .modal__content {
    max-width: 100%;
    overflow-y: visible;
  }
  .userBag-modal, .user-bag {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    overflow: visible;
    background: white;
  }
  .order {
    max-width: 250px;
  }

  .orders-container {
    flex-direction: row !important;
  }

  .smaller-on-print {
    font-size: 1.2em;
  }

  .hide-on-print {
    display: none;
  }
}
</style>

<style lang="css" scoped>

.user-bag {
  /* Add top margin so close button is not behind nav bar */
  margin-top: 70px;
}

.close-bag-button {
  width: 3em;
  height: 3em;
  float: right;
  cursor: pointer;
  transition: 0.3s;
}
.close-bag-button:hover {
  transform: scale(1.2);
}
.bag-header {
  text-align: center;
  color: white;
}

.bag-header h2 {
  color: white;
}

/*used to center order-cards in container*/
@media screen and (max-width: 50em){
  .orders-container {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
@media screen and (min-width: 50em){
  .orders-container {
    /*flex-wrap: nowrap;*/
    flex-direction: row;
  }
}
.orders-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  /*justify-content: space-between;*/
}

.orders-container > * {
  transition: all 1s;
  width: 100%;
}

.order-card {
  transition: all 1s;
  flex: 1 0 auto;
}

.tag {
  padding: 5px 10px;
  margin: 0 10px;
  background: #75dbcf;
  border-radius: 3px;
  font-size: 1.1em;
  color: #595b70;
}

.print-button {
  margin: 10px auto;
}
</style>
