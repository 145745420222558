// Entries Vuex Store Module.
import Firebase from 'firebase';
import FireUtils from '../../modules/fireutils.js';
import Utils from '../../modules/utilities.js'
import moment from 'moment';

const state = {

}

const mutations = {

}

const actions = {

  createEntry: (context, entry) => {
    FireUtils.normalize.entry(entry);
    let database = Firebase.database().ref();

    let newFoodKey = database.child('foodEntries').push().key;
    // Write the new providers's data to the providers list
    let updates = {};


    return new Promise((resolve, reject) => {

      entry.id = newFoodKey;


    let allProviders = FireUtils.values.providers;
    let currentProviders = Object.keys(allProviders).filter((id) => allProviders[id].accountId === context.getters.currentUser.id)
    if (!currentProviders || !currentProviders[0]) {
      reject(Error('You are not logged in.'));
      return;
    }

    let provider = currentProviders[0];
    entry.providerId = provider;

    updates[`/foodEntries/${newFoodKey}`] = entry;
    updates[`/providers/${provider}/foodEntryIds/${newFoodKey}`] = true;
    database.update(updates, (error) => {
      if (error) { reject(error); }
      else { resolve(); }
    });
    });
  },
  deleteEntry: (context, entry) => {

    if (!entry.id) {
      return;
    }

    FireUtils.normalize.entry(entry);

    let database = Firebase.database().ref();
    var reservedByIds = entry.reservedByIds;
    var updates = {};

    return new Promise((resolve, reject) => {

      // if (reservedByIds) {
      //   // Find the orders connected with users who have reserved this entry
      //   var orders = FireUtils.values.orders;
      //   var orderIds = Object.keys(orders);
      //
      //   var matchedOrderIds = orderIds.filter(key => {
      //     var order = orders[key];
      //     if (order.reservedItems) {
      //       return order.reservedItems[entry.id];
      //     }
      //   });
      //
      //   matchedOrderIds.forEach(id => {
      //     var order = orders[id];
      //     // Remove this entry from the order
      //     updates[`orders/${order.id}/reservedItems/${entry.id}`] = null;
      //     // Set the changeFlag to true to indicate that the provider made a change to the order
      //     updates[`orders/${order.id}/changeFlag`] = true;
      //
      //     // Do not actually delete the order here if it is empty after the operation. It needs to still exsist in the database so that the user can be notified of the change...
      //
      //     // double check that this user exists in the reservedByIds
      //     if (entry.reservedByIds[order.user]) {
      //       // adjust the user's quota and remove the order if necessary
      //       var user = FireUtils.values.users[order.user];
      //       // get the quota
      //       var quotaReserved = user.quotaInfo.numberOfItems;
      //       // decrease by the number of reservations the user has for this entry
      //       var decreasedReserved = Math.max(quotaReserved - entry.reservedByIds[user.id], 0);
      //       // update the user quota value
      //
      //       // if the entry being deleted was reserved by the user today, take away the count it made against their quota
      //       let todaysReservations = user.quotaInfo[moment().startOf('day').valueOf()];
      //       if (todaysReservations[entry.id]) {
      //         updates[`users/${user.id}/quotaInfo/${todaysReservations}/${entry.id}`] = null;
      //         updates[`users/${user.id}/quotaInfo/numberOfItems`] = decreasedReserved;
      //       }
      //
      //     }
      //   });
      //
      // }
      // Remove the actual entry from the database
      updates[`foodEntries/${entry.id}`] = null;
      // updates[`/providers/${entry.providerId}/foodEntryIds/${entry.id}`] = null;

      database.update(updates, function(err) {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });

  },
  reserve: (context, entry) => {
    console.log('store-modules/entries.js reserve()');

    FireUtils.normalize.entry(entry);
    let database = Firebase.database().ref();
    let userId = Firebase.auth().currentUser.uid;
    // Get the db object representing info about the user.
    let user = context.getters.currentUser;

    // By retuning a promise, we allow callers to execute function after the operation completes.
    return new Promise((resolve, reject) => {

      // -------- QUOTA LIMIT CHECK ---------

      if (context.getters.availibilityCount(entry) < 1) {
        let error = Error('This entry is no longer available.');
        error.name = "Not Available";
        error.type = "warning";
        reject(error);
        return;
      }

      let currentQuotaCount = user.quotaInfo.numberOfItems;
      let reserveLimit = FireUtils.values.devSettings.reserveLimit;

      // Determine whether the stored quota represents today
      let quotaRepresentsToday = user.quotaInfo.date && moment().isSame(user.quotaInfo.date, 'day');

      // If they have reached their quota Today, reject the request
      if (quotaRepresentsToday && currentQuotaCount >= reserveLimit) {
        let error = Error('You have reached your daily quota.');
        error.name = "Quota Reached";
        error.type = "warning";
        reject(error);
        return;
      }


      // -------- DATA FANOUT ---------
      // Begin creating updates for fanout of entry reservation
      let updates = {};

      // -------- DATA FANOUT ORDERS -----------
      // Get the orderId if one exists
      let orderId = user.orders[entry.providerId];

      if (!orderId) {
        // Create an order for this user and provider
        let order = {
          user: user.id,
          provider: entry.providerId,
          reservedItems: {}
        };

        // make a new key for the order
        orderId = database.child('orders').push().key;
        order.id = orderId;
        order.reservedItems[entry.id] = true;
        updates[`orders/${orderId}`] = order;
      } else {
        // The order exists so only update the parts we need to
        // update the order to include the entry
        updates[`orders/${orderId}/reservedItems/${entry.id}`] = true;

      }

      // -------- DATA FANOUT FOOD ENTRIES -----------

      // Get the number that the user has currently reserved on this entry.
      let reserveCount = entry.reservedByIds[user.id] || 0;;

      // Increment the number of items they have claimed for this Entry
      reserveCount += 1;

      // update the entry reserveCount for the userId
      updates[`foodEntries/${entry.id}/reservedByIds/${user.id}`] = reserveCount;


      // -------- DATA FANOUT USERS -----------

      let reservedItems = user.quotaInfo.reservedItems;
      let todayReserveCountForEntry = reservedItems && reservedItems[entry.id] ||  0;

      todayReserveCountForEntry += 1;
      currentQuotaCount += 1;

      if(quotaRepresentsToday){
        console.log('       updates - quotaRepresentsToday');
        // update the user quota info
        updates[`users/${user.id}/quotaInfo/numberOfItems`] = currentQuotaCount;
        updates[`users/${user.id}/quotaInfo/reservedItems/${entry.id}`] = todayReserveCountForEntry;
      }else {
        console.log('       updates - creating new quotaInfo');

        // replace the user quota info

        // Set the currentQuotaCount to 1 because this is the first reservation today
        currentQuotaCount = 1;
        let beginningOfToday = moment().startOf('day').valueOf();

        var quotaInfoObject = {
          numberOfItems : currentQuotaCount,
          date : beginningOfToday,
          reservedItems : {}
        };
        // Set the value to 1 here and ignore any previous value
        quotaInfoObject.reservedItems[entry.id] = 1;
        updates[`users/${user.id}/quotaInfo`] = quotaInfoObject;
      }

      // Make sure the order id is stored in the user's orders
      updates[`users/${user.id}/orders/${entry.providerId}`] = orderId;
      // This ensures that the user id is stored under the user's node
      updates[`users/${user.id}/id`] = user.id;

      // -------- PERFORM DATA FANOUT -----------
      // Perform the updates
      database.update(updates, (error) => {
        if (error) {
          console.log(`User: ${user.id} error reserving : ${entry.id}`);
          reject(error);
          return;
        } else {
          console.log(`User: ${user.id} reserved : ${entry.id}`);
          resolve();
        }
      });

    });
  },
  unreserve: (context, entry) => {

    FireUtils.normalize.entry(entry);
    let database = Firebase.database().ref();
    // Get the db object representing info about the user.
    let user = context.getters.currentUser;

    return new Promise((resolve, reject) => {
      // If this user has not yet reserved any items under this id, return
      if (!entry.reservedByIds || !entry.reservedByIds[user.id]) {
        reject(Error('This item has not been reserved'));
        return;
      }

      // Get the number that the user has currently reserved on this entry.
      var userReserveCount = entry.reservedByIds[user.id];
      var updates = {};

      // Decrement the number of items they have claimed for this Entry
      userReserveCount--;
      if (userReserveCount <= 0) {
        // causes the update to delete the user.id from foodEntry/reservedByIds, and from the quotaInfo/reservedItems
        userReserveCount = null;

        var orderId = user.orders[entry.providerId];
        // if this was the last reserved item in the order, delete the order from orders node and user node
        var order = FireUtils.values.orders[orderId];

        if (order) {
          FireUtils.normalize.order(order);
          var orderItems = Object.keys(order.reservedItems);
          if (orderItems.length <= 1) { // && reserveCount <= 0 (carrying over from outer loop)

            var cancelOrderUpdates = FireUtils.updates.forCancellingOrder(order);
            Utils.extend(updates, cancelOrderUpdates);
          } else {
            //and causes the update to delete the entry.id from the order.reservedItems
            updates['orders/' + orderId + '/reservedItems/' + entry.id] = null;
          }
        }
      }

      updates[`foodEntries/${entry.id}/reservedByIds/${user.id}`] = userReserveCount;
      // it doesn't matter if reservedItems is from today or not
      updates[`users/${user.id}/quotaInfo/reservedItems/${entry.id}`] = userReserveCount;

      if (user.quotaInfo.date == moment().startOf('day').valueOf()) {
        let newQuota = user.quotaInfo.numberOfItems || 0;
        newQuota--;
        if (newQuota <0) newQuota = 0;
        updates[`users/${user.id}/quotaInfo/numberOfItems`] = newQuota;
      }


      // Perform the updates
      database.update(updates, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });


    });
  }

}

const getters = {
  availibilityCount: (state, getters) => (entry) => {

    let reserved = getters.numberReserved(entry);

    // The number of items still available of this entry is the total number listed minus
    // the number that have been reserved
    return entry.totalListed - reserved;
  },
  numberReserved: (state) => (entry) => {
    let ids = entry.reservedByIds ? entry.reservedByIds : {};

    // Reduce the reservedByIds to a sum of total reservations
    let reserved = Object.keys(ids).map((k) => ids[k]).reduce((sum, val) => {
      return sum + val
    }, 0);
    return reserved;
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
