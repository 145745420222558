<template lang="html">
  <div class="location-search">
    <form>
            <input v-model="zipCodeField" type="text" class="underlined" name="search" placeholder="Enter address, city, or zip code">
            <div @click="setLocationToCurrent" class="location-button hint hint--rounded hint--bottom" aria-label="Search Current Location"><span><i class="custom icomoon-location"></i><div v-if="locating" class="pulsing"></div></span></div>
            <button @click="setLocationFromZip" class="btn type--uppercase btn--primary">Go</button>
    </form>
  </div>
</template>

<script>

export default {
  name: 'location-search',
  components: {},
  props: [],
  firebase: {},
  data() {
    return {
      zipCodeField : "",
      locating : false
    }
},
  computed: {},
  methods: {

    // do something when current location button clicked
    // set location to be deduced vs taken from input
    setLocationToCurrent () {
      if ("geolocation" in navigator) {
              console.log("Asking user to get their location");

              this.locating = true;
              navigator.geolocation.getCurrentPosition(
                  (position) => {
                    let location = { lat : position.coords.latitude, lng : position.coords.longitude };
                    this.$store.commit('setCurrentLocation', location);

                    console.log(location);
                    this.$googleMaps.geocode({ location }).asPromise()
                    .then(this.updateStoreForGeocodeResponse)
                    .catch((err) => {
                      console.log('error with geocoder');
                      console.log(err);

                      this.locating = false
                      this.$modal.show('error-alert', {
                        title: 'Could not determine Address',
                        message: 'We were unable to determine the address of your location',
                        type: 'warning'
                      });
                    });
                  },
                  (error) => {
                    this.locating = false
                    console.log('error with navigator');
                    console.log(error);
                    this.$modal.show('error-alert', {
                      title: 'Error Locating',
                      message: 'We were unable to determine your location',
                      type: 'error'
                    });
                  }, { timeout: 5000});

        } else {
            this.$modal.show('error-alert', {
              title: 'Error Locating',
              message: 'Your browser does not support Geolocation. Please enter your zip code instead.',
              type: 'error'
            });
        }
    },

    // set current location when go is clicked
    setLocationFromZip(event) {
      event.preventDefault();
      let address = this.zipCodeField;
      console.log("zip");
      console.log(address);
      this.locating = true;
      this.$googleMaps.geocode({ address }).asPromise()
      .then(this.updateStoreForGeocodeResponse)
      .catch(() => {
        this.$modal.show('error-alert', {
          title: 'Error Locating',
          message: 'We were unable to find this location',
          type: 'error'
        });
      });
    },

    updateStoreForGeocodeResponse(response){
      console.log(response);
      console.log(response.json.results);
      let results = response.json.results[0];
      let location = results.geometry.location;
      this.$store.commit('setGeoLocationInfo', results);
      this.$store.commit('setCurrentLocation', location);
      this.locating = false;
    }
  }
}

</script>

<style lang="css" scoped>
form {
  display: flex;
  align-items: stretch;
  width: 100%;
}

form input {
  /* make the input stretch to fill remaining space */
  flex-grow: 1;
  background: transparent;
  padding-right: 60px;
}

.location-button {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0;
  margin-right: 20px;
  margin-left: -60px;
  padding: 0 20px;
  color: #FF8E5A;
  cursor: pointer;
  transition: 0.2s;
}

.location-button:hover {
  transition: 0.2s;
  transform: scale(1.3, 1.3);
}

div.pulsing {
  position: absolute;
  top: -5px;
  left: 4px;
  content: " ";
  width: 50px;
  height: 50px;
  border: 2px solid #ff8e5a;
  border-radius: 100px;
  animation:  2s pulse ease infinite;
}

@keyframes pulse {
  0% {
    opacity: 1.0;
    transform: scale(0.0,0.0);
  }
  40% {
    opacity: 1.0;
  }
  100% {
    opacity: 0;
    transform: scale(1.0,1.0);
  }
}

</style>
