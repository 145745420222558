<template lang="html">
  <div class="image-alert-modal">
   <modal
      name="image-alert"
      :reset="true"
      :classes="modalClasses"
      transition="scale"
      :adaptive="true"
      @before-open="beforeOpen">
      <div class="modal--close-button" @click="$modal.hide('image-alert')">
        <i class="custom icomoon-cross">
        </i>
      </div>
      <div class="h-centered-content">
        <img :src="modalConfig.imagePath" :class="modalConfig.imageClasses" alt="">
          <div class="modal--header">
            <h4>{{ modalConfig.title }}</h4>
          </div>
          <div class="modal--content">
            <p>{{ modalConfig.message }}</p>
          </div>
      </div>
   </modal>
  </div>
</template>

<script>
import Utils from '../../modules/utilities'

const defaultModalInfo = {
  title: '',
  message: '',
  imagePath: '',
  imageClasses: [],
  modalClasses: []
};

  export default {
      name: "image-alert",
      components: {  },
      props: ['configuration'],
      data(){ return {
        modalInfo: defaultModalInfo,
        defaultModalInfo
       } },
      computed: {
        modalClasses(){
          let defaultClasses = ['v--modal', 'image-alert-modal'];
          return defaultClasses.concat(this.modalConfig.modalClasses);
        },
        modalConfig(){
          return this.configuration || this.modalInfo;
        }
      },
      methods: {
        beforeOpen (event) {
          let mi = event.params;
          Utils.normalize(mi, this.defaultModalInfo)
          this.modalInfo = mi;
        }
      },
  }
</script>

<style lang="css" scoped>
</style>
