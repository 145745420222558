<template lang="html">
  <div>
    <section class="explanation-process imagebg text-center in-page-link unmarg--top">
        <div class="background-image-holder">
            <img alt="background" src="img/bg/slate-1080.svg">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h1 class="h1--large">Frequently Asked Questions</h1>
                </div>
            </div>
            <!--end of row-->
        </div>
    </section>
    <section class="ui main text container legal-section">
        <div class="row">

            <div class="col-xs-12 col-md-8 col-md-offset-2">
                <div class="font-open_sans">
                    <h3>Do I have to make an account?</h3>
                    <p>Only if you are going to be a partner establishment with us. If you are someone looking for food, no account is required.</p>
                    <h3>How much food can I reserve?</h3>
                    <p>You can only reserve up to 5 new items each day. This ensures that everyone gets a fair chance.</p>
                    <h3>How long does my reservation last?</h3>
                    <p>When you reserve an item, you have until the “Pickup by” date to pick it up. After that it will be disposed of. Partners do reserve the right to cancel reservations of items if there is a food safety issue, such as if they find the food has gone bad before the pickup date they originally listed.</p>
                    <h3>What is the barcode for?</h3>
                    <p>For each Partner you reserve from, you have an Order in your Bag. This includes a barcode that represents all the items that are waiting for you to pickup from that location. Your barcode will be scanned on pickup to confirm your reservations. There is no fee. The barcode is only used to let Partners know what items you are picking up and to help them manage their items.</p>
                    <h3>Where and how do I pickup my food?</h3>
                    <p>Once you have reserved items, you must go to the store, restaurant, or other establishment that posted the food, and pick it up. It will be different places depending on which items you reserved. So make sure to reserve items at locations that you can access. Your Order includes the address to go to and directions on a map. You also will see on the Order instructions of where to go once you arrive, who to talk to, and what times are best for pickup. Make sure to take your barcode on your phone or a printed copy from the website so they can confirm your reservation.</p>
                    <h3>Will my Bag be saved for me to check later?</h3>
                    <p>We do not gather, track, or store any personal information. But your Bag is stored using browser cookies so that your orders will still be there when you come back later. When using the app on a smartphone, your Bag will also be saved even after opening and closing the app.</p>
                    <h3>Who can become a Partner?</h3>
                    <p>Our Partners are approved restaurants, stores, and other food service establishments that have agreed to participate in providing free food. Once they have setup an account with us, they post food as it becomes available.</p>

                    <p>If you have any questions you can always <router-link :to="$siteRoutes.contactus.path">contact us</router-link>.</p>
            </div>
          </div>
        </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'info-view',
  components: {},
  props: [],
  firebase: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!

    // calling next, allows the routing to proceed
    next(vm => {
      // access to component instance via `vm`
      vm.$emit('update:navigationOverlay', 'overlayLight');
    });
  }
};
</script>

<style lang="css" scoped>
</style>
