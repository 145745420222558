<template lang="html">
  <div class="contact-us">
  <div class="contact-us__content">
   <form class="jotform-form" action="https://submit.jotform.us/submit/72120547978160/" method="post" name="form_72120547978160" id="72120547978160" accept-charset="utf-8">

     <input type="hidden" name="formID" value="72120547978160" />

     <div class="form-all">

       <ul class="form-section page-section">
         <li id="cid_1" class="form-input-wide" data-type="control_head">
           <div class="form-header-group ">
             <div class="header-text httal htvam">
               <h2 id="header_1" class="form-header" data-component="header"> Contact Us </h2>
             </div>
           </div>
         </li>

         <li class="form-line" data-type="control_textbox" id="id_3">
           <label class="form-label form-label-top form-label-auto" id="label_3" for="input_3"> Name </label>
           <div id="cid_3" class="form-input-wide jf-required">
             <input type="text" id="input_3" name="q3_name" data-type="input-textbox" class="form-textbox underlined" size="20" value="" data-component="textbox" />
           </div>
         </li>
         <li class="form-line" data-type="control_textbox" id="id_4">
           <label class="form-label form-label-top form-label-auto" id="label_4" for="input_4"> Email * </label>
           <div id="cid_4" class="form-input-wide jf-required">
             <span class="form-sub-label-container" style="vertical-align:top;">
               <input type="text" id="input_4" name="q4_email" data-type="input-textbox" class="form-textbox underlined validate[required, Email]" size="20" value="" data-component="textbox" required=""/>
               <label class="form-sub-label " for="input_4"  style="min-height:13px;"> Give us a way to contact you! We never share your email </label>
             </span>
           </div>
         </li>
         <li class="form-line" data-type="control_textarea" id="id_5">
           <label class="form-label form-label-top form-label-auto" id="label_5" for="input_5"> Feedback or Questions *  </label>
           <div id="cid_5" class="form-input-wide jf-required">
             <textarea id="input_5" class="form-textarea validate[required]" name="q5_feedbackOr" cols="40" rows="6" data-component="textarea" required=""></textarea>
           </div>
         </li>
         <li class="form-line" data-type="control_button" id="id_2">
           <div id="cid_2" class="form-input-wide">
             <div style="text-align:left;" class="form-buttons-wrapper">
               <button id="input_2" type="submit" class="btn btn--primary" data-component="button"> Submit </button>
             </div>
           </div>
         </li>
         <li style="display:none"> Should be Empty: <input type="text" name="website" value="" /> </li>
       </ul>


     </div>
      </form>
    </div>
    <div class="bg--primary-2 decorative-stripe">

    </div>
  </div>
</template>

<script>
  export default {
      name: "contact-us",
      components: {  },
      props: [  ],
      firebase: {},
      data(){ return {  } },
      computed: {},
      methods: {
        embedForm(){
          let JotForm = window.JotForm;
          JotForm.init(function(){
            JotForm.alterTexts({"alphabetic":"This field can only contain letters",
            "alphanumeric":"This field can only contain letters and numbers.",
            "ccDonationMinLimitError":"Minimum amount is {minAmount} {currency}",
            "ccInvalidCVC":"CVC number is invalid.",
            "ccInvalidExpireDate":"Expire date is invalid.",
            "ccInvalidNumber":"Credit Card Number is invalid.",
            "ccMissingDetails":"Please fill up the Credit Card details.",
            "ccMissingDonation":"Please enter numeric values for donation amount.",
            "ccMissingProduct":"Please select at least one product.",
            "characterLimitError":"Too many Characters. The limit is",
            "characterMinLimitError":"Too few characters. The minimum is",
            "confirmClearForm":"Are you sure you want to clear the form?",
            "confirmEmail":"E-mail does not match",
            "currency":"This field can only contain currency values.",
            "cyrillic":"This field can only contain cyrillic characters",
            "dateInvalid":"This date is not valid. The date format is {format}",
            "dateInvalidSeparate":"This date is not valid. Enter a valid {element}.",
            "dateLimited":"This date is unavailable.",
            "disallowDecimals":"Please enter a whole number.",
            "email":"Enter a valid e-mail address",
            "fillMask":"Field value must fill mask.",
            "freeEmailError":"Free email accounts are not allowed",
            "generalError":"There are errors on the form. Please fix them before continuing.",
            "generalPageError":"There are errors on this page. Please fix them before continuing.",
            "gradingScoreError":"Score total should only be less than or equal to",
            "incompleteFields":"There are incomplete required fields. Please complete them.",
            "inputCarretErrorA":"Input should not be less than the minimum value:",
            "inputCarretErrorB":"Input should not be greater than the maximum value:",
            "lessThan":"Your score should be less than or equal to",
            "maxDigitsError":"The maximum digits allowed is",
            "maxSelectionsError":"The maximum number of selections allowed is",
            "minSelectionsError":"The minimum required number of selections is",
            "multipleFileUploads_emptyError":"{file} is empty, please select files again without it.",
            "multipleFileUploads_fileLimitError":"Only {fileLimit} file uploads allowed.",
            "multipleFileUploads_minSizeError":"{file} is too small, minimum file size is {minSizeLimit}.",
            "multipleFileUploads_onLeave":"The files are being uploaded, if you leave now the upload will be cancelled.",
            "multipleFileUploads_sizeError":"{file} is too large, maximum file size is {sizeLimit}.",
            "multipleFileUploads_typeError":"{file} has invalid extension. Only {extensions} are allowed.",
            "numeric":"This field can only contain numeric values",
            "pastDatesDisallowed":"Date must not be in the past.",
            "pleaseWait":"Please wait...",
            "required":"This field is required.",
            "requireEveryCell":"Every cell is required.",
            "requireEveryRow":"Every row is required.",
            "requireOne":"At least one field required.",
            "submissionLimit":"Sorry! Only one entry is allowed. Multiple submissions are disabled for this form.",
            "uploadExtensions":"You can only upload following files:",
            "uploadFilesize":"File size cannot be bigger than:",
            "uploadFilesizemin":"File size cannot be smaller than:",
            "url":"This field can only contain a valid URL",
            "wordLimitError":"Too many words. The limit is",
            "wordMinLimitError":"Too few words. The minimum is"});

            JotForm.clearFieldOnHide="disable"; JotForm.submitError="jumpToFirstError"; /*INIT-END*/
          });

          JotForm.prepareCalculationsOnTheFly(
            [null,
          {"name":"contactUs",
          "qid":"1",
          "text":"Contact Us",
          "type":"control_head"},
          {"name":"submit2",
          "qid":"2",
          "text":"Submit",
          "type":"control_button"},
          {"name":"name",
          "qid":"3",
          "text":"Name",
          "type":"control_textbox"},
          {"name":"email",
          "qid":"4",
          "text":"Email",
          "type":"control_textbox"},
          {"name":"feedbackOr",
          "qid":"5",
          "text":"Feedback or Questions",
          "type":"control_textarea"}]
        );

        }
      },
      mounted(){
        this.embedForm();
      }
  }
</script>

<style lang="css" scoped>
.contact-us .contact-us__content {
  height: 80vh;
  max-width: 50em;
  margin: auto;
  padding: 30px;
}
.defaultOutroBranded{
  visibility: hidden !important;
  display: none !important;
}


.form-label.form-label-auto {
  float: none;
}

button.btn, input[type="submit"].btn {
  font-size: 1.3em;
  max-width: 10em;
  max-height: 3em;
}

.form-label.form-label-auto {
  width: unset;
}

@media screen and (min-width: 768px)
{
  .jotform-form {
    padding: 0;
  }
}
</style>
