<template lang="html">
  <div class="sign-in">
    <div class="flex">
   <div>
     <form>
       <h1 class="color--primary-2 text-center">
         <span v-if="!creating">Sign In to Provide</span>
         <span v-if="creating">Create an Account to Provide</span>
       </h1>
       <div class="sign-in-explaination">
         <p class="font-dosis">Sign in or create an account only if your organization wishes to participate in providing free food.</p>
         <p class="font-dosis">You do not need an account to reserve food.</p>
         <p v-if="error" class="error">{{ error }}</p>
       </div>
       <div>
         <input v-model="emailAddress" type="email" placeholder="Email" class="underlined" required>
         <input v-model="password" type="password" placeholder="Password" class="underlined" required>

         <input v-if="creating" v-model="passwordConfirmed" type="password" placeholder="Confirm Password" class="underlined" required>

          <span v-if="creating" class="type--fine-print terms-check text-center"><input v-model="checked" type="checkbox" name="agree" required/> I have read and agree to the <router-link :to="routes.terms.path">Terms</router-link></span>

         <div class="buttons pad--20 pad--top-40">
           <a class="btn btn--primary-1" @click="cancel">
               <span class="btn__text">Cancel</span>
           </a>
           <action-button class="action-button--primary" @button-click="confirmAction" :is-loading.sync="isSigningIn" :is-confirmed.sync="isSignInComplete">
             <span v-if="!creating">Sign In</span>
             <span v-else>Sign Up</span>
           </action-button>


         </div>
         <a class="h4 color--primary text-center pad--20 toggle-creating" @click="creating = !creating">
           <span v-if="!creating">Don't have an account?</span>
           <span v-if="creating">Have an account?</span>
         </a>
       </div>
       <div v-if="creating" class="text-center">
         <span><router-link :to="$siteRoutes.contactus.path">Contact us</router-link> if you have questions.</span>
       </div>
     </form>

   </div>
 </div>
 <div class="bg--primary-2 decorative-stripe">

 </div>
  </div>
</template>

<script>
  import FireUtils from '../modules/fireutils.js';
  import routes from '../vue-router/routes.js';

  export default {
      name: "sign-in",
      components: {},
      props: [  ],
      firebase: {},
      data(){
        return {
          emailAddress: '',
          password: '',
          passwordConfirmed: '',
          checked: false,
          error: null,
          creating: false,

          isSigningIn : false,
          isSignInComplete : false,
          routes
        }
      },
      computed: {},
      methods: {
        confirmAction(){
          this.isSigningIn = true;

          if(!this.creating){
            this.trySignIn();
          }else{
            this.trySignUp();
          }
        },
        trySignIn(){
          FireUtils.values.signIn(this.emailAddress, this.password)
          .then(() => {
            this.isSignInComplete = true;
            console.log('sign-in.vue -- sign in succeeded');
            return this.$store.dispatch('updateUserIsAdmin');
          })
          .then(() => {
            this.routeToProvide();
          })
          .catch((error) => {
            console.log('sign-in.vue -- sign in error');
            this.isSigningIn = false;
            this.error = error.message;
          });

        },
        trySignUp(){
          if (this.password != this.passwordConfirmed) {
            this.error = 'Passwords do not match';
            this.isSigningIn = false;
            return;
          }
          if (!this.checked){
            this.error = 'You must agree to the Terms';
            this.isSigningIn = false;
            return;
          }
          FireUtils.values.signUp(this.emailAddress, this.password).then(() => {
            this.isSignInComplete = true;
            console.log('successfully created account');
            this.routeToApply();
          })
          .catch((error) => {
            console.log(`error creating account : ${error}`);
            this.isSigningIn = false;
            this.error = error.message;
          })
        },
        routeToProvide(){
          console.log('routeToProvide');
            this.$router.push(routes.provide.path);
        },
        routeToApply(){
          console.log('routeToApply');
          this.$router.push({name: routes.apply.name});
        },
        cancel(){
          this.$router.back();
        }
      },
  }
</script>

<style lang="css" scoped>

h2 {
  margin-top: 20px;
  text-align: center;
}

.sign-in-explaination, .sign-in-explaination * {
  text-align: center;
}
.sign-in-explaination p:first-of-type{
  margin-bottom: 0;
  font-size: 1.25em !important;
  color: #595b70;
}

.sign-in-explaination p:last-of-type {
  color: #aaa;
}

label {
  margin: 1em -10px 0 10px;
}



input[type="checkbox"] {
  -webkit-appearance: checkbox;
  height: auto;
  width: auto;
}

.terms-check input[type="checkbox"]{
  margin-top: 20px;
  margin-right: 10px;
}

.flex {
  margin-top: 50px;
  transition: 0.3s;
}

.flex > div {
  width : 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media all and (min-height: 700px) {

  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  .decorative-stripe {
    position: absolute;
  }

}

form {
  width: 500px;
  padding-bottom: 50px;
}

form > div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.buttons {
  display: flex;
  align-items: center;
  /*margin-top: 16px;*/
}
.buttons > a {
  flex-grow: 1;
}

@media all and (max-width: 767px) {
  form {
    width: 100%;
    padding: 0 16px;
  }
  .flex {
    align-items: flex-start;
  }
  /*.buttons {
    flex-direction: column;
  }*/

  .buttons a:last-child {
    order: -1;
    margin-bottom: 16px;
  }
}

.toggle-creating {
  cursor: pointer;
}
</style>
