<template lang="html">
  <simple-accordion :showIndicator="false" ref="accordion" class="user-order order" @opened="checkChanges">
    <div slot="header">
      <div class="order__header bg--tertiary">
        <div class="order__header__title">
          <div class="provider-name">{{ headerTitle }}</div>
          <div class="provider-address">{{ headerSubtitle }}</div>
        </div>
        <div class="order__header__divider"></div>
        <div class="order__header__detail color--primary">
          <div class="order__total-items">
            <div class="value">{{ reservedCount }}</div>
            <div class="value-label">Items</div>
          </div>
        </div>
      </div>
    </div>

    <div class="order__content">
      <div class="delivered" v-if="isDelivered"><i class="custom icomoon-circle-check"></i></div>
      <div v-if="!displayedForProvider" class="order__content__info">
        <div class="pickup-instructions">{{ provider.pickupInstructions }}</div>
        <div class="barcode">
          <img alt="Unable to load barcode" :src="barcodeSrc">
        </div>
        <div class="order__id">{{ order.id }}</div>
      </div>
      <div class="order__content__reserved-items">
        <div v-for="entry in reservedItems" class="order__reserved-item">
            <div class="entry-name">{{entry.name}}</div>
            <div class="number-reserved">{{entry.reservedByIds[order.user]}}</div>
            <div v-if="!displayedForProvider"  class="entry-remove" @click="unreserve(entry)">
              <i class="custom icomoon-circle-cross"></i>
            </div>
        </div>
        <div v-if="expiredItems.length > 0">
          <div class="">Expired Items</div>
          <div v-for="entry in expiredItems" class="red-text order__reserved-item">
              <div class="entry-name">{{entry.name}}</div>
              <div class="number-reserved">{{entry.reservedByIds[order.user]}}</div>
              <div v-if="!displayedForProvider"  class="entry-remove" @click="unreserve(entry)">
                <i class="custom icomoon-circle-cross"></i>
              </div>
          </div>
        </div>
      </div>
      <div class="order__content__action-buttons">
        <confirm-button v-if="!displayedForProvider" class="action-button--white btn--rounded" @button-click="cancel()">
          Cancel Order
        </confirm-button>
        <!-- <div v-if="!displayedForProvider" class="delete btn--rounded" @click="cancel()">
        </div> -->
        <confirm-button v-if="displayedForProvider" class="action-button-white color--primary btn--rounded" @button-click="deliver()">
          Deliver
        </confirm-button>
        <div class="close color--primary btn--rounded" @click="hideDetails">
          Hide Details
        </div>
      </div>

    </div>

    <!-- <transition name="pop-open">
            <div class="delivered" v-if="isDelivered"></div>
    </transition> -->


  </simple-accordion>
</template>

<script>
import Fireapp from '../modules/fireapp.js';
import FireUtils from '../modules/fireutils.js'

import SimpleAccordion from './simple-accordion.vue';

  export default {
      name: "user-order",
      components: { SimpleAccordion },
      props: [ 'orderId', 'displayedForProvider' ],
      firebase() {
        return {
          order : { source : Fireapp.database().ref(`orders/${this.orderId}`),
          asObject : true
        },
          // This may seem terribly inefficient, but because firebase caches data, this is not much different than passing an array from a parent component. See http://stackoverflow.com/a/38423694
          allItems :{
            source : Fireapp.database().ref('foodEntries'),
            asObject : true
          }
        }
      },
      data(){
        return {
          isDelivered : false
        }
      },
      computed: {
        reservedCount(){
          // Compute the total number of reserved items with the store getter
          let count = this.$store.getters.reservedItemTotal(this.order);
          return count;
        },
        provider() {

          if (!this.order || !FireUtils.values.providers[this.order.provider]) {
            // let p = {}
            return FireUtils.normalize.provider({});
          }
          return FireUtils.values.providers[this.order.provider];
        },
        allReservedItems(){
          return this.$store.getters.reservedItemEntries(this.order);
        },
        reservedItems(){
          return this.allReservedItems.filter(e => e.expirationDate > Date.now());
        },
        expiredItems(){
          return this.allReservedItems.filter(e => e.expirationDate <= Date.now());
        },
        barcodeSrc(){
          return `http://api-bwipjs.rhcloud.com/?bcid=pdf417&text=${this.order.id}&scaleY=3`;
        },
        currentUserId() { return this.$store.state.currentUserId; },

        headerTitle(){
          if (this.displayedForProvider) {
            return this.order.id;
          }else {
            return this.provider.name;
          }
        },
        headerSubtitle(){
          if (this.displayedForProvider) {
            return "Order Id"
          }else {
            return this.provider.address.street;
          }
        }

      },
      methods: {
        unreserve(entry){
          // console.log(`[user-order] unreserve(${entry.id})`)
          this.$store.dispatch('unreserve', entry)
          .catch((err) => {
            this.$modal.show('error-alert', {
              title: 'Error unreserving',
              message: err.message,
              type: 'error'
            });
          });
        },
        cancel(){
          // this.isDelivered = true;
          // window.setTimeout(() => {
          this.$store.dispatch('cancel', this.order)
          .catch((err) => {
            this.$modal.show('error-alert', {
              title: 'Error Cancelling Order',
              message: err.message,
              type: 'error'
            });
          });
        // }, 600);
        },
        deliver(){
          this.isDelivered = true;
          window.setTimeout(() => {
            this.$store.dispatch('deliver', this.order)
            .catch((err) => {
              this.$modal.show('error-alert', {
                title: 'Error Delivering Order',
                message: err.message,
                type: 'error'
              });
            });
          }, 600);

        },
        hideDetails() {
          this.$refs.accordion.toggle();
        },
        checkChanges(){

          if (FireUtils.values.userIsAnonymous && this.order && this.order.changeFlag) {

            let provider = FireUtils.values.providers && FireUtils.values.providers[this.order.provider] || null;
            let providerName = provider && provider.name || 'The Provider';

            this.$modal.show('error-alert', {
              title: `Order Changes`,
              message: `${providerName} needed to remove one or more items from your order.`,
              type: 'warning'
            });

            let order = this.order;
            delete order.changeFlag;
            console.log(this.order);
            this.$store.dispatch('saveOrder', order)
            .catch((err) => { console.log(err); });
          }
        }
      }
  }
</script>

<style lang="css" scoped>
.red-text {
  color: red;
}

.user-order {
  position: relative;
  overflow: hidden;
}
.delivered {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  background-color: #D7E55C;
  border-radius: 6px;
  animation: .3s slide-up ease-out;
}

.delivered i {
  position: absolute;
  top: 1em;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 5em;

}

.btn--rounded {
  border-radius: 0px 0px 6px 6px;

}

@keyframes slide-up {
0% {
  top: 100%;
}
100% {
  top: 0px;
}
}

@media print {
  .order__content__action-buttons,
  .entry-remove {
    display: none;
  }
}

/* when combined with the list transitions from the bag, creates smooth visuals for order delivery */

/*.delivered {
  display: relative;
  position:absolute;
  content: '';
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: #D7E55C;
  border-radius: 6px;
  flex: 1 1 auto;
}*/

/*.pop-open-enter-active, .pop-open-enter-leave {
  // transition: 0.4s;
  animation: pop-in 0.4s ease-out;
}

.pop-open-enter{

}
.pop-open-leave-to {
}
@keyframes pop-in {
  0% {
    transform: scale(1.0, 1.0);
    opacity: 0;
  }
  70% {
    transform: scale(1.1, 1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1.0,1.0);
    border-radius: 6px;
  }
}*/

</style>
