<template lang="html">
    <div class="box-background">

        <form class="form-container">

          <div class="flex-item-half">
            <h2 class="flex-item">Create Entry</h2>
            <img src="img/svg/Circle_x.svg" class="close-bag-button flex-item" @click="$emit('close')">
          </div>

            <!-- Category -->
            <div class="flex-item">
              <label class="font-md hint--right hint--rounded" aria-label="Choosing an appropriate category will help people find this item">Category</label>
                <div class="input-select">
                    <select v-model="selectedCategory">
                      <option v-for="c in categories" :value="c.id" >{{c.displayName}}</option>
                    </select>
                </div>
            </div>

            <!-- Item Name -->
            <div class="flex-item">
              <label class="font-md hint--right hint--rounded" aria-label="Please limit to 50 characters">Item Name</label>
                <input v-model="itemName" type="text" placeholder="Item Name (Optional)">
            </div>

            <!-- Pickup By -->
            <div class="flex-item">
              <label class="font-md hint--right hint--rounded" aria-label="The latest date a user can pick up this item">Pickup By</label>
              <p class="error font-sm" v-show="invalidDate">You must choose either today or a future date.</p>
              <!-- wrap this input in a div so that we can show a tip on only the input and not the whole group -->
              <div class="unpad unmarg" aria-label="Enter a date">
                <!-- <input ref="datePicker" type="text" name="date" class="datepicker" placeholder="Choose a date" /> -->
                <flat-pickr v-model="expiration" :class="{'error' :invalidDate}" input-class="date-input"   placeholder="Choose a date" @input="invalidDate = false"></flat-pickr>
              </div>
            </div>

            <!-- Unit -->
            <div class="flex-item-half">
              <div class="flex-item-unit-input" aria-label="Value must be greater than zero">
                  <label class="font-md hint--right hint--rounded" aria-label="The amount of each item (2 servings, 1 pound, 12 ounces)">Unit</label>
                  <p class="error font-sm" v-show="invalidUnit">You must choose a unit greater than zero.</p>
                    <!-- <input v-model.number="itemSize" type="number" placeholder="Units" value="1"> -->

                    <div class="input-number">
                    	<input  v-model.number="itemSize" type="number" :class="{'error' : invalidUnit}"   placeholder="Units" value="1" @input="invalidUnit = false"/>
                    	<div class="input-number__controls">
                    		<div @click="itemSize += 1"><i class="stack-up-open"></i></div>
                    		<div @click="itemSize = Math.max(1, itemSize-1)"><i class="stack-down-open"></i></div>
                    	</div>
                    </div>
              </div>
              <div class="flex-item-unit-input">
                <div class="input-select">
                    <select v-model="selectedUnit" name="food[units__type]">
                      <option v-for="unit in units" :value="unit">{{unit}}</option>
                    </select>
                </div>
              </div>
            </div>

            <!-- Quantity -->
            <div class="flex-item" aria-label="Value must be greater than zero">
                  <label class="font-md hint--right hint--rounded spa" aria-label="The number of available items">How Many</label>
                  <p class="error font-sm" v-show="invalidQuantity">You must choose a number greater than 0.</p>
                    <!-- <input v-model.number="quantity"  type="number" placeholder="Quantity" value="1"> -->
                  <div class="input-number">
                  	<input v-model.number="quantity" type="number" :class="{'error' :invalidQuantity}" placeholder="Quantity" value="1" @input="invalidDate = false"/>
                  	<div class="input-number__controls">
                  		<div @click="quantity += 1"><i class="stack-up-open"></i></div>
                  		<div @click="quantity = Math.max(1, quantity-1)"><i class="stack-down-open"></i></div>
                  	</div>
                  </div>
            </div>



            <!-- Create Button -->
            <button class="btn btn--primary type--uppercase create-entry-button flex-item" @click="createEntry">Create Entry</button>

        </form>
    </div>
</template>

<script>
import Fireapp from '../modules/fireapp.js';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import {
  required,
  minLength,
  between,
  numeric
} from 'vuelidate/lib/validators';

export default {
  name: 'create-entry',
  components: { flatPickr },
  props: [],
  firebase: {
    categories: Fireapp.database().ref(`categories`),
    unitsArray: Fireapp.database().ref('devSettings/units')
  },
  data() {
    return {
      selectedCategory: 'unspecified',
      itemName: '',
      expiration: moment()
        .endOf('day')
        .toISOString(),
      quantity: 1,
      itemSize: 1,
      selectedUnit: 'cups',
      invalidDate: false,
      invalidUnit: false,
      invalidQuantity: false,

      datePickerConfig: {
        minDate: moment()
          .endOf('day')
          .valueOf(),

        // create an extra input solely for display purposes
        altInput: true,
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        wrap: true // set wrap to true when using 'input-group'
      }
    };
  },
  computed: {
    units() {
      return this.unitsArray.map(unit => unit['.key']);
    }
  },
  methods: {
    capitalize(str) {
      var arr = str.split(' ');
      for (let i = 0; i < arr.length; i++) {
        var word = arr[i];
        // capitalized first letter + rest of the word
        var capitalizedWord = word[0].toUpperCase() + word.slice(1);
        arr[i] = capitalizedWord; // replace the uncapitalized word with capitalized one
      }
      return arr.join(' '); // join with spaces to make it a sentence again
    },

    createEntry(event) {
      event.preventDefault();
      console.log(
        `entry expiration is:  ${moment(this.expiration)
          .endOf('day')
          .valueOf()}`
      );
      let expDate = moment(this.expiration)
        .endOf('day')
        .valueOf();

      // VALIDATE input

      var validValues = true;

      if (this.itemSize < 1) {
        console.log('invalid units');
        this.invalidUnit = true;
        validValues = false;
      }
      if (this.quantity < 1) {
        console.log('invalid quantity');
        this.invalidQuantity = true;
        validValues = false;
      }
      // if earlier than the end of today
      if (
        expDate <
        moment()
          .endOf('day')
          .valueOf()
      ) {
        console.log('invalid date');
        this.invalidDate = true;
        validValues = false;
      }

      if (!validValues) {
        // modal saying "enter thus and such valid data"?
        return;
      }

      // END VALIDATE INPUT

      // trim the pluralized string to make it singular if there is only 1 unit
      let unitsString = '';
      if (this.itemSize == 1) {
        let unitString = `${this.selectedUnit}`;
        let trimmedString = unitString.substring(0, unitString.length - 1);
        unitsString = `${this.itemSize} ${trimmedString}`;
      } else {
        unitsString = `${this.itemSize} ${this.selectedUnit}`;
      }

      let itemName;
      if (this.itemName.length) {
        itemName = this.capitalize(this.itemName);
      } else {
        itemName = this.itemName;
      }

      let entry = {
        category: this.selectedCategory,
        expirationDate: expDate,
        name: itemName,
        reservedByIds: {},
        totalListed: this.quantity,
        units: unitsString
      };

      this.$store
        .dispatch('createEntry', entry)
        .catch(err => {
          this.$modal.show('error-alert', {
            title: 'Error',
            message: err.message,
            type: 'error'
          });
        })
        .then(() => {
          this.$emit('close');
        });
    }
  },
  mounted() {}
};
</script>

<style lang="css" scoped>

  h2 {
    margin: 0em;
  }
  .close-bag-button {
    display: block;
    width: 3.5em;
    height: 3.5em;
    cursor: pointer;

    transition: 0.3s;
  }
  .close-bag-button:hover {
    transform: scale(1.2);
    opacity: 0.8;
  }

.box-background {
  top: 0;
  background-color: #eee;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  border-radius: .8em;
  padding: 1.8em;
  margin: 5em 0em;
}

.form-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: stretch;
  flex-direction: column;
}

.flex-item {
  flex: 1 1 auto;
  /*padding-top: 0.8em;*/
  padding-bottom: 1em;
}
.flex-item-half {
  display: flex;
  justify-content:space-between;
  flex-wrap: nowrap;
  align-items: stretch;
  flex-direction: row;
  padding: 0px;
}
.flex-item-half .flex-item {
  flex: 0 1 auto;
}
.flex-item-half .flex-item-unit-input {
  flex: 1 1 auto;
  align-self: flex-end;
  padding-bottom: 1em;
}

.create-entry-button {
  margin: 1em 0em 1em 0em;
}

.input-number, input, .input-select {
  color: #FF8E5A;
  margin-top: 0em;
}

.input-number__controls {
  display: flex;
  height: 100%;
  width: auto;
  align-items: center;
  flex-direction: row-reverse;
  padding-right: 0px;
}
.input-number__controls > div {
  width: 34px;
}

.input-number > input[type="number"] {
  text-align: left;
  padding-left: 1em;
}

.input-number__controls i:hover, .input-number__controls i:active {
  color: #ff8e5a
}

.input-select:after {
    border-left: 0px;
    line-height: 1.35em;
}

.error {
  border-color: red;

}
p.error {
  color: red;
  margin: 0;
}

</style>
