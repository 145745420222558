<template lang="html">
  <section :data-overlay="overlayAmount" :class="{narrow : isNarrow, 'full-height' : !isNarrow}">
      <!-- <div class="background-image-holder"><img alt="background" src="./img/stock/peach-colored-apples.jpg"></div> -->
      <lazy-background
        :image-source="bgImageSource"
        :loading-image="bgImageSource"
        :error-image="bgImageSource"
        image-class="cover-image">
      <slot></slot>
      </lazy-background>
  </section>
</template>

<script>
import LazyBackground from './lazy-image-background.vue';

  export default {
      name: "cover-header",
      components: { LazyBackground },
      props: {
        bgImageSource: {
          type: String,
          required: true
        },
        overlayAmount : {
          type: String,
          required : false,
          default: '3'
        },
        fullHeight : {
          type: String,
          required : false,
          default: undefined
        }
      },
      firebase: {},
      data(){ return {  } },
      computed: {
        isNarrow(){ return !this.fullHeight }
      },
      methods: {},
  }
</script>

<style lang="css" scoped>
.cover-image {
  height: 100%;
}
.height-100, .height-90, .height-80, .height-70, .height-60, .height-50, .height-40, .height-30, .height-20, .height-10 {
  padding: 0;
}

section.narrow {
  height: 20vw;
  max-height: 300px;
}

section {
  padding: 0;
}

[data-overlay]:before {
  z-index: 0;
}
</style>
