<template lang="html">
  <span class="action-button confirm-button" @click="clicked" :class="[{'processing' : isConfirming}, {'success' : isConfirmed}]">
    <div class="title" @click="checkConfirmation" :class="{'animate' : !isReady}"><slot></slot></div>
    <div class="confirmation" :class="[{'visible' : isConfirming}, {'animate-up': isConfirmed}]">
      <span class="confirmation--cancel" @click="cancel">x</span>
      <span class="confirmation--title" @click="confirm">Confirm</span>
    </div>
     <div class="ab-checkmark" :class="{'animate' : isConfirmed}"><i class="custom icomoon-circle-check"></i></div>
  </span>


</template>

<script>
  export default {
      name: "confirm-button",
      components: {  },
      props: {},
      firebase: {},
      data(){
        return {
          isConfirming : false,
          isConfirmed : false
        }
     },
      computed: {
        isReady(){
          return !this.isConfirming && !this.isConfirmed;
        }
      },
      watch: {
        isConfirmed(newValue){
          if(this.isConfirmed){
            this.$emit('update:isConfirming', false);
            setTimeout(()=>{
              this.$emit('update:isConfirmed', false);
            }, 1000);
          }
        }
      },
      methods: {
        clicked(event){
          event.preventDefault();
        },
       checkConfirmation(){
         if (this.isReady) { this.isConfirming = true; }
       },
       confirm(){
         this.isConfirming = false; this.isConfirmed = true;
         this.$emit('button-click');
         setTimeout(()=>{
             this.isConfirming = false; this.isConfirmed = false;
           }, 1000);
       },
       cancel(){
         this.isConfirming = false;
       }
     }
  }
</script>

<style lang="css" scoped>
</style>
