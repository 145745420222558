// Import for use with VueFire
import Firebase from 'firebase';
import Vue from 'vue';

import Utils from './modules/utilities.js'

// Install VueFire to allow firebase key below
import VueFire from 'vuefire';
Vue.use(VueFire);

import VueRouter from 'vue-router';
Vue.use(VueRouter);

// Install VueGoogleMaps
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDlfGMfqcLw6Vi7xTzqy8-gDW11hu8dr2I'
  }
});

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo, {
     container: "body",
     duration: 500,
     easing: "ease-out",
     offset: -60
 });

 import vmodal from './components/generic/vue-js-modal/vue-js-modal.js';
 Vue.use(vmodal);

 import vnotification from './components/generic/notification/notification.js';
 Vue.use(vnotification);

import DefaultComponents from './components/default-components';
Vue.use(DefaultComponents);

Object.defineProperty(Vue.prototype, '$jQuery', { value: window.jQuery });


import * as gm from '@google/maps'
let googleMaps = gm.createClient({
  Promise,
  key: 'AIzaSyDlfGMfqcLw6Vi7xTzqy8-gDW11hu8dr2I'
});
Object.defineProperty(Vue.prototype, '$googleMaps', { value: googleMaps });


import Fireapp from './modules/fireapp.js';
import store from './vue-store/store.js';

import App from './components/app.vue';

import router from './vue-router/router.js';
router.afterEach((to, from) => {
    Vue.nextTick(() =>{
      // cause stack template to reload whenever a route change occurs, because some setup is dependant on documentReady
      let mr = window.mr;
      mr.documentReady(mr.setContext());
    });
});

import routes from './vue-router/routes.js';
Object.defineProperty(Vue.prototype, '$siteRoutes', { value: routes})

new Vue({
  store, // inject store into child components
  // Specify the element in the index.html that this instance controls
  el: '#app-container',
  router,
  // Render the main app component into the app div in index.html
  render: (h) => h(App)

});
