<template lang="html">
  <div class="main-container">
    <cover-header class="imagebg" bg-image-source="./img/stock/bowl-pears.jpg" overlay-amount="4" full-height="yes">
      <div class="container pos-vertical-center text-center">
      <h1 class="font-intro_head">Locations</h1>
                            <div class="container">
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                      <h2>Washington DC</h2>
                                      <p class="lead">
                                          We currently only function in the Washington DC area
                                      </p>
                                    </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-xs-12 col-xs-offset-0">
                                      <p class="lead">
                                        <a href="#">Contact Us</a> if you are interested in bringing Free Food For Families to your city.
                                      </p>
                                  </div>
                                </div>
                                <!--end of row-->
                            </div>

                            <!--end of container-->
       <gmap-map
       id="locations-map"
       :center="{ lat:38.8923,lng: (-77.0218) }"
       :zoom="11"
       class="map-container border--round"
       :options="mapSettings.greyscaleMapOptions">
         </gmap-map>
     </div>
   </cover-header>
  </div>

</template>

<script>
  import CoverHeader from './cover-header.vue';

  import * as VueGoogleMaps from 'vue2-google-maps';
  import mapSettings from '../modules/map-settings.js';

  export default {
      name: "locations-view",
      components: {
        CoverHeader
       },
      props: [  ],
      firebase: {},
      data(){
        return {
          mapSettings
        }
      },
      computed: {},
      methods: {}
  }
</script>

<style lang="css" scoped>
</style>
