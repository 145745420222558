<template lang="html">
  <div class="container">
    <section class="ui main text container legal-section">
        <div class="row">
            <div class="col-xs-12 col-md-8 col-md-offset-2">

                <h1>Privacy Policy</h1>
                <hr/>
                <div class="font-open_sans">
                  <p>FreeFoodForFamilies is committed to protecting the integrity and privacy of personal information gathered from our partners through all of our communication channels, as well as from visitors to our Site. Note that if you are a website visitor or use the website or app to reserve items, we do NOT collect any personal information. If you are a partner, the information collected (postal address, email address) is to be associated with your employer or business, not you personally.
                  The protection of your privacy is of great importance to us, we have created this privacy policy to communicate our practices regarding the collection and dissemination of personal information that you provide to us and that can be linked to a specific individual, such as a name, postal address, telephone number(s), email address, or any other information, provided to us by our partners and Site visitors (hereinafter "Personal Information"), as well as certain non-personally identifiable information collected when you visit many of our web pages such as the type of browser you are using (e.g. Internet Explorer or Firefox), the type of operating system you are using (e.g., Windows XP or Mac OS), the domain name of your internet service provider (e.g., AT&T or Verizon), aggregate data about the number of visits to the Site and/or aggregate data about the pages visited (hereinafter "Aggregate Data").
                  This policy will be updated periodically, so please be sure to reread it from time to time. By submitting your personal information to us, you are agreeing that FreeFoodForFamilies may use the information in accordance with this privacy policy.</p>
                  <h3>Collection and Use of Personal Information</h3>
                  <p>FreeFoodForFamilies collects Personal Information supplied to us voluntarily by partners and visitors to this Site.  We may use such voluntarily provided information to improve our understanding of your interests, to improve our services, or to contact you about issues that may affect you. In order to utilize certain features of our site or receive information from FreeFoodForFamilies or become a partner, you may choose to provide Personal Information. If you do submit information to us, we will use that information for the purposes for which it was submitted. We may also use your name, postal address and telephone number(s) to contact you offline. If you do not wish us to contact you offline, or receive mail or email, you may opt out by <router-link :to="$siteRoutes.contactus.path">contacting us</router-link> in accordance with the Opt Out section of this policy.
                  FreeFoodForFamilies uses third-party vendors to provide services on this Site and in offline business operations. The information that you submit on this site may be provided to those vendors on a confidential basis so that those vendors can provide services (such as maintaining our database or sending email messages) on our behalf.
                  Other than as described in this privacy policy, or as may be necessary to protect the rights, property, and safety of FreeFoodForFamilies or others, or as may be necessary to comply with applicable law, Feeding America will not give or sell the Personal Information you provide online.</p>
                  <h3>Use of Email Address</h3>
                  <p>When you contact us or sign up as a partner on our Site, you will be asked to supply your email address. FreeFoodForFamilies does not sell or rent your email address to any third party. Email addresses gathered from partners of FreeFoodForFamilies are only available to employees managing this information for purposes of contacting you or sending you emails based on your request for information, and to contracted service providers and vendors for purposes of providing services relating to our communications with you.</p>
                  <h3>User Submissions</h3>
                  <p>FreeFoodForFamilies welcomes your comments and feedback on its site.  However, you acknowledge that if you send us stories, photos, videos, ideas, notes, drawings, concepts, or other information, the information shall be deemed, and shall remain, the property of FreeFoodForFamilies.  By submission of any information to us, you assign to us and we shall exclusively own all now known or hereafter existing rights to such information and shall be entitled to unrestricted use of the information for any purpose whatsoever, commercial or otherwise, without compensation.
                  As a user of this site, or as one of our partners, you are responsible for your own communications and are responsible for the consequences of their posting.  Therefore, do not impersonate another person or business and not transmit material to us that is copyrighted, unless you are the copyright owner of have the permission of the copyright owner to post it; do not send material that reveals trade secrets, unless you won them or have the permission of the owner; do not send material that infringes on any other intellectual property rights of others or on the privacy or publicity rights of others; do not send material that is obscene, defamatory, threatening, harassing, abusive, hateful, or embarrassing to another user or any other person or entity; and do not send advertising or solicitations of business, or chain letters.
                  You represent and warrant that any information and/or material you post or provide to FreeFoodForFamilies through any medium is truthful, accurate, not misleading, not confidential property of others, and not in violation of any other third party’s rights. Furthermore, you acknowledge and agree that all submissions will become the property of FreeFoodForFamilies and that FreeFoodForFamilies has the right to publish or republish the submission for any type of use including for promotional and advertising purposes without compensation to you of any kind.  Should your submission include third party materials, you grant, represent and warrant that you, as well as the owner of such content, have expressly granted FreeFoodForFamilies a royalty-free, perpetual, irrevocable, world-wide exclusive license to use the submission in any manner, media or form now known or hereafter developed.  FreeFoodForFamilies shall have the right to sublicense its rights.</p>
                  <h3>Use of Cookies</h3>
                  <p>The FreeFoodForFamilies Site may use cookies and similar means to collect aggregate statistical information. Aggregate information does not identify any individual person. We may use or disclose aggregate information for any purpose. A cookie is a small text file that is placed on your hard disk by a Web page server. Cookies are uniquely assigned to you, and are designed to be read only by a Web server in the domain that issued the cookie to you. Cookies are also used to recognize visitor preferences, temporarily store session information and past activity at our site in order to provide better service and easier access when individuals return to our site. If you choose to decline cookies, you may not be able to fully experience the interactive features of this or other Sites you visit.</p>
                  <h3>Disabling Cookies</h3>
                  <p>To remove cookies from your computer, visit your Internet browser's Help section for information on how to delete cookies. In addition, there are many Sites that provide browser and operating system specific information on how to delete cookies. When thinking about whether you want to delete cookies, you should be aware that some sites require cookies to function properly. After you delete cookies, you may have to re-register with some sites or you may notice difficulty in browsing some sites.</p>
                  <h3>Security</h3>
                  <p>This Site has security measures in place to protect the loss, misuse, and alteration of the information under our control. The security measures used on this site include, but are not limited to, industry-standard Secure Socket Layer (SSL) technology, firewalls, and internal policies to maintain the security of stored data. While we follow generally accepted industry standards to protect the personal information submitted to us, no method of transmission over the Internet, or method of electronic storage, is 100 percent secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                  <h3>Children</h3>
                  <p>FreeFoodForFamilies does not knowingly gather any personally identifiable information from children under the age of 13.  You must be 13 years of age or older to access the site.  FreeFoodForFamilies has no interest in collecting information from children under the age of 13.</p>
                  <h3>Opt-out/Contact</h3>
                  <p>If you wish to opt-out of receiving future communications from FreeFoodForFamilies, please <router-link :to="$siteRoutes.contactus.path">contact us</router-link>.</p>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'privacy-view',
  components: {},
  props: [],
  firebase: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!

    // calling next, allows the routing to proceed
    next(vm => {
      // access to component instance via `vm`
      vm.$emit('update:navigationOverlay', 'overlayLight');
    });
  }
};
</script>

<style lang="css" scoped>
</style>
