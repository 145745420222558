<template lang="html">
  <div class="main-container">
    <section class="imagebg height-100 text-center">
        <div class="background-image-holder">
            <img alt="background" src="img/bg/slate-1080.svg">
        </div>
          <div class="container pos-vertical-center">
              <div class="row">
                  <div class="col-sm-12">
                      <h1 class="h1--large">404</h1>
                      <p class="lead">
                          The page you were looking for doesn't appear to exist.
                      </p>
                        <router-link class="btn btn--primary" :to="routes.home.path">
                        <span class="btn__text">
                          Home
                        </span>
                      </router-link>
                  </div>
              </div>
              <!--end of row-->
          </div>
          <!--end of container-->
      </section>
  </div>
</template>

<script>
import routes from '../vue-router/routes.js';
export default {
  data(){
    return {
      routes
  }
  }
}
</script>

<style lang="css">
</style>
