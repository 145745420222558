import {loaded} from 'vue2-google-maps'; // https://github.com/xkjyeah/vue-google-maps
import Vue from 'vue';
import MapStyles from './map-styles.js';

// Storing these settings as a vue instance allows the properties to be bound and automatically update in vue components
var settings = new Vue({
data : {
        defaultIconSettings: {},
        defaultMapOptions: {}
    }
});
export default settings;

loaded.then(() => {
    /* google.maps is now available! */
    settings.defaultIconSettings = {
        url: './img/Annotation.png',
        size: new google.maps.Size(20, 32),
        anchor: new google.maps.Point(10, 16)
    };
    settings.noItemsIconSettings = {
      url: './img/AnnotationNoItems.png',
      size: new google.maps.Size(20, 32),
      anchor: new google.maps.Point(10, 16)
    };

    settings.defaultMapOptions = {
      center: { lat:38.8923,lng: (-77.0218) },
      clickableIcons: true,
      streetViewControl: false,
      panControlOptions: false,
      zoom: 13,
      scrollwheel: false,
      styles: MapStyles.normal
    };

    settings.greyscaleMapOptions = {
      center: { lat:38.8923,lng: (-77.0218) },
      clickableIcons: true,
      streetViewControl: false,
      panControlOptions: false,
      zoom: 13,
      scrollwheel: false,
      styles: MapStyles.greyscale
    }
})
