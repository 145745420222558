<template lang="html">
  <div class="main-container">

      <!-- Content -->
      <section class="height-100 bg--primary text-center">
          <div class="container pos-vertical-center">
              <div class="row">
                  <div class="col-sm-12">
                      <h1 class="h1--large">Coming Soon</h1>
                      <p class="lead">
                          This page has not yet been configured
                      </p>
                      <router-link :to="routes.home.path">Go back to home page</router-link>
                  </div>
              </div>
              <!--end of row-->
          </div>
          <!--end of container-->
      </section>

  </div>
</template>

<script>
  import routes from '../vue-router/routes.js';
  export default {
      name: "help-view",
      components: {},
      props: [  ],
      firebase: {},
      data(){
        return {
          routes
        }
      },
      computed: {},
      methods: {}
  }
</script>

<style lang="css" scoped>
</style>
