import VueRouter from 'vue-router';
import routesMap, { routes } from './routes.js';

// this is imported in index.js, giving all the components access to it, but importing here allows us to access the store in navigation guard methods
import store from '../vue-store/store.js';

import FireUtils from '../modules/fireutils.js';

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
});

router.beforeEach((to, from, next) => {

  let authenticatedUser = !FireUtils.values.userIsAnonymous;

  if (to.matched.some(record => record.meta.requires && record.meta.requires.authentication)) {

    // If the path requires authentication route un-authenticated users to sign-in
    if (!authenticatedUser){
      next({path : routesMap.signin.path });
      return;
    }

    if (to.matched.some(record => record.meta.requires && record.meta.requires.provider)){
      // If the user is an admin, route to the admin page
      if (store.state.userIsAdmin) {
        next({path : routesMap.admin.path });
        return;
      }

      if(!store.getters.userIsProvider){
        next({path : routesMap.apply.path });
        return;
      }
    }

    if(to.matched.some(record => record.meta.requires && record.meta.requires.admin) && !store.state.userIsAdmin){
      next({path : routesMap.fourofour.path });
      return;
    }

  }


  next();
});


export default router;
