<template lang="html">
  <gmap-map
  :center="center"
  @center_changed="updateCenter"
  :zoom="13"
  class="providers-map full-height"
  :options="mapSettings.defaultMapOptions"
  style="width: 100%; height: 100%">
      <gmap-info-window
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWindowOpen"
      :content="infoContent"
      @closeclick="showInfoWindow(null)"></gmap-info-window>

      <gmap-marker v-for="(p, i) in providers"
        :key="p.id"
        :position="gmLocationFrom(p.location)"
        :clickable="true"
        :draggable="false"
        :icon="p.foodEntryIds ? mapSettings.defaultIconSettings : mapSettings.noItemsIconSettings"
        @click="showInfoWindow(p)"
      ></gmap-marker>

    </gmap-map>
</template>


<script>
import * as VueGoogleMaps from 'vue2-google-maps';
import mapSettings from '../modules/map-settings.js';

export default {
  name: 'providers-map',
  props: ['providers', 'center', 'height'],
  data() {
    return {
      selectedProvider: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -10
        }
      },
      // Import the map settings module that initializes as soon as googlemaps loads
      // just including the value here will make the key have the same name
      mapSettings
    };
  },
  computed: {
    infoWindowOpen() {
      return this.selectedProvider != null;
    },
    infoContent() {
      let provider = this.selectedProvider;
      if (provider) {
        // let imgSrc = provider.imageURL;
        return `<img src="" alt=""><span>${provider.name}</span>`;
      }
      return '';
    },
    infoWindowPos() {
      let provider = this.selectedProvider;
      if (provider) {
        return this.gmLocationFrom(provider.location);
      }
      return { lat: 0, lng: 0 };
    }
  },
  methods: {
    // Converts a location object with keys "latitude/longitude" to "lat/lng"
    gmLocationFrom(location) {
      return { lat: location.latitude, lng: location.longitude };
    },
    showInfoWindow(provider) {
      //check if its the same marker that was selected
      if (
        this.selectedProvider &&
        provider &&
        this.selectedProvider.id == provider.id
      ) {
        // Hide the marker window
        this.selectedProvider = null;
        this.$emit('selectProvider', null);
      } else {
        //if different marker set infowindow to open
        this.selectedProvider = provider;
        this.$emit('selectProvider', provider);
      }
    },
    updateCenter(newCenter) {
      console.log('map center changed');
      this.$emit('center-changed', newCenter);
    }
  }
};
</script>

<style lang="css">
</style>
