<template lang="html">
  <div class="pending-provider-details">
    <!-- Editing Form -->
    <div v-if="isEditing">
      <div class="details-header">
        <h2>{{ editedProvider.name }}</h2>
        <span class="status-tag">{{ recordStatus.displayName }}</span>
      </div>

      <form class="flex-form" @submit="">
        <h4>Contact Information</h4>
         <div class="flex-input-group">
           <input v-model="submissionInfo.applicant" type="text" class="underlined" placeholder="Applicant Name">
           <input v-model="submissionInfo.phone" type="text" class="underlined" placeholder="Phone">
         </div>
         <textarea v-model="submissionInfo.notes" placeholder="Additional notes"></textarea>

         <h4>Provider Information</h4>
         <div class="flex-input-group">
           <input v-model="editedProvider.name" class="underlined" type="text" placeholder="Company Name">
         </div>
         <div class="flex-input-group">
           <input v-model="editedProvider.address.street" class="underlined" type="text" placeholder="Address">
         </div>
         <div class="flex-input-group">
           <input v-model="editedProvider.address.city" class="underlined" type="text" placeholder="City">
           <input v-model="editedProvider.address.state" class="underlined" type="text" placeholder="State/Province">
           <input v-model="editedProvider.address.country" class="underlined" type="text" placeholder="Country">
           <input v-model="editedProvider.address.postalCode" class="underlined" type="text" placeholder="Zipcode">
         </div>
         <textarea v-model="editedProvider.pickupInstructions" placeholder="pickup instructions">
         </textarea>
       </form>

    </div>

    <!-- Details Display -->
    <div v-else>
      <div class="details-header">
        <h2>{{ provider.name }}</h2>
        <span class="status-tag">{{ recordStatus.displayName }}</span>
      </div>
      <div class="pending-info-tables">
      <table>
        <th><h4>Contact Information</h4></th>
        <tr>  <td class="row__label">Email</td><td class="row__value">{{pendingRecord.submissionInfo.email}}</td>   </tr>
        <tr>  <td class="row__label">Applicant</td class="row__value"><td>{{pendingRecord.submissionInfo.applicant}}</td>   </tr>
        <tr>  <td class="row__label">Phone</td> <td class="row__value">{{pendingRecord.submissionInfo.phone}}</td>   </tr>
        <tr>  <td class="row__label">Notes</td><td class="row__value">{{pendingRecord.submissionInfo.notes}}</td>   </tr>
      </table>

      <table>
        <th><h4>Provider Information</h4></th>
        <tr><td class="row__label">Address</td><td class="row__value">
          <div>{{provider.address.street}}</div>
          <div>{{provider.address.city}}, {{provider.address.state}}</div> <div>{{provider.address.postalCode}}</div>
          <div>{{provider.address.country}}</div>
        </td></tr>
        <tr><td class="row__label">Pickup Instructions</td><td class="row__value">{{provider.pickupInstructions}}</td></tr>
      </table>
    </div>
    <pending-progress-bar v-if="displayProgressBar" :pending-progress="recordStatus"></pending-progress-bar>
</div>
  <div class="action-buttons">
    <slot name="additional-actions" class="additional-actions"></slot>
    <a v-if="!isEditing && permittedToEdit" class="btn btn--primary" @click="editing = true">
        <span class="btn__text">Edit</span>
    </a>
    <a v-if="showSaveButton" class="btn btn--primary" @click="save">
        <span class="btn__text">Save</span>
    </a>
    <a v-if="showSubmitButton" class="btn btn--primary" @click="reApply">
        <span class="btn__text">Save and Submit</span>
    </a>
  </div>
  </div>
</template>

<script>
import FireUtils from '../../modules/fireutils.js';
import Fireapp from '../../modules/fireapp.js';

import PendingProgressBar from './pending-progress-bar.vue';

let emptyProvider = FireUtils.normalize.provider({});
let defaultSubmissionInfo = FireUtils.normalize.submissionInfo({});

  export default {
      name: "pending-provider-details",
      components: { PendingProgressBar },
      props: [ 'pendingRecordUserId', 'adminMode', 'displayProgressBar' ],
      firebase: {},
      data(){ return {
        editing : false,
        editedProvider : emptyProvider,
        submissionInfo : defaultSubmissionInfo
       } },
      computed: {
        awaitingRecord() {
          let awaitingReview = FireUtils.values.pendingProviders[this.statusTypes.awaitingReview.dbPath];
          return awaitingReview && awaitingReview[this.pendingRecordUserId] || null;
        },
        inReviewRecord() {
          let inReview =  FireUtils.values.pendingProviders[this.statusTypes.inReview.dbPath];
          return inReview && inReview[this.pendingRecordUserId] || null;
        },
        deniedRecord() {
          let denied = FireUtils.values.pendingProviders[this.statusTypes.denied.dbPath]
          return denied && denied[this.pendingRecordUserId] || null;
        },
        approvedRecord() {
          let approved = FireUtils.values.pendingProviders[this.statusTypes.approved.dbPath];
          return approved && approved[this.pendingRecordUserId] || null;
        },
        statusTypes(){
          return FireUtils.statusTypes.pendingProviders;
        },
        pendingRecord(){
          let applyingRecord = {
            provider: FireUtils.normalize.provider({}),
            submissionInfo: FireUtils.normalize.submissionInfo({})
          };

          let record = this.awaitingRecord ||  this.inReviewRecord || this.deniedRecord || this.approvedRecord || applyingRecord;

          return record;
        },
        provider() {
          let accountId = this.pendingRecord.accountId;
          let providerId = FireUtils.values.accounts[accountId];

          let existingProvider = providerId && FireUtils.values.providers[providerId] || null;

          let record = this.pendingRecord;
          let provider = existingProvider || record.provider || emptyProvider;
          return FireUtils.normalize.provider(provider);
        },
        recordStatus(){
          let statusTypes = this.statusTypes;

          if (this.awaitingRecord){ return statusTypes.awaitingReview; }

          else if (this.inReviewRecord){ return statusTypes.inReview; }

          else if (this.deniedRecord){ return statusTypes.denied; }

          else if (this.approvedRecord){ return statusTypes.approved; }

          else{ return statusTypes.applying; }
        },
        permittedToEdit() {

          switch(this.recordStatus.enumValue){
            case this.statusTypes.awaitingReview.enumValue:
                // If the application is awaiting Review don't allow an Admin to edit it
                return !this.adminMode;
            break;
            case this.statusTypes.inReview.enumValue:
                // Only allow inReview applications to be edited by an Admin
              return this.adminMode
            break;
            case this.statusTypes.denied.enumValue:
              return !this.adminMode;
            break;
            default: return true;
          }
        },
      isEditing(){
        return this.editing && this.permittedToEdit || (this.recordStatus.enumValue === this.statusTypes.applying.enumValue && !this.adminMode);
      },
      showSubmitButton(){
        if (!this.isEditing) { return false; }
        // Display the submit button when the status is either initial applying or applying after denial.
        switch(this.recordStatus.enumValue){
          case this.statusTypes.denied.enumValue:
          case this.statusTypes.applying.enumValue:
            return true;
            break;
          default: return false;
        }
      },
      showSaveButton(){
        if (!this.isEditing) { return false; }
        // Display the save button in all editable cases except applying, in which case saving submits for review.
        switch(this.recordStatus.enumValue){
          case this.statusTypes.applying.enumValue:
            return false;
            break;
          default: return true;
        }
      }
      },
      watch: {
        editing(nowEditing) {
          if (nowEditing){
            this.editedProvider = this.provider;
            this.submissionInfo = this.pendingRecord.submissionInfo || defaultSubmissionInfo;
          }
        }
      },
      methods: {
        save(){

          let provider = this.editedProvider;
          let submissionInfo = this.submissionInfo;
          let reviewerInfo = this.pendingRecord && this.pendingRecord.reviewerInfo || {};

          switch(this.recordStatus.enumValue) {
            case this.statusTypes.applying.enumValue:
            case this.statusTypes.awaitingReview.enumValue:
              // create or update applied provider
              this.$store.dispatch('applyAsProvider', {provider, submissionInfo}).then(() => {
                this.editing = false;
                console.log("apply as provider here");

              });
            break;
            case this.statusTypes.inReview.enumValue:
            this.$store.dispatch('reviewProvider', {provider, submissionInfo, reviewerInfo}).then(() => {
              this.editing = false;
            });
            break;
            case this.statusTypes.denied.enumValue:
            // update the denied provider
            this.$store.dispatch('denyProvider', {provider, submissionInfo, reviewerInfo}).then(() => {
              this.editing = false;
            });
            break;
            case this.statusTypes.approved.enumValue:
            this.editing = false;
            break;
          }
        },
        reApply(){
          let provider = this.editedProvider;
          let submissionInfo = this.submissionInfo;
          let reviewerInfo = this.pendingRecord && this.pendingRecord.reviewerInfo || {};

          // create or update applied provider
          this.$store.dispatch('applyAsProvider', {provider, submissionInfo, reviewerInfo}).then(() => {
            this.editing = false;
            this.$modal.show('image-alert', {
              title: `You're Finished!`,
              message: 'Your application has been submitted for review. A representative will contact you within the next week. You can continue to make changes to your submission until its status has changed to In Review.',
              imagePath: 'img/svg/application_submitted.svg',
              imageClasses: [],
              modalClasses: ['image-modal image-modal--application-submitted']
            });
          });
        }
      },
      mounted(){
      }
  }
</script>

<style lang="css" scoped>

.status-tag {
  margin: 0 10px;
  background: #bdce24;
  color: #fff;
  border-radius: 3px;
  padding: 4px 8px;
  font-size: 20px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}

.flex-input-group {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.flex-input-group input {
  flex: 1;
  margin-top: 0;
  margin: 5px;
  margin-right: 15px;
}

.flex-input-group input:last-child {
  margin-right: 0px;
}

textarea {
  margin: 20px 0;
}

.details-header {
  margin-bottom: 1.5em;
  padding-bottom: 1em;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-content: space-between;
  align-items: center;
}

.pending-info-tables table {
  flex: 0 1 auto;
}


table {
  width: auto;
  max-width: 100%;
}
table th {
  padding: 0;
}
table td {
  vertical-align: top;
  padding: 0.4em;
}
.row__label {
  font-weight: bold;
  padding-left: 0;
}

h1, h2, h3,h4 {
  margin-bottom: 0;
}
.btn {
  margin: 4px;
}
@media all and (min-width: 768px) {
  .pending-info-tables {
    display: flex;
    flex-wrap: wrap;
  }

  .pending-info-tables table:first-child {
    margin-right: 30px;
  }

  .pending-info-tables table:last-child {
    padding-left: 30px;
    border-left: 1px solid #ddd;
  }


  .action-buttons > span > span {
    display: flex;
    margin-right: 5px;
  }

  .action-buttons {
    display: flex;
  }
}

</style>
