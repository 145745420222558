import Firebase from 'firebase';
import Vue from 'vue';
import VueFire from 'vuefire';

import fa from './fireapp.js';
import Util from './utilities.js';

Vue.use(VueFire);

/**
 * This file contains Utility functions related to Firebase as well as a vue instance that provides access to various database nodes.
 * @module FireUtils
 */

/**
 *  Watches all database nodes.
 *  This may seem terribly inefficient, but because firebase caches data, this is not much different than passing an array around from one source that loads the data. See http://stackoverflow.com/a/38423694
 *
 * This is not a replacement for using Vuefire in components directly. It merely exists for components where it would not make sense to load certain pieces of data with Vuefire, and exposes the database to Vuex
 *
 * @type {Vue}
 */
const values = new Vue({
  data: {
    currentUserId: '',
    currentUser: fa.auth().currentUser
  },
  computed: {
    userIsAnonymous() {
      return this.currentUser && this.currentUser.isAnonymous;
    }
  },
  firebase: {
    accounts: { source: fa.database().ref('accounts'), asObject: true },
    categories: { source: fa.database().ref('categories'), asObject: true },
    devSettings: { source: fa.database().ref('devSettings'), asObject: true },
    foodEntries: { source: fa.database().ref('foodEntries'), asObject: true },
    orders: { source: fa.database().ref('orders'), asObject: true },
    providers: { source: fa.database().ref('providers'), asObject: true },
    users: { source: fa.database().ref('users'), asObject: true },
    pendingProviders: {
      source: fa.database().ref('pendingProviders'),
      asObject: true
    }
  },
  methods: {
    // this does not seem to work :/
    setCurrentUserId(id) {
      this.currentUserId = id;
      this.currentUser = fa.auth().currentUser;

      this.$emit('FireUtils-userChanged', id);
      console.log(`set FireUtils user id : ${this.currentUserId}`);
    },
    signOut() {
      Firebase.auth().signOut();
      console.log('FireUtils.signOut()');
    },
    signIn(email, password) {
      return new Promise((resolve, reject) => {
        Firebase.auth()
          .signInWithEmailAndPassword(email, password)
          .catch(error => {
            reject(error);
            return;
          })
          .then(result => {
            console.log(result);
            if (result) {
              resolve();
            } else {
              reject();
            }
          });
      });
    },
    signUp(email, password) {
      return new Promise((resolve, reject) => {
        Firebase.auth()
          .createUserWithEmailAndPassword(email, password)
          .then(result => {
            resolve();
          })
          .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            reject(error);
          });
      });
    },
    determineAdminStatus() {
      return new Promise((resolve, reject) => {
        // Create the request
        let req = new XMLHttpRequest();

        // Handle the callback
        req.onload = () => {
          if (req.response) {
            console.log(`FireUtils determinedAdminStatus : ${req.response}`);
            resolve(req.response);
          } else {
            reject('no response');
          }
        };

        // Handle load errrors
        req.onerror = () => {
          reject('unknown error');
        };

        // Get the token for the user (this is different than the uid and is refreshed often)
        fa
          .auth()
          .currentUser.getToken(true)
          .then(token => {
            if (token) {
              // CHANGE_FOR_PRODUCTION!!!!!
              // Development admin cloud function
              // let validateAdmin = 'https://us-central1-dev-freefood.cloudfunctions.net/validateAdmin';
              // Production admin cloud function
              let validateAdmin =
                'https://us-central1-freefood-d6a78.cloudfunctions.net/validateAdmin';

              // Set the request type and url
              req.open('GET', validateAdmin);

              // Send the user token in the header
              req.setRequestHeader('Authorization', token);

              // perform the request
              req.send();
            } else {
              reject('no user token');
            }
          });
      });
    }
  }
});

// Watch changes to authentication state and signInAnonymously whenever there is no user
Firebase.auth().onAuthStateChanged(user => {
  if (user) {
    if (user.uid === values.currentUserId) {
      return;
    }
    values.setCurrentUserId(user.uid);
  } else {
    Firebase.auth()
      .signInAnonymously()
      .catch(error => {});
  }
});

/**
 * Functions that take database objects and ensure that they have all the expected values.
 *
 * Rather than checking everywhere before accessing values that may or may not exist (such as FoodEntry.reservedByIds), we can just pass the object the the appropriate normalize function before accessing any optional properties and then be certain that we can safely attempt to access any property. The return values are there for convenience. Passing an object by reference will  clean up the object without needing to reassign it to the variable.
 * @type {Object}
 */
const normalize = {
  entry: entry => {
    Util.normalize(entry, {
      category: 'unspecified',
      expirationDate: 1488828600000,
      id: '',
      name: '',
      providerId: '',
      reservedByIds: {},
      totalListed: 0,
      units: ''
    });
    return entry;
  },
  user: (user, id) => {
    let currentUser = Firebase.auth().currentUser;
    Util.normalize(user, {
      // Use any existing id, or use the id passed, or finally the user id

      id: id ? id : currentUser ? currentUser.uid : '',
      quotaInfo: {
        numberOfItems: 0
      },
      orders: {},
      accounts: {}
    });
    return user;
  },
  provider: provider => {
    Util.normalize(provider, {
      accountId: '',
      address: {
        city: '',
        country: '',
        postalCode: '',
        state: '',
        street: ''
      },
      foodEntryIds: {},
      id: '',
      imageURL: '',
      location: { latitude: 0, longitude: 0 },
      name: '',
      pickupInstructions: '',
      ratingCount: 0,
      ratingSum: 0
    });
    return provider;
  },
  order: order => {
    Util.normalize(order, {
      id: '',
      provider: '',
      reservedItems: [],
      user: ''
    });
    return order;
  },
  submissionInfo: submissionInfo => {
    Util.normalize(submissionInfo, {
      applicant: '',
      phone: '',
      notes: '',
      email: ''
    });
    return submissionInfo;
  }
};

/**
 * Functions that create Firebase update objects for certain events that need to be re-used.
 * @type {Object}
 */
const updates = {
  forCancellingOrder: order => {
    var userId = order.user;
    var updates = {};

    updates['orders/' + order.id] = null;
    updates['users/' + userId + '/orders/' + order.provider] = null;
    // quota is adjusted beforehand in the methods that ultimately call this one

    return updates;
  }
};

const statusTypes = {
  pendingProviders: {
    applying: {
      enumValue: 0,
      displayName: 'Applying',
      dbPath: null
    },
    awaitingReview: {
      enumValue: 1,
      displayName: 'Awaiting Review',
      dbPath: 'awaitingReview'
    },
    inReview: {
      enumValue: 2,
      displayName: 'in Review',
      dbPath: 'inReview'
    },
    denied: {
      enumValue: -1,
      displayName: 'Denied',
      dbPath: 'denied'
    },
    approved: {
      enumValue: 3,
      displayName: 'Approved',
      dbPath: 'approved'
    }
  }
};

export default {
  values,
  normalize,
  updates,
  statusTypes
};
