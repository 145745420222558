<template lang="html">
  <div>
    <section class="explanation-process imagebg text-center in-page-link unmarg--top">
        <div class="background-image-holder">
            <img alt="background" src="img/bg/slate-1080.svg">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h1 class="h1--large">How Free Food Works</h1>
                </div>
            </div>
            <!--end of row-->
        </div>
    </section>
    <section class="imagebg image--light explanation-step">
      <div class="background-image-holder">
          <img alt="background" src="img/bg/cutie-diag-900.svg">
      </div>
      <div class="container-fluid">
        <div class="row explanation-step__details">
          <div class="col-xs-4 col-xs-offset-4 col-sm-offset-0">
              <img src="img/svg/browse.svg" class="animated explanation-step__img" alt="">
          </div>
          <div class="col-xs-12 col-sm-7 container">
              <h1 class="animated text-center-xs">Browse</h1>
              <div class="row">
                <div class="col-sm-6 col-md-4">
                    <div class="feature feature-6">
                        <i class="icon icon--md icon-Location-2 color--primary-2"></i>
                        <h4 class="font-md">Nearby</h4>
                        <p class="font-md">
                            Browse food listed near you or specify a zip code to search by.
                        </p>
                    </div>
                    <!--end feature-->
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="feature feature-6">
                    <i class="icon icon--md icon-Handshake color--primary-2"></i>
                    <h4 class="font-md">Partners</h4>
                    <p class="font-md">
                        Participating restaurants and stores list food that is safe to eat but is scheduled to be discarded.
                    </p>
                  </div>
                    <!--end feature-->
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="feature feature-6">
                    <i class="icon icon--md icon-Magnifi-Glass2 color--primary-2"></i>
                    <h4 class="font-md">Search</h4>
                    <p class="font-md">
                        Find items by name or filter by categories.
                    </p>
                  </div>
                    <!--end feature-->
                </div>
              </div>
              <div class="">
                <router-link class="router-link flex__justify-end" :to="routes.find.path">
                  <span>Try It</span><i class="icon icon-Arrow-Right color--orange"></i>
                </router-link>
              </div>
          </div>
        </div>

      <!--end of row-->
      </div>
    </section>
    <section class="imagebg image--light explanation-step">
      <div class="background-image-holder">
          <img alt="background" src="img/bg/aqua-diag-900.svg">
      </div>
      <div class="container-fluid">
        <div class="row explanation-step__details">
          <div class="col-xs-4 col-xs-offset-4 col-sm-offset-0 visible-xs">
              <img src="img/svg/reserve.svg" class="animated explanation-step__img" alt="">
          </div>
          <div class="col-xs-12 col-sm-7 col-sm-offset-1 container">
            <h1 class="animated text-center-xs">Reserve</h1>
              <div class="row">
                <div class="col-sm-6 col-md-4">
                    <div class="feature feature-6">
                        <i class="icon icon--md icon-Add color--primary-2"></i>
                        <h4 class="font-md">Items</h4>
                        <p class="font-md">
                            Reserve free items you would like to pick up so that they will be held for you. No account required.
                        </p>
                    </div>
                    <!--end feature-->
                </div>
                <div class="col-sm-6 col-md-4">
                    <div class="feature feature-6">
                        <i class="icon icon--md icon-Basket-Items color--primary-2"></i>
                        <h4 class="font-md">Fair</h4>
                        <p class="font-md">
                            Every user can reserve up to 3 listed items each day.
                        </p>
                    </div>
                    <!--end feature-->
                </div>
                <div class="col-sm-6 col-md-4">
                    <div class="feature feature-6">
                        <i class="icon icon--md icon-QR-Code color--primary-2"></i>
                        <h4 class="font-md">Barcode</h4>
                        <p class="font-md">
                            Receive a barcode representing the items you have reserved from each unique location.
                        </p>
                    </div>
                    <!--end feature-->
                </div>
              </div>
              <div class="">
                <router-link class="router-link flex__justify-end" :to="routes.find.path">
                  <span>Try It</span><i class="icon icon-Arrow-Right color--orange"></i>
                </router-link>
              </div>
          </div>
          <div class="col-xs-4 hidden-xs">
              <img src="img/svg/reserve.svg" class="animated explanation-step__img" alt="">
          </div>
        </div>
        <!--end of row-->
      </div>
    </section>
    <section class="imagebg image--light explanation-step">
      <div class="background-image-holder background--top">
          <img alt="background" src="img/bg/truce-diag-900.svg">
      </div>
      <div class="container-fluid">
        <div class="row explanation-step__details">
          <div class="col-xs-4 col-xs-offset-4 col-sm-offset-0">
              <img src="img/svg/pickup.svg" class="animated explanation-step__img" alt="">
          </div>
          <div class="col-xs-12 col-sm-7 container">
            <h1 class="animated text-center-xs">Pickup</h1>
            <div class="row">
                <div class="col-sm-6 col-md-4">
                    <div class="feature feature-6">
                        <i class="icon icon--md icon-Car color--primary-2"></i>
                        <h4 class="font-md">Go</h4>
                        <p class="font-md">
                            Go to the the Partner location where the food is available. Details will be listed with your items.
                        </p>
                    </div>
                    <!--end feature-->
                </div>
                <div class="col-sm-6 col-md-4">
                    <div class="feature feature-6">
                        <i class="icon icon--md icon-Checkout-Basket color--primary-2"></i>
                        <h4 class="font-md">Checkout</h4>
                        <p class="font-md">
                            Present your barcode to show which items you reserved.
                        </p>
                    </div>
                    <!--end feature-->
                </div>
                <div class="col-sm-6 col-md-4">
                    <div class="feature feature-6">
                        <i class="icon icon--md icon-Fruits color--primary-2"></i>
                        <h4 class="font-md">Enjoy</h4>
                        <p class="font-md">
                            Receive your free food.
                        </p>
                    </div>
                    <!--end feature-->
                </div>
              </div>
              <div>
                <router-link class="router-link flex__justify-end" :to="routes.find.path">
                  <span>Try It</span><i class="icon icon-Arrow-Right color--orange"></i>
                </router-link>
              </div>
            </div>
        </div>
        <!--end of row-->
      </div>
    </section>
  </div>
</template>

<script>
import routes from '../vue-router/routes.js';
export default {
  name: "how-it-works",
  components: {

   },
  props: [  ],
  firebase: {},
  data(){
    return {
      routes
    }
  },
  methods: {},
}
</script>

<style lang="css">
.imagebg .icon {
  color: unset;
}
</style>
