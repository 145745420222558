<template lang="html">
  <div class="app">
    <main-nav
      :showbag="showBag"
      :overlay="navigationOverlay">
    </main-nav>

    <transition name="fade">
    <!-- Sync properties related to the main-nav so that components can determine the appearance of the nav -->
      <router-view
        :show-bag.sync="showBag"
        :navigation-overlay.sync="navigationOverlay"
        :collapse-footer.sync="collapseFooter">
      </router-view>
    </transition>

    <footer-view :collapsed-left="collapseFooter">
    </footer-view>

    <alert-modal></alert-modal>
    <confirmation-modal></confirmation-modal>
    <image-alert-modal></image-alert-modal>

  </div>
</template>

<script>

import MainNav from './main-nav.vue';
import FooterView from './footer.vue';
import HomeView from './home.vue';
import ProvideView from './provide.vue';
import FindView from './find.vue';
import AlertModal from './generic/alert-modal.vue';
import ConfirmationModal from './generic/confirmation-modal.vue';
import ImageAlertModal from './generic/image-alert-modal.vue';

  export default {
      name: "app",
      components: {
        MainNav,
        HomeView,
        ProvideView,
        FindView,
        FooterView,
        AlertModal,
        ConfirmationModal,
        ImageAlertModal
       },
      props: [  ],
      firebase: {},
      data(){
        return {
          showBag : false,
          navigationOverlay : 'overlayDark',
          collapseFooter : false
        }
      },
      computed: {},
      methods: {}
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}

.fade-enter-active {
  transition-delay: .3s;
}

</style>
