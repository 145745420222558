<template lang="html">
  <div class="featured-providers">
    <section class="imagebg">
      <div class="background-image-holder background--top">
          <img alt="background" src="img/bg/slate-1080.svg">
      </div>
        <div class="container">
            <div class="row text-center">
                <h2>Our Partners</h2>
            </div>
            <div class="row">
                <div class="slider slider--inline-arrows slider--arrows-hover text-center" data-arrows="true">
                    <ul class="slides">
                        <li class="col-sm-3 col-xs-6">
                            <img alt="Image" class="image--sm img-circle" src="img/partners/mcdonalds.jpg">
                        </li>
                        <li class="col-sm-3 col-xs-6">
                            <img alt="Image" class="image--sm img-circle" src="img/partners/lavagna.jpg">
                        </li>
                        <li class="col-sm-3 col-xs-6">
                            <img alt="Image" class="image--sm img-circle" src="img/partners/my_giant.jpg">
                        </li>
                        <li class="col-sm-3 col-xs-6">
                            <img alt="Image" class="image--sm img-circle" src="img/partners/panera.jpg">
                        </li>
                        <li class="col-sm-3 col-xs-6">
                            <img alt="Image" class="image--sm img-circle" src="img/partners/safeway.jpg">
                        </li>
                        <li class="col-sm-3 col-xs-6">
                            <img alt="Image" class="image--sm img-circle" src="img/partners/walmart.jpg">
                        </li>
                        <li class="col-sm-3 col-xs-6">
                            <img alt="Image" class="image--sm img-circle" src="img/partners/subway.jpg">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
  export default {
      name: "featured-providers",
      components: {  },
      props: [  ],
      firebase: {},
      data(){ return {  } },
      computed: {},
      methods: {},
  }
</script>

<style lang="css" scoped>
</style>
