<template lang="html">
  <button class="action-button" @click="clicked" :class="[{'processing' : isLoading}, {'success' : isConfirmed}]">
    <div class="title" :class="{'animate' : !isReady}"><slot></slot></div>
       <div class="ab-loader" :class="{'animate' : isLoading}">
         <svg x="0px" y="0px" width="25px" height="25px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 5 0;" xml:space="preserve">
         <path fill="#FFF" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
         <animateTransform attributeType="xml"
           attributeName="transform"
           type="rotate"
           from="0 25 25"
           to="360 25 25"
           dur="780ms"
           repeatCount="indefinite"
           calcMode="spline"
           keySplines="0.4, 0.4, 0.4, 0.4"
           keyTimes="0;1"/>
         </path>
       </svg>
     </div>
     <div class="ab-checkmark" :class="{'animate' : isConfirmed}"><i class="custom icomoon-circle-check"></i></div>
  </button>
</template>

<script>
  export default {
      name: "action-button",
      components: {  },
      props: {
        isLoading: {
          type: Boolean,
          required: false,
          default: false
        },
        isConfirmed : {
          type: Boolean,
          required: false,
          default: false
        },
        disableWhileLoading: {
          type: Boolean,
          required: false,
          default: true
        }
      },
      firebase: {},
      data(){ return {  } },
      computed: {
        isReady(){
          return !this.isLoading && !this.isConfirmed;
        }
      },
      watch: {
        isConfirmed(newValue){
          if(this.isConfirmed){
            this.$emit('update:isLoading', false);
            setTimeout(()=>{
              this.$emit('update:isConfirmed', false);
            }, 1000);
          }
        }
      },
      methods: {
        clicked(event){
          event.preventDefault();

          if(!this.isReady && this.disableWhileLoading){
            // Prevent click events when button is loading.
            return;
          }

          this.$emit('button-click');
        }
      },
  }
</script>

<style lang="css" scoped>

</style>
