<template lang="html">
  <modal
    name="confirmation-modal"
    :reset="true"
    transition="scale"
    :classes="modalClasses"
    :adaptive="true"
    @before-open="beforeOpen">
    <div class="modal--close-button" @click="$modal.hide('confirmation-modal')">
      <i class="custom icomoon-cross">
      </i>
    </div>
    <div class="modal--content">
      <div class="modal--header">
        <h4 class="unmarg font-dosis">{{ modalInfo.title }}</h4>
      </div>
      <p class="font-dosis">{{ modalInfo.message }}</p>
    </div>
    <div class="action-buttons-group">
      <a class="btn" :class="modalInfo.cancelButton.classes"  @click="dismiss()">
          <span class="btn__text">{{modalInfo.cancelButton.title}}</span>
      </a>
      <a class="btn" :class="modalInfo.confirmButton.classes" @click="dismiss(true)">
          <span class="btn__text">{{modalInfo.confirmButton.title}}</span>
      </a>
    </div>

  </modal>
</template>

<script>
import Utils from '../../modules/utilities'

  const defaultModalInfo = {
    title: 'Are you sure?',
    message: '',
    confirmButton:{
      title: 'Ok',
      classes: []
    },
    cancelButton: {
      title: 'Cancel',
      classes: ['btn--gray']
    },
    callback: (conrimed) => {}
  };
  export default {
      name: "confirmation-modal",
      components: {  },
      props: [  ],
      firebase: {},
      data(){ return {
        modalInfo: defaultModalInfo,
        defaultModalInfo
       } },
      computed: {
        modalClasses(){
          return ['v--modal', 'confirmation-modal'];
        }
      },
      methods: {
        beforeOpen(event){
          let mi = event.params;
          Utils.normalize(mi, this.defaultModalInfo)
          this.modalInfo = mi;
        },
        dismiss(confirmed = false){
          this.$modal.hide('confirmation-modal');
          this.modalInfo.callback(confirmed);
        }
      },
  }
</script>

<style lang="css" scoped>
.confirmation-modal {
  max-width: 400px;
  width: 90%;
  background: white;
  padding: 10px 20px;
}

.modal--content {
  padding: 20px 0;
}
</style>
