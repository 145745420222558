// Orders Vuex Store Module.
import Firebase from 'firebase';
import FireUtils from '../../modules/fireutils.js';
import Utils from '../../modules/utilities.js';
import moment from 'moment';

const state = {

}

const mutations = {

}

const actions = {
  cancel: (context, order) => {
    FireUtils.normalize.order(order);

    let database = Firebase.database().ref();
    let user = context.getters.currentUser;

    new Promise((resolve, reject) => {
      if (!order) {
        reject('No Order to Cancel');
        return;
      }

      // create an empty updates object
      let updates = {};
      let orderItems = Object.keys(order.reservedItems);

      let userReserveCount = 0;

      // loop thru reserved ids in order
      orderItems.forEach(entryId => {
        // grab entry for each id
        let entry = FireUtils.values.foodEntries[entryId];
        if (entry) {
          // see how many of this entry is reserved under this order/user.id


          let entryUpdates = {};
          entryUpdates[`foodEntries/${entryId}/reservedByIds/${user.id}`] = null;

          // add the updates for entry to all updates
          Utils.extend(updates, entryUpdates);

          let quotaItemUpdate = {};
          // update the user quotaInfo

          let numberToDecrement = user.quotaInfo.reservedItems && user.quotaInfo.reservedItems[entry.id] || 0;
          userReserveCount += numberToDecrement;

          quotaItemUpdate[`users/${user.id}/quotaInfo/reservedItems/${entryId}`] = null;

          Utils.extend(updates, quotaItemUpdate);
        }
      });

      let newQuota = user.quotaInfo.numberOfItems - userReserveCount;
      updates[`users/${user.id}/quotaInfo/numberOfItems`] = newQuota;

      let orderUpdates = FireUtils.updates.forCancellingOrder(order);
      Utils.extend(updates, orderUpdates);


      // Perform the updates
      database.update(updates, (error) => {
        if (error) {
          reject(error)
        } else {
          resolve()
        }

      });
    });
  },
  deliver: (context, order) => {
    FireUtils.normalize.order(order);

    let database = Firebase.database().ref();
    console.log(`delivering order ${order.id}`);
    new Promise((resolve, reject) => {
      if (!order) {
        reject('No Order to Cancel');
        return;
      }

      // create an empty updates object
      let updates = {};
      let orderItems = Object.keys(order.reservedItems);

      // loop thru reserved ids in order
      orderItems.forEach(entryId => {
        // grab entry for each id
        let entry = FireUtils.values.foodEntries[entryId];
        if (entry) {
          // see how many of this entry is reserved under this order/user.id
          let userReserveCount = entry.reservedByIds[order.user];
          let totalListed = entry.totalListed;

          // Calculate the number of items will be available under this entry after the items reserved in this order are delivered
          let newTotalListed = totalListed - userReserveCount;
          let entryRef = `foodEntries/${entry.id}`;

          let entryUpdates = {};
          if (newTotalListed <= 0) {
              // delete the entry
              entryUpdates[entryRef] = null;
              entryUpdates[`/providers/${entry.providerId}/foodEntryIds/${entry.id}`] = null;
          } else {
              // remove the userId from the reservedByIds
              entryUpdates[`${entryRef}/reservedByIds/${order.user}`] = null;
              // set the entry total to the new total
              entryUpdates[`${entryRef}/totalListed`] = newTotalListed;
          }
          // add the updates for entry to all updates
          Utils.extend(updates, entryUpdates);

          // remove delivered food entries from quotaInfo/items
          updates[`users/${order.user}/quotaInfo/reservedItems/${entryId}`] = null
        }
      });

      // Remove the Order
      updates[`orders/${order.id}`] = null;

      // Remove the Order from the User's list
      updates[`users/${order.user}/orders/${order.provider}`] = null;

      // Perform the updates
      database.update(updates, (error) => {
        if (error) {
          reject(error)
        } else {
          resolve()
        }

      });
    });
  },
  saveOrder: (context, order) => {
    return new Promise((resolve, reject) => {
      if (!order || !order.id || order.id.length < 1) {
        reject();
        return;
      }
      let database = Firebase.database().ref();
      FireUtils.normalize.order(order);

      // Remove vue-fire's .key property
      delete order['.key'];

      let updates = {};
      updates[`orders/${order.id}`] = order;
      database.update(updates, (error) => {
        if (error){
          reject(error);
          return;
        }
        resolve();
      });
    });
  }
}

const getters = {
  reservedItemTotal: (state) => (order) => {
    // If there are no foodEntries or the order was not passed, return 0
    if (!FireUtils.values.foodEntries || !order || !order.reservedItems) {
      return 0;
    }

    // Reduce the reservedItems, which is an array of foodEntry ids, down to a sum of the
    // number of times the user has reserved each item
    let sum = Object.keys(order.reservedItems).reduce((sum, id) => {
      // Get the entry
      let entry = FireUtils.values.foodEntries[id];
      // If it cannot be found, do not include it in the sum
      if (!entry || !entry.reservedByIds  || entry.expirationDate < Date.now()) {
        return sum;
      }
      console.log(`calculating reservations for ${entry}`);
      console.log(entry);
      // If the entry is found, add the resereved count to the sum
      return (entry.reservedByIds[order.user] || 0) + sum
    }, 0);

    console.log(`[orders.js] reservedItemTotal(${order.id}) -> ${sum}`);
    return sum;
  },
  reservedItemEntries: (state) => (order) => {
    if (!order || !order.reservedItems) {
      return [];
    }

    let entries = Object.keys(order.reservedItems).map((id) => {
      // Get the entry
      let entry = FireUtils.values.foodEntries[id];
      // If it cannot be found, set it to null so we can filter it out.
      if (!entry) {
        return null;
      }

      // If the entry is found, include it
      return entry;
    });
    entries = entries.filter((e) => {
      return e != null;
    });
    return entries;
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
