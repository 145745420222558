import ActionButton from  './generic/action-button.vue';
import ConfirmButton from './generic/confirm-button.vue';

const Plugin = {
  install (Vue, options = {}){
    if (this.installed) {
      return
    }
    this.installed = true

    Vue.component('action-button', ActionButton);
    Vue.component('confirm-button', ConfirmButton);
  }
}

export default Plugin
