<template>
  <div class="c-accordion">
    <div class="c-accordion" :class="{'is-active' : active}">
  <div class="c-accordion__header" @click="toggle">
    <i v-if="showIndicator" class="collapsed-indicator stack-right-open pull-left" :class="{'open' : active}"></i>
    <div class="flex-grow">
      <slot name="header"></slot>
    </div>
  </div>
  <div class="c-accordion__content-wrapper">
    <div class="c-accordion__content">
      <slot></slot>
    </div>
  </div>
</div>
  </div>
</template>

<script>
  export default {
    name: 'simple-accordion',
    props:  {
      'showIndicator' : {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data(){
     return { active: false }
    },
    methods: {
      toggle(){
        this.active = !this.active;
        if (this.active){ this.$emit('opened'); }
        else { this.$emit('closed'); }
      }
    }
  }
</script>

<style scoped>

.c-accordion {
  transition: all 1s;
}
.c-accordion__header {
  user-select:none;
  /*padding:10px;*/
  cursor:pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.collapsed-indicator {
  /*padding-right: 20px;*/
}

i.stack-right-open {
  transition: 0.3s;
  height: 100%;
}

i.stack-right-open.open {
  transform: rotate(90deg);
  transition: 0.3s;
}

.c-accordion__content {
  max-height:0;
  opacity: 0.4;
  overflow:hidden;
  transition: all .6s ease-in-out;
}

.is-active .c-accordion__header {
  /*background-color: #ddd;*/
}

.is-active .c-accordion__content {
  max-height:500px;
  opacity: 1;
  /*padding:10px;*/

}

@media print {
  .c-accordion__content {
    max-height:500px;
    opacity: 1;
  }
  i.stack-right-open {
    visibility: hidden;
  }
}
/*.is-active .c-accordion__content-wrapper {
  transition:all .6s ease-out;
}*/
</style>
